import { useEffect, useState } from "react";
import useTheme from "@mui/material/styles/useTheme";
import StyledLabel from "../../atomicComponents/StyledLabel";
import {
  FormControl,
  OutlinedInput,
  Typography,
  Box,
  InputAdornment,
  Divider,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import useProformaData from "../../hooks/dealReporthooks/useProformaData";
import { greyout } from "../../utils/greyout";
import commaSeparate from "../../utils/commaSeparate";
import useSaveNow from "../../hooks/useSaveNow";
import EnhancedRadioButtonGroup from "../../atomicComponents/EnhancedRadioButtonGroup";
import { remodelCostOptions } from "./constants";
import EditIcon from "@mui/icons-material/Edit";
import RegularToolTip from "../../atomicComponents/RegularToolTip";
import { getCostPsqftFromAdvancedRemodelCost } from "./remodelCostUtils/getCostPerSqft";
import * as actionCreators from "../../../../actions/dealAnalyzer/index";
import { useMemo } from "react";
import { isNaNChecker } from "../../localCalcutions/utils/genericFunctions";
import { preventMinus } from "../../utils/preventNegativeValues";
import { generateField, multiplyArrays } from "../../utils/GenerateInputField";

const useStyles = (_theme) => {
  return {
    root: {},
    inputContainer: {
      display: "flex",
      flexDirection: "column",
      marginTop: "1.125rem",
    },
    underInput: {
      paddingBottom: "5px",
      "&:hover": {
        borderBottom: "solid",
      },
      cursor: "pointer",
    },
    bottomLabel: {
      marginTop: "10px",
      display: "flex",
      alignItems: "center",
      padding: "5px 0",
    },
  };
};

function RemodelMainCard({ handleOpenDialog, handleSubMenu, handler, data }) {
  const theme = useTheme();
  const styles = useStyles(theme);
  const { saveNow } = useSaveNow();
  const propertyInfo = useSelector(
    (state) => state?.straightAestheticRemodel?.propertyInformation
  );
  const analyzerData = useSelector((state) => state?.straightAestheticRemodel);
  const { fetchResult, resultData } = useProformaData();
  const preExistingSquareFootage =
    analyzerData?.propertyInformation?.pre_existing_livable_sqft;
  const additionalSquareFootage =
    analyzerData?.propertyInformation?.additional_sqrft;
  const aestheticItems = analyzerData?.remodelCosts?.aesthetic_items || [];
  const nonAestheticItems =
    analyzerData?.remodelCosts?.non_aesthetic_items || [];

  const { estimatedRemodelCostPsqft, remodelCost } =
    getCostPsqftFromAdvancedRemodelCost({
      preExistingSquareFootage,
      aestheticItems,
      nonAestheticItems,
      additionalSquareFootage,
    }) || {};
  const isAdvanced = +analyzerData?.remodelCosts?.remodel_cost_option;
  let advancedRemodelTimer;

  // dispatch the advanced remodel cost
  const dispatch = useDispatch();

  // // //===================== Remodel cost ==========================//

  const remodelCosts = resultData?.remodel_cost;

  const est_remodel_cost_psqft = remodelCosts?.est_remodel_cost_psqft;
  const pre_existing_living_sqft =
    +analyzerData?.propertyInformation?.pre_existing_livable_sqft;
  const localRemodelCostPerSf =
    +analyzerData?.remodelCosts?.est_remodel_cost_psqft;

  //========================== dynamic form generation ============================//
  // generate fields for additional sqf added
  const generateNewForm = useMemo(() => {
    return generateField(propertyInfo?.additional_units?.length);
  }, [propertyInfo?.additional_units]);

  const formsWithValues =
    data?.additional_units_price?.length === generateNewForm?.length
      ? data?.additional_units_price
      : undefined;

  // empty prevPrices if unit length changes
  useEffect(() => {
    if (!formsWithValues) {
      handler({ additional_units_price: [] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // generate fields for additional sqf added
  const [additionalUnitArray, setAdditionalUnitArray] = useState(
    formsWithValues || generateNewForm
  );

  const componentDynamicDataFilter = (index, event) => {
    let data = [...additionalUnitArray];

    data[index][event.target.name] = event.target.value;
    setAdditionalUnitArray(data);
    handler({ additional_units_price: data });
    // handleEvents({ [e.target.name]: e.target.value });
  };

  const unitCostResult = useMemo(() => {
    return multiplyArrays(
      propertyInfo?.additional_units,
      data?.additional_units_price
    );
  }, [propertyInfo.additional_units, data?.additional_units_price]);

  //=============xxxxxxxxxxx============= dynamic form generation ==================xxxxxxxxx==========//

  const simpleRemodelTotal = useMemo(() => {
    const res =
      pre_existing_living_sqft * localRemodelCostPerSf +
      (unitCostResult?.totalSum || 0) +
      (data?.adu_price * propertyInfo?.adu || 0);
    const localTotalRemodelCost = { remodelCost: res };

    dispatch(actionCreators.updateRemodelCosts(localTotalRemodelCost));
    return isNaNChecker(res);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pre_existing_living_sqft,
    localRemodelCostPerSf,
    unitCostResult?.totalSum,
    data?.adu_price,
    propertyInfo?.adu,
  ]);

  useEffect(() => {
    dispatch(
      actionCreators.updateFinanceOptions({ advancedRemodelCost: remodelCost })
    );
    // NOTE: Run effect once on component mount, please
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remodelCost]);

  // updates the local cost for if there is no data from the server yet
  const localRemodelCost = simpleRemodelTotal;

  const simpleEstimatedRemodelCostPsqft = isNaN(
    +data?.[remodelMainCardStaticData.input1.name]
  )
    ? est_remodel_cost_psqft
    : data?.[remodelMainCardStaticData.input1.name];

  const handleChange = (e) => {
    handler({ [e.target.name]: e.target.value });
  };
  const specialHandler = (e) => {
    handler({ [e.target.name]: e.target.value });
    if (+e.target.value === 1) {
      advancedRemodelTimer = setTimeout(() => {
        handleSubMenu(1);
      }, 1000);
    }
  };

  const save = async () => {
    try {
      saveNow({ remodel_cost: analyzerData?.remodelCosts });
    } catch (err) {}
  };
  useEffect(() => {
    !data?.remodel_cost_option && handler({ remodel_cost_option: "0" });

    return () => {
      advancedRemodelTimer && clearTimeout(advancedRemodelTimer);
    };
    // NOTE: Run effect once on component mount, please
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // console.log(resultData, "mainCard");
  useEffect(() => {
    save();
    fetchResult();
    // NOTE: Run effect once on component mount, please
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.pre_existing_livable_sqft, data?.est_remodel_cost_psqft]);

  // Straight Aesthetic Remodel

  // grandTotalRemodelConst
  useEffect(() => {
    const grandTotalRemodelCost = isAdvanced
      ? remodelCost
      : localRemodelCost ||
        resultData?.remodel_cost?.estimated_total_remodel_cost;

    handler({ grandTotalRemodelCost });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remodelCost, localRemodelCost]);

  return (
    <>
      <StyledLabel label="Choose a Remodel Cost Calculator 1" />
      <EnhancedRadioButtonGroup
        options={remodelCostOptions}
        name="remodel_cost_option"
        choice={data?.remodel_cost_option}
        specialHandler={specialHandler}
        styles={{ display: "flex" }}
      />
      <Divider
        light={true}
        // variant="middle"
        sx={{
          height: "1px",
          mt: -2,
          mb: 2,
          backgroundColor: "#C4c4c4",
        }}
      />
      <Box>
        <Box>
          <Box sx={styles.inputContainer}>
            <StyledLabel label={remodelMainCardStaticData.input1.label} />
            <FormControl sx={{ minWidth: "18.75rem" }}>
              <OutlinedInput
                fullWidth
                margin="normal"
                name={remodelMainCardStaticData.input1.name}
                onKeyPress={preventMinus}
                onChange={isAdvanced ? "" : handleChange}
                value={
                  isAdvanced
                    ? estimatedRemodelCostPsqft
                    : simpleEstimatedRemodelCostPsqft
                }
                placeholder="0"
                renderValue={(value) => value}
                inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                type="number"
                sx={isAdvanced ? greyout : {}}
                startAdornment={
                  <InputAdornment position="start">
                    {" "}
                    <Typography variant="adornment" color="secondary">
                      {" "}
                      $
                    </Typography>
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="start">
                    {" "}
                    <Typography variant="adornment" color="secondary">
                      {" "}
                      /SqFt
                    </Typography>
                  </InputAdornment>
                }
              />
            </FormControl>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "0.5rem",
              }}
            >
              {!isAdvanced &&
                additionalUnitArray.map((item, index) => (
                  <FormControl sx={{ width: "45%", mt: "1rem" }} key={index}>
                    <StyledLabel
                      label={`Unit ${index + 2}`}
                      showToolTip
                      // toolTipContent={toolTipTexts.bedrooms}
                    />

                    <OutlinedInput
                      name={"value"}
                      onKeyPress={preventMinus}
                      type="number"
                      onChange={(event) =>
                        componentDynamicDataFilter(index, event)
                      }
                      value={item?.value}
                      inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                      sx={{ fontSize: "16px" }}
                      endAdornment={
                        <InputAdornment position="start">
                          {" "}
                          <Typography variant="adornment"> SqFt</Typography>
                        </InputAdornment>
                      }
                    ></OutlinedInput>
                  </FormControl>
                ))}
            </Box>

            {!isAdvanced && (
              <Box sx={styles.bottomLabel}>
                <Typography
                  variant="notice"
                  color="primary"
                  sx={styles.underInput}
                  onClick={handleOpenDialog}
                >
                  {remodelMainCardStaticData.input1.lowerLabel}
                </Typography>
              </Box>
            )}
          </Box>
          {!!propertyInfo?.adu && (
            <Box sx={styles.inputContainer}>
              <StyledLabel label={"Accessory Dwelling Unit SqFt Cost"} />
              <FormControl sx={{ minWidth: "18.75rem" }}>
                <OutlinedInput
                  fullWidth
                  margin="normal"
                  name={"adu_price"}
                  onKeyPress={preventMinus}
                  onChange={isAdvanced ? "" : handleChange}
                  value={data?.adu_price}
                  placeholder="0"
                  renderValue={(value) => value}
                  inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                  type="number"
                  sx={isAdvanced ? greyout : {}}
                  startAdornment={
                    <InputAdornment position="start">
                      {" "}
                      <Typography variant="adornment" color="secondary">
                        {" "}
                        $
                      </Typography>
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="start">
                      {" "}
                      <Typography variant="adornment" color="secondary">
                        {" "}
                        /SqFt
                      </Typography>
                    </InputAdornment>
                  }
                />
              </FormControl>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "0.5rem",
                }}
              >
                {!isAdvanced &&
                  additionalUnitArray?.map((item, index) => (
                    <FormControl sx={{ width: "45%", mt: "1rem" }} key={index}>
                      <StyledLabel
                        label={`Unit ${index + 2}`}
                        showToolTip
                        // toolTipContent={toolTipTexts.bedrooms}
                      />

                      <OutlinedInput
                        name={"value"}
                        onKeyPress={preventMinus}
                        type="number"
                        onChange={(event) =>
                          componentDynamicDataFilter(index, event)
                        }
                        value={item?.value}
                        inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                        sx={{ fontSize: "16px" }}
                        endAdornment={
                          <InputAdornment position="start">
                            {" "}
                            <Typography variant="adornment"> SqFt</Typography>
                          </InputAdornment>
                        }
                      ></OutlinedInput>
                    </FormControl>
                  ))}
              </Box>
            </Box>
          )}
          <Box sx={styles.inputContainer}>
            <Box display={"flex"} justifyContent={"space-between"} gap={"3rem"}>
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "100%" }}
              >
                <StyledLabel label={remodelMainCardStaticData?.input2.label} />
                <FormControl sx={{ minWidth: "18.75rem" }}>
                  <OutlinedInput
                    fullWidth
                    margin="normal"
                    name={remodelMainCardStaticData?.input2.name}
                    onChange={handleChange}
                    value={propertyInfo?.pre_existing_livable_sqft || 0}
                    sx={greyout}
                    placeholder="0"
                    onKeyPress={preventMinus}
                    renderValue={(value) => value}
                    inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                    type="number"
                    endAdornment={
                      <InputAdornment position="start">
                        {" "}
                        <Typography variant="adornment" color="secondary">
                          {" "}
                          SqFt
                        </Typography>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                {!propertyInfo?.pre_existing_livable_sqft && (
                  <Box>
                    <Typography
                      variant="h6"
                      sx={{
                        backgroundColor: "pink",
                        padding: "5px",
                        width: "60%",
                      }}
                    >
                      This value should be filled under Property Information!
                    </Typography>
                  </Box>
                )}{" "}
              </Box>
              {!!propertyInfo?.adu && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <StyledLabel label={"Accessory Dwelling Unit SqFt"} />
                  <FormControl sx={{ minWidth: "18.75rem" }}>
                    <OutlinedInput
                      fullWidth
                      margin="normal"
                      name={"adu"}
                      onChange={handleChange}
                      value={propertyInfo?.adu || 0}
                      sx={greyout}
                      placeholder="0"
                      onKeyPress={preventMinus}
                      renderValue={(value) => value}
                      inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                      type="number"
                      endAdornment={
                        <InputAdornment position="start">
                          {" "}
                          <Typography variant="adornment" color="secondary">
                            {" "}
                            SqFt
                          </Typography>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Box>
              )}

              {!!propertyInfo?.additional_units?.length && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <StyledLabel label={"Pre Existing Livable SqFt Unit Cost"} />
                  <FormControl sx={{ minWidth: "18.75rem" }}>
                    <OutlinedInput
                      fullWidth
                      margin="normal"
                      name={remodelMainCardStaticData?.input3.name}
                      sx={greyout}
                      value={
                        isAdvanced
                          ? commaSeparate(remodelCost)
                          : commaSeparate(
                              pre_existing_living_sqft * localRemodelCostPerSf
                            ) ||
                            commaSeparate(
                              resultData?.remodel_cost
                                ?.estimated_total_remodel_cost
                            )
                      }
                      placeholder="0"
                      renderValue={(value) => value}
                      inputProps={{ style: { fontSize: "1rem" } }}
                      startAdornment={
                        <InputAdornment position="start">
                          {" "}
                          <Typography variant="adornment" color="secondary">
                            {" "}
                            $
                          </Typography>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Box>
              )}
            </Box>
            {!!propertyInfo?.adu && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  mt: "2rem",
                }}
              >
                <StyledLabel
                  label={"Accessory Dwelling Unit SqFt  Total Cost"}
                />
                <FormControl sx={{ minWidth: "18.75rem" }}>
                  <OutlinedInput
                    fullWidth
                    margin="normal"
                    name={"adu"}
                    onChange={handleChange}
                    value={data?.adu_price * propertyInfo?.adu || 0}
                    sx={greyout}
                    placeholder="0"
                    onKeyPress={preventMinus}
                    renderValue={(value) => value}
                    inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                    type="number"
                    endAdornment={
                      <InputAdornment position="start">
                        {" "}
                        <Typography variant="adornment" color="secondary">
                          {" "}
                          SqFt
                        </Typography>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Box>
            )}
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            {propertyInfo?.additional_units?.map((item, index) => (
              <Box sx={styles.inputContainer}>
                <StyledLabel label={`Unit ${index + 2}`} />
                <FormControl sx={{ minWidth: "18.75rem" }}>
                  <OutlinedInput
                    fullWidth
                    margin="normal"
                    name={remodelMainCardStaticData?.input2.name}
                    onChange={handleChange}
                    value={item?.value || 0}
                    sx={greyout}
                    placeholder="0"
                    onKeyPress={preventMinus}
                    renderValue={(value) => value}
                    inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                    type="number"
                    endAdornment={
                      <InputAdornment position="start">
                        {" "}
                        <Typography variant="adornment" color="secondary">
                          {" "}
                          SqFt
                        </Typography>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Box>
            ))}
          </div>
          <div>
            {unitCostResult?.indexMultiplications?.map((item, index) => (
              <Box sx={styles.inputContainer}>
                <StyledLabel label={`Unit ${index + 2} Remodel Cost`} />
                <FormControl sx={{ minWidth: "18.75rem" }}>
                  <OutlinedInput
                    fullWidth
                    margin="normal"
                    name={remodelMainCardStaticData?.input2.name}
                    onChange={handleChange}
                    value={item?.value || 0}
                    sx={greyout}
                    placeholder="0"
                    onKeyPress={preventMinus}
                    renderValue={(value) => value}
                    inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                    type="number"
                    startAdornment={
                      <InputAdornment position="start">
                        {" "}
                        <Typography variant="adornment" color="secondary">
                          {" "}
                          $
                        </Typography>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Box>
            ))}
          </div>
        </Box>

        <Box sx={styles.inputContainer}>
          <Box>
            <StyledLabel label={remodelMainCardStaticData?.input3.label} />
            <RegularToolTip content="Edit">
              <EditIcon
                sx={{
                  fontSize: "1.2rem",
                  mx: 3,
                  color: "#1378A5",
                  cursor: "pointer",
                }}
                onClick={() => handleSubMenu(1)}
              />
            </RegularToolTip>
          </Box>
          <FormControl sx={{ minWidth: "18.75rem" }}>
            <OutlinedInput
              fullWidth
              margin="normal"
              name={remodelMainCardStaticData?.input3.name}
              sx={greyout}
              value={
                isAdvanced
                  ? commaSeparate(remodelCost)
                  : commaSeparate(localRemodelCost) ||
                    commaSeparate(
                      resultData?.remodel_cost?.estimated_total_remodel_cost
                    )
              }
              placeholder="0"
              renderValue={(value) => value}
              inputProps={{ style: { fontSize: "1rem" } }}
              startAdornment={
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="adornment" color="secondary">
                    {" "}
                    $
                  </Typography>
                </InputAdornment>
              }
            />
          </FormControl>
          {/* <Box sx={styles.bottomLabel}>
            <Typography
              variant="notice"
              color="primary"
              sx={styles.underInput}
              onClick={() => handleSubMenu(1)}
            >
              {remodelMainCardStaticData.input3.lowerLabel}
            </Typography>
            <HtmlToolTip
              content={{
                text: "Add Items and their estimated dollar value individually",
              }}
            />
          </Box> */}
        </Box>
      </Box>
    </>
  );
}

export default RemodelMainCard;

const remodelMainCardStaticData = {
  input1: {
    label: "Contractor Pre Existing Remodel Cost Per Square foot (Main Unit)",
    lowerLabel:
      "Get an estimated dollar per square foot from a Local Licensed Contractor",
    name: "est_remodel_cost_psqft",
  },
  input2: {
    label: "Pre Existing Livable Square Footage",
    name: "pre_existing_livable_square_footage",
  },
  input3: {
    label: "Est Total Remodel Cost",
    lowerLabel: "Advanced Remodel Cost",
    name: "est_total_remodel_cost",
  },
};

import React from 'react';
//import Button from '../Button/Button';
import Checkbox from '../Checkbox/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { googleOAuth, switchToSignin, switchToSignup } from '../../actions';
import { useNavigate } from 'react-router-dom';
import Google from '../../assets/google.svg';
import Logo from '../../images/bpoVerticalLogo.webp';
import {
	Button,
	Box,
	Divider,
	FormLabel,
	Grid,
	InputAdornment,
	Link,
	TextField,
	Typography,
	FormControlLabel,
	ButtonGroup,
	IconButton,
	Hidden,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { Contacts, Email, Person, Lock, Close, Facebook } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { formatPhoneNumber } from '../../utils/functions';
import { useGoogleLogin } from '@react-oauth/google';

const useStyles = makeStyles((theme) => ({
	field: {
		marginTop: '.5rem',
		marginBottom: '1.5rem',
	},
	logo: {
		width: '60%',
		display: 'block',
		margin: '0 auto',
		marginBottom: '1rem',
	},
	label: {
		fontSize: '1rem',
	},
	flex: {
		marginBottom: '2rem',
		display: 'flex',
		gap: '1rem',
		justifyContent: 'center',
		alignItems: 'center',
	},
	btnGoogle: {
		padding: '.5rem 0',
		borderRadius: '8px',
		textTransform: 'none',
		marginBottom: '1rem',
	},

	hoverActive: {
		borderBottom: `3px solid ${theme.secondaryColor}`,
	},
	modal: {
		[theme.breakpoints.down('sm')]: {
			// padding: '1rem',
			// maxWidth: '80vw',
		},
	},
}));

function AuthContent({ closeModal, accountDetails, setAccountDetails, onSubmit }) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const isSignIn = useSelector((state) => state.modals.authModal.isSignin);
	const navigate = useNavigate();

	const goToForgotPassword = () => {
		dispatch({ type: 'SET_MODAL' });
		navigate('/reset_password');
	};

	// Oauth with Google & Facebook

	const googleAuth = useGoogleLogin({
		onSuccess: (codeResponse) => {
			dispatch(googleOAuth(codeResponse.code, navigate));
		},
		redirect_uri: window.location.origin,
		flow: 'auth-code',
	});

	const facebookAuth = async () => {
		window.location.href = `https://www.facebook.com/v15.0/dialog/oauth?client_id=478122010963405&redirect_uri=${window.location.origin}/&state=login&return_scopes=true&scope=email`;
	};

	return (
		<>
			<Box padding={'2.5rem'} position='relative' className={classes.modal}>
				<img src={Logo} className={classes.logo} alt='logo' />
				<IconButton onClick={closeModal} sx={{ position: 'absolute', top: '5%', right: '5%' }}>
					<Close fontSize='small' />
				</IconButton>
				<Box padding='1rem 1rem 0 1rem' display='flex' gap={'2rem'}>
					<Typography variant='body1' sx={{ paddingBottom: '5px' }} className={(isSignIn && classes.hoverActive) || ''}>
						<Link
							underline='none'
							sx={{ cursor: 'pointer' }}
							onClick={() => {
								dispatch(switchToSignin());
							}}
							color={'textPrimary'}
							className={(isSignIn && 'list-active') || ''}
						>
							Sign in
						</Link>
					</Typography>
					<Typography variant='body1' sx={{ paddingBottom: '5px' }} className={(!isSignIn && classes.hoverActive) || ''}>
						<Link
							underline='none'
							sx={{ cursor: 'pointer' }}
							onClick={() => {
								dispatch(switchToSignup());
							}}
							color={'textPrimary'}
							className={(!isSignIn && 'list-active') || ''}
						>
							New account
						</Link>
					</Typography>
				</Box>
				<Divider sx={{ background: grey[200] }} />
				{isSignIn && (
					<>
						<Box padding='2rem 1rem 1rem 1rem'>
							<FormLabel className={classes.label}>Email</FormLabel>
							<TextField
								variant='outlined'
								name={'email'}
								onChange={(e) => setAccountDetails(e.target.name, e.target.value)}
								className={classes.field}
								value={accountDetails.email}
								fullWidth
								type='email'
								required
								placeholder='Enter email'
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											<Email fontSize='small' sx={{ color: grey[300] }} />
										</InputAdornment>
									),
								}}
							/>

							<FormLabel className={classes.label}>Password</FormLabel>
							<TextField
								variant='outlined'
								name='password'
								required
								type={'password'}
								inputProps={{ minLength: 8 }}
								onChange={(e) => setAccountDetails(e.target.name, e.target.value)}
								className={classes.field}
								value={accountDetails.password}
								fullWidth
								placeholder='Enter password'
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											<Lock fontSize='small' sx={{ color: grey[300] }} />
										</InputAdornment>
									),
								}}
							/>
							<Button
								variant='contained'
								disableElevation
								color='secondary'
								fullWidth
								sx={{ textTransform: 'none', height: '3.5rem', marginBottom: '1rem' }}
								onClick={onSubmit}
							>
								Sign in
							</Button>
							<Typography variant='body2' textAlign={'center'}>
								<Link underline='none' sx={{ cursor: 'pointer' }} onClick={goToForgotPassword} color={'secondary'}>
									Forgot your password?
								</Link>
							</Typography>
						</Box>
						<Divider sx={{ margin: '1.4rem 0' }} orientation='horizontal'>
							<Typography color='textSecondary' variant='body2'>
								or continue with
							</Typography>{' '}
						</Divider>
						<ButtonGroup fullWidth>
							<Button className={classes.btnGoogle} disableElevation variant='outlined' fullWidth color='secondary' onClick={() => googleAuth()}>
								<img style={{ marginRight: '1rem' }} width='15%' src={Google} alt='google_icon' /> <Hidden smDown>Google</Hidden>
							</Button>
							<Button
								className={classes.btnGoogle}
								startIcon={<Facebook />}
								disableElevation
								variant='outlined'
								fullWidth
								color='secondary'
								onClick={facebookAuth}
							>
								<Hidden smDown>Facebook</Hidden>&nbsp;
							</Button>

							{/* <Button
								className={classes.btnGoogle}
								startIcon={<Apple sx={{ color: '#000' }} />}
								disableElevation
								variant='outlined'
								fullWidth
								color='secondary'
							>
								<Hidden smDown>Apple</Hidden>
							</Button> */}
						</ButtonGroup>
					</>
				)}
				{!isSignIn && (
					<Box padding='2rem 1rem 1rem 1rem' height={'34.2rem'} overflow='scroll'>
						<FormLabel className={classes.label}>Email</FormLabel>
						<TextField
							variant='outlined'
							type={'email'}
							name={'email'}
							className={classes.field}
							value={accountDetails.email}
							fullWidth
							placeholder='Enter Email'
							onChange={(e) => setAccountDetails(e.target.name, e.target.value)}
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<Email fontSize='small' sx={{ color: grey[300] }} />
									</InputAdornment>
								),
							}}
						/>
						<Grid container spacing={3}>
							<Grid item sm={6}>
								<FormLabel className={classes.label}>First Name</FormLabel>
								<TextField
									variant='outlined'
									className={classes.field}
									value={accountDetails.first_name}
									fullWidth
									placeholder='First Name'
									name='first_name'
									onChange={(e) => setAccountDetails(e.target.name, e.target.value)}
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												<Person fontSize='small' sx={{ color: grey[300] }} />
											</InputAdornment>
										),
									}}
								/>
							</Grid>
							<Grid item sm={6}>
								<FormLabel className={classes.label}>Last Name</FormLabel>
								<TextField
									variant='outlined'
									className={classes.field}
									value={accountDetails.last_name}
									fullWidth
									placeholder='Last Name'
									name='last_name'
									onChange={(e) => setAccountDetails(e.target.name, e.target.value)}
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												<Person fontSize='small' sx={{ color: grey[300] }} />
											</InputAdornment>
										),
									}}
								/>
							</Grid>
						</Grid>
						<FormLabel className={classes.label}>Zip/Postal</FormLabel>
						<TextField
							variant='outlined'
							name='zip'
							className={classes.field}
							value={accountDetails.zip}
							onChange={(e) => setAccountDetails(e.target.name, e.target.value)}
							fullWidth
							placeholder='Zip/Postal Code'
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<Contacts fontSize='small' sx={{ color: grey[300] }} />
									</InputAdornment>
								),
							}}
						/>
						<Grid container spacing={3}>
							<Grid item sm={12}>
								<FormLabel className={classes.label}>Phone</FormLabel>
								<Grid item sm={12}>
									<TextField
										variant='outlined'
										type={'tel'}
										name='phone'
										value={accountDetails.phone || ''}
										inputProps={{ min: 0 }}
										onChange={(e) => setAccountDetails(e.target.name, formatPhoneNumber(e.target.value))}
										className={classes.field}
										fullWidth
										placeholder='Phone Number'
									/>
								</Grid>
							</Grid>
						</Grid>
						<FormLabel className={classes.label}>Password</FormLabel>
						<TextField
							variant='outlined'
							name='password'
							type={'password'}
							className={classes.field}
							value={accountDetails.password}
							onChange={(e) => setAccountDetails(e.target.name, e.target.value)}
							inputProps={{ minLength: 8 }}
							fullWidth
							placeholder='Password'
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<Lock fontSize='small' sx={{ color: grey[300] }} />
									</InputAdornment>
								),
							}}
						/>
						<FormLabel className={classes.label}>Confirm Password</FormLabel>
						<TextField
							variant='outlined'
							type={'password'}
							className={classes.field}
							value={accountDetails.passwordConfirmation}
							onChange={(e) => setAccountDetails(e.target.name, e.target.value)}
							name='passwordConfirmation'
							inputProps={{ minLength: 8 }}
							fullWidth
							placeholder='Confirm Password'
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<Lock fontSize='small' sx={{ color: grey[300] }} />
									</InputAdornment>
								),
							}}
						/>
						<FormControlLabel
							sx={{ marginBottom: '1rem' }}
							control={
								<Checkbox
									checked={accountDetails.isProfessional}
									id='prof'
									name={'isProfessional'}
									setChecked={(e) => {
										setAccountDetails('isProfessional', e.target.checked);
									}}
								/>
							}
							label={<Typography variant='body2'>I am a real estate broker or industry professional</Typography>}
						/>
						{/* {accountDetails.isProfessional && (
							<>
								<TextField
									variant='outlined'
									className={classes.field}
									fullWidth
									select
									defaultValue={''}
									onChange={(e) => setAccountDetails('professionType', e.target.value)}
									name='professionType'
									label='Select your category'
								>
									{professionalOptions?.map((item, index) => {
										return (
											<MenuItem key={index} value={item.value}>
												{item.label}
											</MenuItem>
										);
									})}
								</TextField>
							</>
						)} */}

						<Button
							variant='contained'
							disableElevation
							color='secondary'
							fullWidth
							sx={{ textTransform: 'none', height: '3.5rem', marginBottom: '1rem' }}
							onClick={onSubmit}
						>
							Sign Up
						</Button>
						<Typography variant='body2' sx={{ fontSize: '13px' }} color={'textSecondary'}>
							By signing in, you agree to BPO Homes's{' '}
							<Link href='/terms-of-service' onClick={closeModal}>
								Terms of Use
							</Link>{' '}
							and{' '}
							<Link href='/privacy-policy' onClick={closeModal}>
								Privacy Policy
							</Link>
						</Typography>
					</Box>
				)}
			</Box>
		</>
	);
}

export default AuthContent;

import {
	CalendarMonth,
	Call,
	CheckCircle,
	Close,
	Email,
	ErrorOutline,
	PhoneInTalk,
	PhoneMissed,
	Search,
	Tune,
	Voicemail,
} from "@mui/icons-material";
import {
	Box,
	Button,
	ButtonGroup,
	Card,
	CardContent,
	Checkbox,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	Divider,
	Drawer,
	FormControlLabel,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Pagination,
	Popover,
	Select,
	Slide,
	Stack,
	TextField,
	Toolbar,
	Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
import Selected from "react-select";
import React, { useEffect, useReducer, useRef, useState } from "react";
import LeadItem from "../../components/MUI-Components/LeadItem";
import LeadFiles from "../../assets/leadFiles.webp";
import { Container, styled } from "@mui/system";
import BPOLOGO from "../../images/bpoLogo.webp";
import _ from "lodash";
import { LoadingButton } from "@mui/lab";
import { dataURLtoFile, formatPhoneNumber } from "../../utils/functions";
// import ActivityItem from '../../components/MUI-Components/ActivityItem'
import SignatureCanvas from "react-signature-canvas";
import base from "../../apis";
import { toast } from "react-toastify";
import {
	timeData,
	californiaCounties,
	countiesAndCities,
} from "../../constants";
import dayjs from "dayjs";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { jsPDF } from "jspdf";
import exportFromJSON from "export-from-json";

const pdfFromReact = (target, name, orientation, resize, debug) => {
	if (resize) {
		document.querySelector(target).style.width =
			orientation === "p" ? "600px" : "830px";
		document.querySelector(target).style.minHeight =
			orientation === "p" ? "841px" : "595px";
	}
	let pdf = new jsPDF(orientation, "pt", "a4");
	pdf.html(document.querySelector(target), {
		callback: (pdf) => {
			if (debug) {
				window.open(pdf.output("bloburl"));
			}
			pdf.save("signDoc");
		},
	});

	return pdf.output("datauristring");
};

const selectStyles1 = {
	container: (provided) => ({
		...provided,
		flex: 1,
	}),
};

const drawerWidth = 400;

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
	wrap: {
		background: "#fff",
		padding: "1.5rem",
		borderRadius: "5px",
	},
	btn: {
		textTransform: "none",
	},
	listIcon: {
		minWidth: "35px",
	},
	listText: {
		"& span": {
			fontSize: "1rem",
		},
	},
	leadsWrap: {
		overflowY: "scroll",
		height: "100vh",
		padding: "5px",
	},
	details: {
		marginBottom: "1.5rem",
	},
}));

const CallBtn = styled(IconButton)(({ theme }) => ({
	padding: "1rem",
	borderRadius: "10px",
	margin: "8px 0",
}));

const FlexBox = styled(Box)(({ theme }) => ({
	// width: '30rem',
	display: "flex",
	gap: "1rem",
	alignItems: "flex-end",
	marginBottom: ".2rem",
	"& p": {
		width: "6rem",
	},
}));

export const getISODate = (dt) => {
	return new Date(dt).toISOString().split("T")[0];
};

// const getLastMonthDate = () => {
//     const currentDate = new Date();
//     const firstDayOfCurrentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
//     const lastDayOfPreviousMonth = new Date(firstDayOfCurrentMonth);
//     lastDayOfPreviousMonth.setDate(firstDayOfCurrentMonth.getDate() - 1);
//     const firstDayOfPreviousMonth = new Date(lastDayOfPreviousMonth.getFullYear(), lastDayOfPreviousMonth.getMonth(), 1);
//     return firstDayOfPreviousMonth.toISOString()?.split("T")[0];
//   };

const LiveLeads = () => {
	const classes = useStyles();
	const cityRef = useRef();
	const doc = useRef();
	const [open, setOpen] = useState(false);
	const [open2, setOpen2] = useState(false);
	const [show, setShow] = useState(false);
	const [load, setLoad] = useState(false);
	const [load2, setLoad2] = useState(false);
	const [data, setData] = useState();
	const [filteredData, setFilteredData] = useState();
	const [openDraw, setOpenDraw] = useState(false);
	const [contract, setContract] = useState(false);
	const [viewData, setViewData] = useState();
	const [val, setVal] = useState();
	// const [leadActivity, setLeadActivity] = useState([])
	// const [tabValue, setTabValue] = useState('1')
	const [loading, setLoading] = useState(false);
	const [response, setResponse] = useState({ open: false, status: null });
	const [refSign, setRefSign] = useState(false);
	const [recSign, setRecSign] = useState(false);
	const [schedule, setSchedule] = useState(false);
	const [cities, setCities] = useState([]);
	const [pageCount, setPageCount] = useState(null);
	const [page, setPage] = useState(1);

	const [searchParams, setSearchParams] = useState({
		county: null,
		city: null,
	});
	const [dateRange, setDateRange] = useState({
		start: dayjs("2024-07-01"),
		end: "",
	});
	const [NODLeads, setNODLeads] = useState();
	const [isFilter, setIsFilter] = useState("all");
	const [isLoading, setIsLoading] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const openCalendar = Boolean(anchorEl);

	useEffect(() => {
		const fetchLeads = async () => {
			try {
				setIsLoading(true);
				// const res = await base.get('leads/properties/')
				const rep = await base.get("leads/report/");
				const nod = await base.get("leads/report/nod/");
				const result = [...rep.data.results, ...nod.data.results];
				setData(result);
				setIsLoading(false);
				setFilteredData(result);
				setPageCount(nod?.data?.count);
			} catch (error) {
				setIsLoading(false);
				//console.log(error.response)
			}
		};
		fetchLeads();
	}, []);

	const onCountySelect = (e) => {
		if (!e) {
			setCities([]);
			setSearchParams({ ...searchParams, county: null, city: null });
			return;
		}
		setSearchParams({ ...searchParams, county: e, city: null });
		setCities(countiesAndCities[e.value]);
	};

	const onCityChange = (e) => {
		if (!e) {
			setSearchParams({ ...searchParams, city: null });
			return;
		}
		setSearchParams({ ...searchParams, city: e });
	};

	const initFilterState = {
		all: false,
		nod: false,
		buy: false,
		sell: false,
		ordered: false,
		premier: false,
		divorce: false,
		probate: false,
		expired: false,
		fsbo: false,
	};

	const initContract = {
		contractDate: new Date(Date.now()).toDateString(),
		clientName: "",
		referrerBroker: {
			name: "BPO HOMES INC",
			address: "6920 Santa Teresa Boulevard, San Jose, CA, USA",
			email: "operations@bpohomes.com",
			phone: "+1 888.616.5270",
			signature: "BPO HOMES INC.",
			signDate: new Date(Date.now()).toDateString(),
		},
		recipientAgent: {
			name: "",
			address: "",
			email: "",
			phone: "",
			signature: null,
			signDate: "",
		},
		recipientBroker: {
			name: "",
			address: "",
			email: "",
			phone: "",
		},
	};

	const filterReducer = (state, action) => {
		switch (action.type) {
			case "ALL": {
				return { ...initFilterState, all: true };
			}
			case "NOD": {
				return { ...initFilterState, nod: !state.nod };
			}
			case "BUY": {
				return { ...initFilterState, buy: !state.buy };
			}
			case "SELL": {
				return { ...initFilterState, sell: !state.sell };
			}
			case "ORDERED": {
				return { ...initFilterState, ordered: !state.ordered };
			}
			case "PREMIER": {
				return { ...initFilterState, premier: !state.premier };
			}
			case "DIVORCE": {
				return { ...initFilterState, divorce: !state.divorce };
			}
			case "PROBATE": {
				return { ...initFilterState, probate: !state.probate };
			}
			case "EXPIRED": {
				return { ...initFilterState, expired: !state.expired };
			}
			case "FSBO": {
				return { ...initFilterState, fsbo: !state.fsbo };
			}
			default:
				return state;
		}
	};

	const contractReducer = (state, action) => {
		switch (action.type) {
			case "DATE":
				return { ...state, contractDate: action.payload };
			case "CLIENT_NAME":
				return { ...state, clientName: action.payload };
			case "RF_NAME":
				return {
					...state,
					referrerBroker: { ...state.referrerBroker, name: action.payload },
				};
			case "RF_ADDRESS":
				return {
					...state,
					referrerBroker: { ...state.referrerBroker, address: action.payload },
				};
			case "RF_EMAIL":
				return {
					...state,
					referrerBroker: { ...state.referrerBroker, email: action.payload },
				};
			case "RF_PHONE":
				return {
					...state,
					referrerBroker: { ...state.referrerBroker, phone: action.payload },
				};
			case "RF_SIGN":
				return {
					...state,
					referrerBroker: {
						...state.referrerBroker,
						signature: action.payload,
					},
				};
			case "RF_SIGNDATE":
				return {
					...state,
					referrerBroker: {
						...state.referrerBroker,
						signDate: new Date(Date.now()).toLocaleDateString(),
					},
				};
			case "RA_NAME":
				return {
					...state,
					recipientAgent: { ...state.recipientAgent, name: action.payload },
				};
			case "RA_ADDRESS":
				return {
					...state,
					recipientAgent: { ...state.recipientAgent, address: action.payload },
				};
			case "RA_EMAIL":
				return {
					...state,
					recipientAgent: { ...state.recipientAgent, email: action.payload },
				};
			case "RA_PHONE":
				return {
					...state,
					recipientAgent: { ...state.recipientAgent, phone: action.payload },
				};
			case "RA_SIGN":
				return {
					...state,
					recipientAgent: {
						...state.recipientAgent,
						signature: action.payload,
					},
				};
			case "RA_SIGNDATE":
				return {
					...state,
					recipientAgent: {
						...state.recipientAgent,
						signDate: new Date(Date.now()).toLocaleDateString(),
					},
				};
			case "RB_NAME":
				return {
					...state,
					recipientBroker: { ...state.recipientBroker, name: action.payload },
				};
			case "RB_ADDRESS":
				return {
					...state,
					recipientBroker: {
						...state.recipientBroker,
						address: action.payload,
					},
				};
			case "RB_EMAIL":
				return {
					...state,
					recipientBroker: { ...state.recipientBroker, email: action.payload },
				};
			case "RB_PHONE":
				return {
					...state,
					recipientBroker: { ...state.recipientBroker, phone: action.payload },
				};
			case "RESET":
				return initContract;
			default:
				return state;
		}
	};

	const [filterInput, dispatch] = useReducer(filterReducer, initFilterState);
	const [contractDetails, dispatchContract] = useReducer(
		contractReducer,
		initContract
	);

	// const handleChange = (event, newValue) => {
	//     setTabValue(newValue);
	// };

	const handleClose = () => {
		setOpenDraw(false);
	};

	const viewHandler = async (val) => {
		setViewData(val);
		if (val?.can_view) {
			// const res = await base.get(`/favorite/activities/${val.id}/`)
			// setLeadActivity(res.data)
		}
	};

	const cancelContract = (val) => {
		setOpen(false);
		setContract(false);
		dispatchContract({ type: "RESET" });
	};

	const onSearch = async () => {
		if (isFilter === "nod") {
			setNODLeads();
			const leads = [];
			//available data
			if (!searchParams?.city?.value || !searchParams?.county?.value) {
				toast.error("Please select a county and a city");
				return;
			}
			setLoad2(true);
			setIsLoading(true);
			const params = {
				county: searchParams.county.label,
				city: searchParams.city.label,
			};
			if (dateRange?.start) params["start_date"] = dateRange?.start;
			if (dateRange?.start) params["end_date"] = dateRange?.end;

			const res = await base.get(`/leads/report/nod/`, {
				params,
			});

			leads.push(...res.data?.results);
			// console.log(leads)
			setLoad2(false);
			setIsLoading(false);
			setPageCount(res.data?.count);
			setFilteredData(res.data?.results);
			if (res.data?.count > 6) {
				const num = Math.ceil(res.data?.count / 6);
				for (let index = 2; index <= num; index++) {
					// console.log(index)

					const response = await base.get(`/leads/report/nod/?page=${index}`, {
						params,
					});
					leads.push(...response.data?.results);
				}
			}
			setNODLeads(leads);
		}
		if (isFilter === "divorce") {
			if (!searchParams?.county?.value) {
				toast.error("Please select a county");
				return;
			}
			setLoad2(true);
			setIsLoading(true);
			const { data: res } = await base.get(
				`leads/divorce/?county=${searchParams.county.label}&start_date=${dateRange?.start}&end_date=${dateRange?.end}`
			);
			setLoad2(false);
			setIsLoading(false);
			setPageCount(res?.count);
			setFilteredData(res?.results);
		}
		if (isFilter === "probate") {
			if (!searchParams?.county?.value) {
				toast.error("Please select a county");
				return;
			}
			setLoad2(true);
			setIsLoading(true);
			const { data: res } = await base.get(
				`leads/probate/?county=${searchParams.county.label}&start_date=${dateRange?.start}&end_date=${dateRange?.end}`
			);
			setLoad2(false);
			setIsLoading(false);
			setPageCount(res?.count);
			setFilteredData(res?.results);
		}
		if (isFilter === "expired") {
			if (!searchParams?.county?.value) {
				toast.error("Please select a county");
				return;
			}
			setLoad2(true);
			setIsLoading(true);
			const { data: res } = await base.get(
				`leads/expired/?county=${searchParams.county.label}&start_date=${dateRange?.start}&end_date=${dateRange?.end}`
			);
			setLoad2(false);
			setIsLoading(false);
			setPageCount(res?.count);
			setFilteredData(res?.results);
		}
		if (isFilter === "fsbo") {
			if (!searchParams?.county?.value) {
				toast.error("Please select a county");
				return;
			}
			setLoad2(true);
			setIsLoading(true);
			const { data: res } = await base.get(
				`leads/fsbo/?county=${searchParams.county.label}&start_date=${dateRange?.start}&end_date=${dateRange?.end}`
			);
			setLoad2(false);
			setIsLoading(false);
			setPageCount(res?.count);
			setFilteredData(res?.results);
		}
	};

	const handlePageChange = async (e, page) => {
		setPage(page);
		if (isFilter === "nod") {
			setOpen2(true);
			const county = `&county=${
				searchParams.county ? searchParams.county.label : null
			}`;
			const city = `&city=${
				searchParams.city ? searchParams?.city?.label : null
			}`;

			if (searchParams.county && searchParams.city) {
				const res = await base.get(
					`/leads/report/nod/?page=${page}${searchParams.county && county}${
						searchParams.city && city
					}&start_date=${dateRange?.start}&end_date=${dateRange?.end}`
				);
				setFilteredData(res.data?.results);
				setPageCount(res.data?.count);
				setOpen2(false);
				window.scrollTo({ top: 0 });
				return;
			} else {
				const res = await base.get(`/leads/report/nod/?page=${page}`);
				//console.log(res)
				setFilteredData(res.data?.results);
				setPageCount(res.data?.count);
				setOpen2(false);
				window.scrollTo({ top: 0 });
			}
		}
		if (isFilter === "divorce") {
			setOpen2(true);
			const county = `&county=${
				searchParams.county ? searchParams.county.label : null
			}`;

			if (searchParams.county) {
				const res = await base.get(
					`leads/divorce/?page=${page}${
						searchParams.county && county
					}&start_date=${dateRange?.start}&end_date=${dateRange?.end}`
				);
				setFilteredData(res.data?.results);
				setPageCount(res.data?.count);
				setOpen2(false);
				window.scrollTo({ top: 0 });
				return;
			} else {
				const res = await base.get(`leads/divorce/?page=${page}`);
				//console.log(res)
				setFilteredData(res.data?.results);
				setPageCount(res.data?.count);
				setOpen2(false);
				window.scrollTo({ top: 0 });
			}
		}
		if (isFilter === "probate") {
			setOpen2(true);
			const county = `&county=${
				searchParams.county ? searchParams.county.label : null
			}`;

			if (searchParams.county) {
				const res = await base.get(
					`leads/probate/?page=${page}${
						searchParams.county && county
					}&start_date=${dateRange?.start}&end_date=${dateRange?.end}`
				);
				setFilteredData(res.data?.results);
				setPageCount(res.data?.count);
				setOpen2(false);
				window.scrollTo({ top: 0 });
				return;
			} else {
				const res = await base.get(`leads/probate/?page=${page}`);
				//console.log(res)
				setFilteredData(res.data?.results);
				setPageCount(res.data?.count);
				setOpen2(false);
				window.scrollTo({ top: 0 });
			}
		}
		if (isFilter === "expired") {
			setOpen2(true);
			const county = `&county=${
				searchParams.county ? searchParams.county.label : null
			}`;

			if (searchParams.county) {
				const res = await base.get(
					`leads/expired/?page=${page}${
						searchParams.county && county
					}&start_date=${dateRange?.start}&end_date=${dateRange?.end}`
				);
				setFilteredData(res.data?.results);
				setPageCount(res.data?.count);
				setOpen2(false);
				window.scrollTo({ top: 0 });
				return;
			} else {
				const res = await base.get(`leads/expired/?page=${page}`);
				//console.log(res)
				setFilteredData(res.data?.results);
				setPageCount(res.data?.count);
				setOpen2(false);
				window.scrollTo({ top: 0 });
			}
		}
		if (isFilter === "fsbo") {
			setOpen2(true);
			const county = `&county=${
				searchParams.county ? searchParams.county.label : null
			}`;

			if (searchParams.county) {
				const res = await base.get(
					`leads/fsbo/?page=${page}${
						searchParams.county && county
					}&start_date=${dateRange?.start}&end_date=${dateRange?.end}`
				);
				setFilteredData(res.data?.results);
				setPageCount(res.data?.count);
				setOpen2(false);
				window.scrollTo({ top: 0 });
				return;
			} else {
				const res = await base.get(`leads/fsbo/?page=${page}`);
				//console.log(res)
				setFilteredData(res.data?.results);
				setPageCount(res.data?.count);
				setOpen2(false);
				window.scrollTo({ top: 0 });
			}
		} else {
			onFilter(page);
			window.scrollTo({ top: 0, behavior: "smooth" });
		}
	};

	const onFilter = async (page) => {
		setLoad(true);
		try {
			if (val === "all") {
				setIsLoading(true);
				setDateRange({ start: "", end: "" });
				setSearchParams({
					county: null,
					city: null,
				});
				setData([]);
				setFilteredData([]);
				const rep = await base.get(`leads/report/?page=${page ? page : 1}`);
				const nod = await base.get(`leads/report/nod/?page=${page ? page : 1}`);
				const result = [...rep.data.results, ...nod.data.results];
				setNODLeads();
				setLoad(false);
				setIsLoading(false);
				setData(result);
				setFilteredData(result);
				setOpenDraw(false);
				setIsFilter("all");
				return;
			}
			if (val === "premier") {
				window.location?.assign("/live-leads/premier");
			} else {
				setData([]);
				setDateRange({ start: "", end: "" });
				setSearchParams({ county: null, city: null });
				setIsLoading(true);
				setFilteredData([]);
				const rep =
					val === "ordered"
						? await base.get(`leads/report/?page=${page ? page : 1}`)
						: null;
				const nod =
					val === "nod"
						? await base.get(`leads/report/nod/?page=${page ? page : 1}`)
						: null;
				const prob =
					val === "probate"
						? await base.get(`leads/probate/?page=${page ? page : 1}`)
						: null;
				const div =
					val === "divorce"
						? await base.get(`leads/divorce/?page=${page ? page : 1}`)
						: null;
				const exp =
					val === "expired"
						? await base.get(`leads/expired/?page=${page ? page : 1}`)
						: null;
				const fsbo =
					val === "fsbo"
						? await base.get(`leads/fsbo/?page=${page ? page : 1}`)
						: null;

				const repData = val === "ordered" ? rep?.data?.results : [];
				const nodData = val === "nod" ? nod?.data?.results : [];
				const probData = val === "probate" ? prob?.data?.results : [];
				const divData = val === "divorce" ? div?.data?.results : [];
				const expData = val === "expired" ? exp?.data?.results : [];
				const fsData = val === "fsbo" ? fsbo?.data?.results : [];

				const repCount = val === "ordered" ? rep?.data?.count : 0;
				const nodCount = val === "nod" ? nod?.data?.count : 0;
				const probCount = val === "probate" ? prob?.data?.count : 0;
				const divCount = val === "divorce" ? div?.data?.count : 0;
				const expCount = val === "expired" ? exp?.data?.count : 0;
				const fbCount = val === "fsbo" ? fsData?.data?.count : 0;

				const result = [
					...repData,
					...nodData,
					...probData,
					...divData,
					...expData,
					...fsData,
				];
				const total =
					repCount + nodCount + probCount + divCount + expCount + fbCount;

				setData(result);
				setFilteredData(result);
				setNODLeads(result);
				setIsLoading(false);
				setLoad(false);
				setOpenDraw(false);
				setPageCount(total);
				setIsFilter(val);
				setViewData(null);
				return;
			}
		} catch (error) {
			setLoad(false);
		}
	};

	const handleFilters = (value) => {
		setVal(value);
		dispatch({ type: `${value.toUpperCase()}` });
	};

	const onContractSign = async () => {
		//validate
		if (
			contractDetails.contractDate === "" ||
			contractDetails.clientName === "" ||
			contractDetails.referrerBroker.name === "" ||
			contractDetails.referrerBroker.address === "" ||
			contractDetails.referrerBroker.email === "" ||
			contractDetails.referrerBroker.phone === "" ||
			contractDetails.referrerBroker.signature === null ||
			contractDetails.recipientAgent.name === "" ||
			contractDetails.recipientAgent.address === "" ||
			contractDetails.recipientAgent.email === "" ||
			contractDetails.recipientAgent.phone === "" ||
			contractDetails.recipientAgent.signature === null ||
			contractDetails.recipientBroker.name === "" ||
			contractDetails.recipientBroker.address === "" ||
			contractDetails.recipientBroker.email === "" ||
			contractDetails.recipientBroker.phone === ""
		) {
			toast.warn("Sorry, all fields required");
			return;
		}
		setLoading(true);
		const blobURL = pdfFromReact("#print", "signContract", "p", true, false);
		// console.log(blobURL)
		// const savedFile = new File([res], "contract_Doc.pdf", { type: 'application/pdf' });
		// console.log(savedFile)
		const formData = new FormData();
		formData.append(
			"signature",
			dataURLtoFile(contractDetails.recipientAgent.signature, "leadSign.png")
		);
		formData.append("docfile", dataURLtoFile(blobURL, "contract_Doc.pdf"));

		const data = {
			lead_id: viewData?.id,
			lead_type: viewData?.lead_type?.startsWith("buy") ? "buyer" : "seller",
			referring_broker_name: contractDetails.referrerBroker.name,
			referring_broker_email: contractDetails.referrerBroker.email,
			referring_broker_phone: contractDetails.referrerBroker.phone,
			referring_broker_address: contractDetails.referrerBroker.address,
			recipient_broker_name: contractDetails.recipientBroker.name,
			recipient_broker_email: contractDetails.recipientBroker.email,
			recipient_broker_phone: contractDetails.recipientBroker.phone,
			recipient_broker_address: contractDetails.recipientBroker.address,
			recipient_agent_name: contractDetails.recipientAgent.name,
			recipient_agent_email: contractDetails.recipientAgent.email,
			recipient_agent_phone: contractDetails.recipientAgent.phone,
			recipient_agent_address: contractDetails.recipientAgent.address,
		};
		// make request  calls
		try {
			const res = await base.post("/contract/create/", data);
			setViewData(res.data?.lead);
			const update = await base.put(
				`/contract/update/${res.data?.contract?.id}/`,
				formData,
				{
					headers: {
						"content-type": "multipart/form-data",
					},
				}
			);
			// console.log(update)
			if (update.status === 200) {
				setResponse({ open: true, status: "success" });
				setLoading(false);
				setTimeout(() => {
					setResponse({ open: false, status: null });
					cancelContract();
				}, 1000);
			}
		} catch (error) {
			setLoading(false);
			toast.warn("Sorry, something went wrong. Please try again");
		}
	};

	const onDialogClose = () => {
		setResponse({ open: false, status: null });
		cancelContract();
	};

	const testView = () => {
		setShow(true);
	};

	// signature functions //
	let signPadReferrer = {};
	let signPadRecipient = {};

	const clearPadReferrer = () => {
		signPadReferrer.clear();
	};
	const clearPadRecipient = () => {
		signPadRecipient.clear();
	};
	const signReferrer = () => {
		dispatchContract({
			type: "RF_SIGN",
			payload: signPadReferrer.getTrimmedCanvas().toDataURL("image/png"),
		});
		dispatchContract({ type: "RF_SIGNDATE" });
		//close box
		setRefSign(false);
	};
	const signRecipient = () => {
		dispatchContract({
			type: "RA_SIGN",
			payload: signPadRecipient.getTrimmedCanvas().toDataURL("image/png"),
		});
		dispatchContract({ type: "RA_SIGNDATE" });
		//close box
		setRecSign(false);
	};

	const createActivity = async (val) => {
		setOpen2(true);
		try {
			const res = await base.post(`/favorite/activities/`, {
				agent_id: 0,
				customer_id: 0,
				description: val,
				lead_id: viewData.id,
			});

			if (res.data) {
				// const logs = await base.get(`/favorite/activities/${viewData.id}/`)
				// //console.log(logs.data)
				// setLeadActivity(logs.data)
			}
			setOpen2(false);
		} catch (error) {
			//console.log(error.response)
			setOpen2(false);
			toast.warn("Sorry, something went wrong. Please try again");
		}
	};

	const exportAsCSV = () => {
		const data = NODLeads;
		const fileName = isFilter + "_Leads";
		const exportType = exportFromJSON.types.csv;

		exportFromJSON({ data, fileName, exportType });
	};

	return (
		<div>
			<Grid container>
				<Grid item md={4} lg={4}>
					<Box padding={"2rem"}>
						<Box
							display={"flex"}
							justifyContent="space-between"
							alignItems={"flex-start"}
							mb="1rem"
						>
							<span>
								<Typography variant="h6" sx={{ fontWeight: 600 }}>
									{isFilter === "fsbo" ? "FSBO" : _.startCase(isFilter)}{" "}
									{isFilter === "expired" ? "Listings" : "Leads"}
								</Typography>
								<Typography variant="body2" color="secondary">
									Showing {filteredData?.length} results{" "}
								</Typography>
							</span>
							<Button
								variant="text"
								className={classes.btn}
								onClick={() => setOpenDraw(true)}
								color="secondary"
								endIcon={<Tune fontSize="small" />}
							>
								Filter
							</Button>
						</Box>

						{/* Search Field */}
						{isFilter === "nod" && (
							<Box
								sx={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
									gap: ".8rem",
								}}
							>
								<Selected
									placeholder="County"
									options={californiaCounties}
									isSearchable={true}
									name="city"
									value={searchParams.county}
									onChange={onCountySelect}
									styles={selectStyles1}
									isClearable={true}
								/>
								<Selected
									placeholder="City"
									options={cities}
									ref={cityRef}
									isSearchable={true}
									onChange={onCityChange}
									styles={selectStyles1}
									isClearable={true}
									value={searchParams.city}
								/>
								<IconButton
									size="small"
									sx={{ bgcolor: grey[200], borderRadius: "5px" }}
									onClick={(e) => setAnchorEl(e?.currentTarget)}
								>
									<CalendarMonth />
								</IconButton>

								<LoadingButton
									onClick={onSearch}
									loading={load2}
									disableElevation
									variant="contained"
									color="secondary"
								>
									<Search />
								</LoadingButton>
							</Box>
						)}
						{(isFilter === "probate" ||
							isFilter === "divorce" ||
							isFilter === "expired" ||
							isFilter === "fsbo") && (
							<Box
								sx={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
									gap: ".8rem",
								}}
							>
								<Selected
									placeholder="County"
									options={californiaCounties}
									isSearchable={true}
									name="city"
									value={searchParams.county}
									onChange={onCountySelect}
									styles={selectStyles1}
									isClearable={true}
								/>
								<IconButton
									size="small"
									sx={{ bgcolor: grey[200], borderRadius: "5px" }}
									onClick={(e) => setAnchorEl(e?.currentTarget)}
								>
									<CalendarMonth />
								</IconButton>
								<LoadingButton
									onClick={onSearch}
									loading={load2}
									disableElevation
									variant="contained"
									color="secondary"
								>
									<Search />
								</LoadingButton>
							</Box>
						)}

						{/* Populate Leads */}
						<Box className={classes.leadsWrap}>
							{isLoading ? (
								<Box
									textAlign={"center"}
									display={"flex"}
									flexDirection={"column"}
									height={"10rem"}
									justifyContent={"center"}
								>
									<Typography mb={1}>Loading...Please wait</Typography>
									<CircularProgress
										value={20}
										size={30}
										color="secondary"
										sx={{ margin: "0 auto" }}
									/>
								</Box>
							) : filteredData?.length > 0 ? (
								filteredData?.map((el) => {
									return (
										<LeadItem
											date={el?.date}
											handleClick={() => viewHandler(el)}
											key={el.id}
											name={
												el?.first_name ||
												(el?.pr_first_name
													? `${el?.pr_first_name} ${el?.pr_last_name}`
													: null) ||
												el?.defendant_first_name ||
												el?.owner_1_name ||
												el?.client_register?.user?.first_name ||
												_.startCase(
													_.lowerCase(el.borrower_name_owner).split(" ")[0]
												)
											}
											type={
												el.lead_type?.startsWith("ordered")
													? "Ordered Lead"
													: el.lead_type?.startsWith("nod") ||
													  el.lead_type?.startsWith("NOD")
													? "NOD Lead"
													: el.document_type?.toLowerCase() === "probate"
													? "Probate Lead"
													: el.document_type?.toLowerCase() === "expired"
													? "Expired Lead"
													: el.document_type?.toLowerCase() === "fsbo"
													? "FSBO Leads"
													: "Divorce Lead"
											}
											location={
												el.property_address ||
												el?.address ||
												el.clients_physical_address ||
												el.property_address_full +
													(el.property_address_zip || "")
											}
											city={
												isFilter === "divorce" || isFilter === "probate"
													? el?.county_name
													: el?.property_address_city ||
													  el?.property_city ||
													  el?.city
											}
											state={el.situs_state_code || el?.state}
										/>
									);
								})
							) : (
								<Box
									sx={{
										my: 3,
										p: 3,
										textAlign: "center",
										border: "1px solid lightgrey",
										borderRadius: "10px",
									}}
								>
									<img
										alt="nolead"
										src={LeadFiles}
										width="20%"
										style={{ margin: "0 auto", marginBottom: "1rem" }}
									/>
									<Typography>No leads</Typography>
								</Box>
							)}

							{/* Pagination */}
							<Box marginBottom={"4rem"}>
								{!isLoading && (
									<Pagination
										size="medium"
										color="secondary"
										sx={{ display: "flex", justifyContent: "center" }}
										shape="rounded"
										variant="outlined"
										page={page}
										count={Math.ceil(pageCount / 12)}
										onChange={handlePageChange}
									/>
								)}
							</Box>
						</Box>
					</Box>
				</Grid>

				<Grid item md={8} lg={8}>
					<Box bgcolor={grey[100]} padding="1rem" height={"100%"}>
						<Box
							display={"flex"}
							gap=".3rem"
							justifyContent={"flex-end"}
							mb="2rem"
						>
							{NODLeads && (
								<Button
									variant="contained"
									size="small"
									className={classes.btn}
									sx={{ color: "#fff" }}
									onClick={exportAsCSV}
									disableElevation
								>
									Export CSV
								</Button>
							)}
							<Button variant="text" className={classes.btn} disableElevation>
								Leads
							</Button>
							<Button
								variant="text"
								className={classes.btn}
								disableElevation
								color="secondary"
							>
								My Responses
							</Button>
						</Box>
						{viewData ? (
							<>
								<Box bgcolor={"#fff"} padding="1.5rem" borderRadius={"5px"}>
									<Grid container>
										<Grid item lg={6}>
											<Stack>
												{viewData?.lead_type === "ordered" && (
													<div>
														<Typography variant="h6">
															{viewData?.client_register?.user?.first_name +
																" " +
																viewData?.client_register?.user?.last_name}
														</Typography>
														<Typography variant="body1">
															Ordered Report
														</Typography>
													</div>
												)}
												{(viewData?.lead_type?.startsWith("nod") ||
													viewData?.lead_type?.startsWith("NOD")) && (
													<div>
														<Typography variant="h6">
															{viewData?.borrower_name_owner}
														</Typography>
														<Typography variant="body1">NOD Lead</Typography>
													</div>
												)}
												{viewData?.document_type?.toLowerCase() ===
													"probate" && (
													<div>
														<Typography variant="h6">
															{viewData?.first_name + " " + viewData?.last_name}
														</Typography>
														<Typography variant="body1">
															Probate Lead
														</Typography>
													</div>
												)}
												{viewData?.document_type?.toLowerCase() ===
													"divorce" && (
													<div>
														<Typography variant="h6">
															{viewData?.defendant_first_name +
																" " +
																viewData?.defendant_last_name}
														</Typography>
														<Typography variant="body1">
															Divorce Lead
														</Typography>
													</div>
												)}
												{viewData?.document_type?.toLowerCase() ===
													"expired" && (
													<div>
														<Typography variant="h6">
															{viewData?.owner_1_name}
														</Typography>
														<Typography variant="body1">
															Expired Lead
														</Typography>
													</div>
												)}
												{viewData?.document_type?.toLowerCase() === "fsbo" && (
													<div>
														<Typography variant="h6">
															{viewData?.owner_1_name}
														</Typography>
														<Typography variant="body1">FSBO Lead</Typography>
													</div>
												)}

												<Typography
													sx={{ fontSize: ".9rem" }}
													variant="body2"
													paragraph
													color="textSecondary"
												>
													{viewData?.property_address ||
														viewData?.clients_physical_address ||
														`${
															viewData?.property_address_full ||
															viewData?.address
														}, ${
															viewData?.property_address_city || viewData?.city
														}, ${
															viewData?.situs_state_code || viewData?.state
														} ${
															viewData?.property_address_zip ||
															viewData?.zip ||
															""
														}`}
												</Typography>
												<Box>
													{viewData?.lead_type === "nod" ||
													viewData?.lead_type === "NOD" ||
													viewData?.document_type === "PROBATE" ||
													viewData?.document_type === "DIVORCE" ||
													viewData?.document_type === "expired" ||
													viewData?.document_type === "fsbo" ? null : (
														<List disablePadding>
															<ListItem disablePadding>
																<ListItemIcon className={classes.listIcon}>
																	<Call fontSize="small" />
																</ListItemIcon>
																<ListItemText className={classes.listText}>
																	{!viewData?.can_view ? (
																		<>
																			{viewData.phone
																				? viewData.phone.replace(
																						/\d(?=\d{3})/g,
																						"*"
																				  )
																				: viewData.client_register
																				? viewData.client_register.phone.replace(
																						/\d(?=\d{3})/g,
																						"*"
																				  )
																				: null}
																		</>
																	) : (
																		<>
																			{viewData.phone
																				? viewData.phone
																				: viewData.client_register
																				? viewData.client_register.phone
																				: null}
																		</>
																	)}
																</ListItemText>
															</ListItem>
															<ListItem disablePadding>
																<ListItemIcon className={classes.listIcon}>
																	<Email fontSize="small" />
																</ListItemIcon>
																<ListItemText className={classes.listText}>
																	{viewData?.can_view ? (
																		<>
																			{viewData.email
																				? viewData.email
																				: viewData.client_register
																				? viewData.client_register.user.email
																				: null}
																		</>
																	) : (
																		<>
																			{viewData.email
																				? viewData.email.replace(
																						/\D(?=\D{3})/g,
																						"*"
																				  )
																				: viewData.client_register
																				? viewData.client_register.user.email.replace(
																						/\D(?=\D{3})/g,
																						"*"
																				  )
																				: null}
																		</>
																	)}
																</ListItemText>
															</ListItem>
														</List>
													)}
												</Box>
											</Stack>
										</Grid>

										<Grid item lg={6}>
											{(!viewData?.can_view &&
												viewData?.lead_type === "sell") ||
											(!viewData?.can_view &&
												viewData?.lead_type?.startsWith("buy")) ? (
												<Box
													display={"flex"}
													gap=".6rem"
													alignItems={"flex-end"}
													justifyContent={"flex-end"}
													height="100%"
												>
													<Button
														variant="contained"
														color="secondary"
														className={classes.btn}
														disableElevation
														onClick={() => {
															dispatchContract({
																type: "CLIENT_NAME",
																payload: `${viewData?.first_name} ${viewData?.last_name}`,
															});
															setOpen(true);
														}}
													>
														Contact{" "}
														{viewData
															? viewData?.first_name
															: viewData?.client_register?.user.first_name}
													</Button>
													<Button
														variant="text"
														className={classes.btn}
														disableElevation
														color="secondary"
													>
														Not Interested
													</Button>
												</Box>
											) : (
												<>
													{viewData?.lead_type === "nod" ||
													viewData?.lead_type === "NOD" ||
													viewData?.document_type === "PROBATE" ||
													viewData?.document_type === "DIVORCE" ||
													viewData?.document_type === "expired" ||
													viewData?.document_type === "fsbo" ? null : (
														<Box
															display={"flex"}
															gap=".6rem"
															alignItems={"flex-end"}
															justifyContent={"flex-end"}
															height="100%"
														>
															<Button
																variant="contained"
																startIcon={<Call />}
																color="secondary"
																onClick={testView}
																className={classes.btn}
																disableElevation
															>
																Call{" "}
																{viewData
																	? viewData.first_name
																	: viewData.client_register?.user.first_name}
															</Button>
															{/* <Button variant='contained' startIcon={<Email />} className={classes.btn} disableElevation color='secondary'> Email</Button>
                                                                            <Button variant='contained' startIcon={<Message />}  className={classes.btn} disableElevation color='secondary'> SMS</Button> */}
														</Box>
													)}
												</>
											)}
										</Grid>
									</Grid>
								</Box>

								<Divider sx={{ m: "1.5rem 0", bgcolor: grey[200] }} />
								<Box className={classes.wrap}>
									<>
										{viewData?.lead_type === "ordered" && (
											<>
												<Typography sx={{ fontWeight: 600 }} mb={3}>
													Ordered Report Details
												</Typography>

												{/* Details for Seller Agent */}
												<Stack className={classes.details}>
													<Typography color={"textSecondary"}>
														Full Property Address
													</Typography>
													<Typography color={"textPrimary"} variant="body2">
														{viewData?.clients_physical_address}
													</Typography>
												</Stack>
												<Stack className={classes.details}>
													<Typography color={"textSecondary"}>
														Why would you like a BPO Report?
													</Typography>
													<Typography color={"textPrimary"} variant="body2">
														{viewData?.reason_for_request}
													</Typography>
												</Stack>
												<Stack className={classes.details}>
													<Typography color={"textSecondary"}>
														Payment status?{" "}
													</Typography>
													<Typography color={"textPrimary"} variant="body2">
														{viewData?.payment_status}
													</Typography>
												</Stack>
											</>
										)}
										{(viewData?.lead_type?.startsWith("NOD") ||
											viewData?.lead_type === "nod") && (
											<>
												<Typography variant="h6" mb={3}>
													NOD Details
												</Typography>

												{/* Details for Seller Agent */}
												<Stack className={classes.details}>
													<Typography color={"textSecondary"}>
														Full Property Address
													</Typography>
													<Typography color={"textPrimary"} variant="body2">
														{`${viewData?.property_address_full}, ${viewData?.property_address_city}, ${viewData?.situs_state_code}`}
													</Typography>
												</Stack>
												<Stack className={classes.details}>
													<Typography color={"textSecondary"}>
														Type of Property
													</Typography>
													<Typography color={"textPrimary"} variant="body2">
														{viewData?.property_use_group}
													</Typography>
												</Stack>
												<Stack className={classes.details}>
													<Typography color={"textSecondary"}>
														Year built
													</Typography>
													<Typography color={"textPrimary"} variant="body2">
														{viewData?.year_built}
													</Typography>
												</Stack>
												<Stack className={classes.details}>
													<Typography color={"textSecondary"}>
														Bedroom Count{" "}
													</Typography>
													<Typography color={"textPrimary"} variant="body2">
														{viewData?.bedrooms_count || 0}
													</Typography>
												</Stack>
												<Stack className={classes.details}>
													<Typography color={"textSecondary"}>
														Bathroom Count{" "}
													</Typography>
													<Typography color={"textPrimary"} variant="body2">
														{viewData?.bath_count || 0}
													</Typography>
												</Stack>
											</>
										)}
										{(viewData?.document_type?.toLowerCase() === "probate" ||
											viewData?.document_type?.toLowerCase() === "divorce" ||
											viewData?.document_type?.toLowerCase() === "expired" ||
											viewData?.document_type?.toLowerCase() === "fsbo") && (
											<>
												<Typography variant="h6" mb={3}>
													Lead Details
												</Typography>

												{/* Details for Seller Agent */}
												<Stack className={classes.details}>
													<Typography color={"textSecondary"}>
														Full Property Address
													</Typography>
													<Typography color={"textPrimary"} variant="body2">
														{`${
															viewData?.property_address || viewData?.address
														}, ${viewData?.property_city || viewData?.city}, ${
															viewData?.state
														}`}
													</Typography>
												</Stack>
												<Stack className={classes.details}>
													<Typography color={"textSecondary"}>
														Type of Property
													</Typography>
													<Typography color={"textPrimary"} variant="body2">
														{viewData?.property_use || viewData?.property_type}
													</Typography>
												</Stack>
												<Stack className={classes.details}>
													<Typography color={"textSecondary"}>
														Year built
													</Typography>
													<Typography color={"textPrimary"} variant="body2">
														{viewData?.year_built}
													</Typography>
												</Stack>
												<Stack className={classes.details}>
													<Typography color={"textSecondary"}>
														Bedroom Count{" "}
													</Typography>
													<Typography color={"textPrimary"} variant="body2">
														{viewData?.bedrooms || 0}
													</Typography>
												</Stack>
												<Stack className={classes.details}>
													<Typography color={"textSecondary"}>
														Bathroom Count{" "}
													</Typography>
													<Typography color={"textPrimary"} variant="body2">
														{viewData?.bathrooms || 0}
													</Typography>
												</Stack>
											</>
										)}
									</>
								</Box>
							</>
						) : (
							<Box textAlign={"center"} sx={{ marginTop: "8rem" }}>
								<img
									alt="nolead"
									src={LeadFiles}
									width="15%"
									style={{ marginBottom: "1rem" }}
								/>
								<Typography variant="h6">No Lead Selected</Typography>
								<Typography variant="body2" color={"textSecondary"}>
									Click on a Lead on the left tray to view details here
								</Typography>
							</Box>
						)}
					</Box>
				</Grid>
			</Grid>

			{/* DIALOG */}
			<Dialog open={open} maxWidth="md">
				<DialogContent>
					<Typography variant="body2">
						This Lead will attract a 10% of the total gross compensation earned
					</Typography>
				</DialogContent>

				<DialogActions sx={{ padding: "1rem" }}>
					<Button
						className={classes.btn}
						color="secondary"
						sx={{ border: (theme) => `1px solid ${theme.secondaryColor}` }}
						variant="text"
						disableElevation
						onClick={() => setOpen(false)}
					>
						Decline
					</Button>
					<Button
						className={classes.btn}
						variant="contained"
						color="secondary"
						disableElevation
						onClick={() => setContract(true)}
					>
						Accept
					</Button>
				</DialogActions>
			</Dialog>

			{/* Filters */}
			<Drawer
				anchor="left"
				sx={{
					position: "relative",
					"& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
				}}
				open={openDraw}
				onClose={handleClose}
				classes={{ paper: drawerWidth }}
			>
				<Toolbar />
				<Box sx={{ padding: "3rem" }}>
					<span
						style={{
							display: "flex",
							justifyContent: "space-between",
							marginBottom: "2rem",
						}}
					>
						<Typography variant="h6">Filters</Typography>
						<IconButton onClick={handleClose}>
							<Close fontSize="small" />
						</IconButton>
					</span>
					<Typography mb={3} color="secondary">
						LEAD TYPES
					</Typography>
					<List disablePadding>
						<ListItem disablePadding>
							<FormControlLabel
								value={"all"}
								onChange={(e) => handleFilters(e.target.value)}
								control={
									<Checkbox
										checked={filterInput.all}
										size="small"
										color="secondary"
									/>
								}
								label={
									<Typography variant="body2">All ({data?.length})</Typography>
								}
							/>
						</ListItem>
						<ListItem disablePadding>
							<FormControlLabel
								value={"nod"}
								onChange={(e) => handleFilters(e.target.value)}
								control={
									<Checkbox
										checked={filterInput.nod}
										size="small"
										color="secondary"
									/>
								}
								label={
									<Typography variant="body2">Notice of Default</Typography>
								}
							/>
						</ListItem>
						<ListItem disablePadding>
							<FormControlLabel
								value={"premier"}
								onChange={(e) => handleFilters(e.target.value)}
								control={
									<Checkbox
										checked={filterInput.premier}
										size="small"
										color="secondary"
									/>
								}
								label={
									<Typography variant="body2">Premier Agent Leads</Typography>
								}
							/>
						</ListItem>
						<ListItem disablePadding>
							<FormControlLabel
								value={"ordered"}
								onChange={(e) => handleFilters(e.target.value)}
								control={
									<Checkbox
										checked={filterInput.ordered}
										size="small"
										color="secondary"
									/>
								}
								label={
									<Typography variant="body2">
										Ordered BPO Request Leads
									</Typography>
								}
							/>
						</ListItem>
						<ListItem disablePadding>
							<FormControlLabel
								value={"divorce"}
								onChange={(e) => handleFilters(e.target.value)}
								control={
									<Checkbox
										checked={filterInput.divorce}
										size="small"
										color="secondary"
									/>
								}
								label={<Typography variant="body2">Divorce Leads</Typography>}
							/>
						</ListItem>
						<ListItem disablePadding>
							<FormControlLabel
								value={"probate"}
								onChange={(e) => handleFilters(e.target.value)}
								control={
									<Checkbox
										checked={filterInput.probate}
										size="small"
										color="secondary"
									/>
								}
								label={<Typography variant="body2">Probate Leads</Typography>}
							/>
						</ListItem>
						<ListItem disablePadding>
							<FormControlLabel
								value={"expired"}
								onChange={(e) => handleFilters(e.target.value)}
								control={
									<Checkbox
										checked={filterInput.expired}
										size="small"
										color="secondary"
									/>
								}
								label={
									<Typography variant="body2">Expired Listings</Typography>
								}
							/>
						</ListItem>
						<ListItem disablePadding>
							<FormControlLabel
								value={"fsbo"}
								onChange={(e) => handleFilters(e.target.value)}
								control={
									<Checkbox
										checked={filterInput.fsbo}
										size="small"
										color="secondary"
									/>
								}
								label={<Typography variant="body2">FSBO Leads</Typography>}
							/>
						</ListItem>
					</List>
				</Box>

				<Box
					sx={{
						width: "100%",
						position: "absolute",
						bottom: 0,
						padding: "2rem",
						borderTop: "1px solid lightgrey",
					}}
				>
					<LoadingButton
						loading={load}
						variant="contained"
						onClick={() => onFilter()}
						color="secondary"
						disableElevation
						sx={{ textTransform: "none", width: "7rem" }}
					>
						Apply
					</LoadingButton>
				</Box>
			</Drawer>

			{/* Contract */}
			<Dialog
				open={contract}
				fullScreen
				fullWidth
				TransitionComponent={Transition}
			>
				<Box
					bgcolor={"secondary.main"}
					sx={{
						display: "flex",
						justifyContent: "space-between",
						padding: "1.5rem 4rem",
						marginBottom: "3rem",
						color: "#fff",
					}}
				>
					<Typography color="paper">
						Please review the document below
					</Typography>
					<Box sx={{ display: "flex", gap: "1rem" }}>
						<LoadingButton
							loading={loading}
							onClick={onContractSign}
							variant="contained"
							color="paper"
							sx={{ color: "secondary.main", textTransform: "none" }}
						>
							Sign Contract
						</LoadingButton>

						<Button
							variant="outlined"
							color="paper"
							sx={{ textTransform: "none" }}
							onClick={cancelContract}
						>
							Close
						</Button>

						{/* <Button variant='outlined' color='paper' sx={{textTransform: 'none'}} onClick={() => {
                        const file = pdfFromReact('#print',"My-file", 'p', true, false )
                        console.log(file)
                        setDocFile(file)
                    }}>Save File</Button> */}
					</Box>
				</Box>
				<DialogContent>
					<Container id="print">
						<Box
							ref={doc}
							sx={{
								height: "100%",
								border: "1px solid lightgrey",
								padding: "3rem 1rem",
								width: "100%",
								minHeight: "840px",
							}}
						>
							<img
								alt="logo"
								src={BPOLOGO}
								width="17%"
								style={{
									margin: "0 auto",
									display: "block",
									marginBottom: "1rem",
								}}
							/>
							<Typography
								mb={1}
								variant="h6"
								sx={{ fontWeight: 600 }}
								textAlign="center"
								textTransform={"uppercase"}
							>
								Partner Premier Agent
							</Typography>
							<Typography
								mb={3}
								sx={{ fontWeight: 600 }}
								textAlign="center"
								variant="h6"
							>
								Referral Fee Agreement
							</Typography>

							{/* Signed Details */}
							<Box mb={4}>
								<Typography
									mb={1}
									sx={{ fontWeight: 600 }}
									textTransform={"capitalize"}
								>
									Agreement Date:
								</Typography>
								<FlexBox>
									<Typography>Date:</Typography>
									<TextField
										value={contractDetails.contractDate}
										disabled={true}
										fullWidth
										variant="standard"
									/>
								</FlexBox>
							</Box>
							<Box mb={4}>
								<Typography
									mb={1}
									sx={{ fontWeight: 600 }}
									textTransform={"capitalize"}
								>
									Referring Broker:
								</Typography>
								<FlexBox>
									<Typography>Name:</Typography>
									<TextField
										value={contractDetails.referrerBroker.name}
										fullWidth
										variant="standard"
									/>
								</FlexBox>
								<FlexBox>
									<Typography>Email:</Typography>
									<TextField
										value={contractDetails.referrerBroker.email}
										fullWidth
										type={"email"}
										variant="standard"
									/>
								</FlexBox>
								<FlexBox>
									<Typography>Address:</Typography>
									<TextField
										value={contractDetails.referrerBroker.address}
										fullWidth
										variant="standard"
									/>
								</FlexBox>
								<FlexBox>
									<Typography>Phone:</Typography>
									<TextField
										value={
											"1" +
											formatPhoneNumber(contractDetails.referrerBroker.phone)
										}
										fullWidth
										variant="standard"
									/>
								</FlexBox>
							</Box>
							{/* Recipient Agent */}
							<Box mb={4}>
								<Typography
									mb={1}
									sx={{ fontWeight: 600 }}
									textTransform={"capitalize"}
								>
									Recipient Agent:
								</Typography>
								<FlexBox>
									<Typography>Name:</Typography>
									<TextField
										value={contractDetails.recipientAgent.name}
										onChange={(e) =>
											dispatchContract({
												type: "RA_NAME",
												payload: e.target.value,
											})
										}
										fullWidth
										variant="standard"
									/>
								</FlexBox>
								<FlexBox>
									<Typography>Email:</Typography>
									<TextField
										value={contractDetails.recipientAgent.email}
										onChange={(e) =>
											dispatchContract({
												type: "RA_EMAIL",
												payload: e.target.value,
											})
										}
										fullWidth
										type={"email"}
										variant="standard"
									/>
								</FlexBox>
								<FlexBox>
									<Typography>Address:</Typography>
									<TextField
										value={contractDetails.recipientAgent.address}
										onChange={(e) =>
											dispatchContract({
												type: "RA_ADDRESS",
												payload: e.target.value,
											})
										}
										fullWidth
										variant="standard"
									/>
								</FlexBox>
								<FlexBox>
									<Typography>Phone:</Typography>
									<TextField
										value={formatPhoneNumber(
											contractDetails.recipientAgent.phone
										)}
										onChange={(e) =>
											dispatchContract({
												type: "RA_PHONE",
												payload: e.target.value,
											})
										}
										fullWidth
										variant="standard"
									/>
								</FlexBox>
							</Box>
							{/* Recipient Broker */}
							<Box mb={4}>
								<Typography
									mb={1}
									sx={{ fontWeight: 600 }}
									textTransform={"capitalize"}
								>
									Recipient Broker:
								</Typography>
								<FlexBox>
									<Typography>Name:</Typography>
									<TextField
										value={contractDetails.recipientBroker.name}
										onChange={(e) =>
											dispatchContract({
												type: "RB_NAME",
												payload: e.target.value,
											})
										}
										fullWidth
										variant="standard"
									/>
								</FlexBox>
								<FlexBox>
									<Typography>Email:</Typography>
									<TextField
										value={contractDetails.recipientBroker.email}
										onChange={(e) =>
											dispatchContract({
												type: "RB_EMAIL",
												payload: e.target.value,
											})
										}
										fullWidth
										type={"email"}
										variant="standard"
									/>
								</FlexBox>
								<FlexBox>
									<Typography>Address:</Typography>
									<TextField
										value={contractDetails.recipientBroker.address}
										onChange={(e) =>
											dispatchContract({
												type: "RB_ADDRESS",
												payload: e.target.value,
											})
										}
										fullWidth
										variant="standard"
									/>
								</FlexBox>
								<FlexBox>
									<Typography>Phone:</Typography>
									<TextField
										value={formatPhoneNumber(
											contractDetails.recipientBroker.phone
										)}
										onChange={(e) =>
											dispatchContract({
												type: "RB_PHONE",
												payload: e.target.value,
											})
										}
										fullWidth
										variant="standard"
									/>
								</FlexBox>
							</Box>
							{/* Principal */}
							<Box mb={4}>
								<Typography
									mb={1}
									sx={{ fontWeight: 600 }}
									textTransform={"capitalize"}
								>
									Principal (Client):
								</Typography>
								<FlexBox>
									<Typography>Name:</Typography>
									<TextField
										value={contractDetails.clientName}
										fullWidth
										variant="standard"
									/>
								</FlexBox>
							</Box>

							<Stack>
								<Typography
									sx={{ fontWeight: 600 }}
									textTransform={"capitalize"}
								>
									Agreement:
								</Typography>
								<Typography textAlign={"justify"}>
									In consideration for receipt of the referral of Principal from
									Referring Broker, Recipient Broker agrees to pay Referring
									Broker as follows: 10% of the total gross compensation earned
									by Recipient Broker (based only upon the Principal's side of
									the transaction) or 15% if of the total gross compensation
									upon recipient broker and or recipient Agent acting as Dual
									Agent, as follows: (i) the event specified below occurs no
									later than 24 full months from the date of this agreement; and
									(ii) compensation is payable upon recordation of deed or other
									evidence of transfer resulting from the event specified below
									(whether closing occurs during or after the expiration time.
								</Typography>
							</Stack>
							<Stack sx={{ my: "1rem" }}>
								<Typography>Principal Enters, into Contract to Buy</Typography>
								<Typography>Principal Enters, into Contract to Sell</Typography>
							</Stack>

							<Stack sx={{ mt: "1rem" }}>
								<Typography
									sx={{ fontWeight: 600 }}
									textTransform={"capitalize"}
								>
									Additional:
								</Typography>
								<Typography variant="body2" paragraph textAlign={"justify"}>
									Partner Premier Agent Referral Fee Addendum No.1 attached is
									hereby incorporated as part of this agreement By signing this
									agreement Recipient Agent indicates Recipient Agent has full
									authority to sign this agreement on behalf of Recipient Broker
									By signing this agreement Recipient Agent understands a
									referral compensation will be payable directly to Recipient
									Broker not Recipient Agent
								</Typography>
								<Typography variant="body2" paragraph textAlign={"justify"}>
									California real estate law strictly prohibits (a) a broker
									from paying compensation for licensed activity to anyone other
									than (i) a broker, (ii) a salesperson who is licensed under
									the compensating broker or (iii) a broker of another State and
									(b) a salesperson from paying compensation to another licensee
									for licensed activity, except through the employing broker.
									Federal law prohibits giving or accepting any fee or other
									thing of value for a referral involving a federally related
									mortgage loan (most residential one to four property
									transactions) unless pursuant to a cooperative brokerage
									arrangement.
								</Typography>

								<Typography variant="body2" paragraph textAlign={"justify"}>
									© 2022, BPO Homes, Inc. United States copyright law (Title 17
									U.S. Code) forbids the unauthorized distribution, display and
									reproduction of this form, or any portion thereof, by
									photocopy machine or any other means, including facsimile or
									computerized formats. THIS FORM HAS BEEN APPROVED BY BPO Homes
									Inc. NO REPRESENTATION IS MADE AS TO THE LEGAL VALIDITY OR
									ACCURACY OF ANY PROVISION IN ANY SPECIFIC TRANSACTION. A REAL
									ESTATE BROKER IS A PERSON ONLY QUALIFIED TO ADVISE ON REAL
									ESTATE TRANSACTIONS. IF YOU NEED ANY LEGAL OR TAX
									ADVICE,CONSULT AN APPROPRIATE PROFESSIONAL. This form is made
									available to real estate professionals through an agreement
									with BPO Homes Inc & BPO Homes Partner Premier Agents. It is
									not intended to identify the user as a REALTOR®. REALTOR® is a
									registered collective membership mark which may be used only
									by members of the NATIONAL ASSOCIATION OF REALTORS® who
									subscribe to its Code of Ethics.
								</Typography>
							</Stack>

							{/* signatures */}
							<Grid container spacing={"3rem"} sx={{ mt: "2rem" }}>
								<Grid item sm={6}>
									<FlexBox>
										<Typography
											sx={{ fontWeight: 600, width: "15rem !important" }}
										>
											Referring Broker:
										</Typography>
										<TextField
											value={contractDetails.referrerBroker.signature}
											disabled
											fullWidth
											variant="standard"
										/>
									</FlexBox>
									<FlexBox>
										<Typography
											sx={{ fontWeight: 600, width: "15rem !important" }}
										>
											Date:
										</Typography>
										<TextField
											value={contractDetails.referrerBroker.signDate}
											disabled
											fullWidth
											variant="standard"
										/>
									</FlexBox>
								</Grid>
								<Grid item sm={6}>
									<FlexBox>
										<Typography
											sx={{ fontWeight: 600, width: "15rem !important" }}
										>
											Recipient Agent:
										</Typography>
										{contractDetails.recipientAgent.signature ? (
											<Box
												mt={-2}
												sx={{
													borderBottom: "1px solid #000",
													cursor: "pointer",
												}}
												onClick={() => setRecSign(true)}
											>
												<img
													width="62%"
													src={contractDetails.recipientAgent.signature}
													alt="referrer_sign"
												/>
											</Box>
										) : (
											<Box
												sx={{
													height: "3.5rem",
													cursor: "pointer",
													width: "100%",
													border: "1px dashed lightgrey",
													borderRadius: "10px",
												}}
												onClick={() => setRecSign(true)}
											/>
										)}
									</FlexBox>
									<FlexBox>
										<Typography
											sx={{ fontWeight: 600, width: "15rem !important" }}
										>
											Date:
										</Typography>
										<TextField
											value={contractDetails.recipientAgent.signDate}
											disabled
											fullWidth
											variant="standard"
										/>
									</FlexBox>
								</Grid>
							</Grid>
						</Box>
					</Container>
				</DialogContent>
			</Dialog>

			{/* Loader */}
			<Dialog open={response.open}>
				<DialogContent sx={{ padding: "3rem", textAlign: "center" }}>
					{response.status === "success" ? (
						<CheckCircle color="primary" fontSize="large" />
					) : (
						<ErrorOutline sx={{ color: "red" }} fontSize="large" />
					)}
					<IconButton
						onClick={onDialogClose}
						sx={{ position: "absolute", top: "5%", right: "3%" }}
					>
						<Close fontSize="small" />
					</IconButton>
					<Typography
						sx={{ fontSize: "1.3rem", fontWeight: 600 }}
						variant="h6"
						mb={2}
					>
						{response.status === "success" ? "Thank You" : "Error"}
					</Typography>
					<Typography variant="body2">
						{response.status === "success"
							? "Congratulations!! This lead has been successfully signed and received. Thank you for choosing BPO Homes"
							: "Sorry, something went wrong. Please try again"}
					</Typography>
				</DialogContent>
			</Dialog>

			{/* Show call */}
			<Dialog open={show}>
				<DialogContent sx={{ position: "relative" }}>
					<IconButton
						onClick={() => setShow(false)}
						sx={{ position: "absolute", right: "5%" }}
					>
						<Close fontSize="small" />
					</IconButton>
					<Box sx={{ padding: "2rem 0", width: "20rem" }}>
						<Typography variant="h6" textAlign={"center"}>
							Call{" "}
							{viewData?.first_name
								? viewData?.first_name
								: viewData?.client_register
								? viewData?.client_register?.user?.first_name
								: _.startCase(
										_.lowerCase(viewData?.borrower_name_owner?.split(" ")[0])
								  )}
						</Typography>
						<Button
							variant="text"
							color="secondary"
							sx={{ display: "flex", margin: "0 auto" }}
							startIcon={<Call color="secondary" fontSize="small" />}
						>
							{viewData
								? viewData.phone
								: viewData?.client_register
								? viewData?.client_register?.phone
								: null}
						</Button>
						<Divider sx={{ margin: "8px 0" }} />
						<Typography mb={3} textAlign={"center"} sx={{ fontSize: ".9rem" }}>
							Update your progress
						</Typography>
						<Grid container spacing={0}>
							<Grid item sm={4} sx={{ textAlign: "center" }}>
								<CallBtn
									onClick={() => createActivity("Called, but no response")}
									sx={{ bgcolor: "red" }}
								>
									<PhoneMissed color="paper" />
								</CallBtn>
								<Typography
									textAlign={"center"}
									color="textSecondary"
									sx={{ fontSize: "1rem" }}
								>
									No <br /> answer
								</Typography>
							</Grid>
							<Grid item sm={4} sx={{ textAlign: "center" }}>
								<CallBtn
									onClick={() => createActivity("Called and left a voicemail")}
									sx={{ bgcolor: grey[200] }}
								>
									<Voicemail />
								</CallBtn>
								<Typography
									textAlign={"center"}
									color="textSecondary"
									sx={{ fontSize: "1rem" }}
								>
									Left <br /> voicemail
								</Typography>
							</Grid>
							<Grid item sm={4} sx={{ textAlign: "center" }}>
								<CallBtn
									onClick={() => createActivity("Called and talked")}
									sx={{ bgcolor: "#4caf50" }}
								>
									<PhoneInTalk color="paper" />
								</CallBtn>
								<Typography
									textAlign={"center"}
									color="textSecondary"
									sx={{ fontSize: "1rem" }}
								>
									We <br /> talked
								</Typography>
							</Grid>
						</Grid>
					</Box>
				</DialogContent>
			</Dialog>

			{/* Sign Referrer */}
			<Dialog open={refSign} fullWidth>
				<DialogContent dividers sx={{ position: "relative" }}>
					<IconButton
						onClick={() => setRefSign(false)}
						sx={{ position: "absolute", top: "5%", right: "5%" }}
					>
						<Close fontSize="small" />
					</IconButton>
					<Box sx={{ padding: "2rem" }}>
						<Typography mb={3} textAlign={"center"}>
							Referrer Broker Signature
						</Typography>
						<SignatureCanvas
							ref={(ref) => {
								signPadReferrer = ref;
							}}
							penColor="#000"
							canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
						/>
					</Box>
				</DialogContent>
				<DialogActions sx={{ padding: "1rem 2rem" }}>
					<Button
						disableElevation
						sx={{ textTransform: "none" }}
						onClick={clearPadReferrer}
						variant="outlined"
						color="secondary"
					>
						Clear
					</Button>
					<Button
						disableElevation
						sx={{ textTransform: "none" }}
						onClick={signReferrer}
						variant="contained"
						color="secondary"
					>
						Continue
					</Button>
				</DialogActions>
			</Dialog>

			{/* Sign Recipient Contract */}
			<Dialog open={recSign} fullWidth>
				<DialogContent dividers sx={{ position: "relative" }}>
					<IconButton
						onClick={() => setRecSign(false)}
						sx={{ position: "absolute", top: "5%", right: "5%" }}
					>
						<Close fontSize="small" />
					</IconButton>
					<Box sx={{ padding: "2rem" }}>
						<Typography mb={3} textAlign={"center"}>
							Recipient Agent Signature
						</Typography>
						<SignatureCanvas
							ref={(ref) => {
								signPadRecipient = ref;
							}}
							penColor="#000"
							canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
						/>
					</Box>
				</DialogContent>
				<DialogActions sx={{ padding: "1rem 2rem" }}>
					<Button
						disableElevation
						sx={{ textTransform: "none" }}
						onClick={clearPadRecipient}
						variant="outlined"
						color="secondary"
					>
						Clear
					</Button>
					<Button
						disableElevation
						sx={{ textTransform: "none" }}
						onClick={signRecipient}
						variant="contained"
						color="secondary"
					>
						Continue
					</Button>
				</DialogActions>
			</Dialog>

			{/* Schedule another call */}
			<Dialog open={schedule}>
				<DialogContent sx={{ position: "relative", padding: "2rem" }}>
					<IconButton
						onClick={() => setSchedule(false)}
						sx={{ position: "absolute", right: "5%", top: "3%" }}
					>
						<Close fontSize="small" />
					</IconButton>
					<Typography variant="h6" textAlign={"center"}>
						Set a Reminder
					</Typography>
					<Typography sx={{ fontSize: ".9rem" }} textAlign={"center"} mb={3}>
						Get an email reminder to call the customer back
					</Typography>
					<ButtonGroup
						variant="outlined"
						color="secondary"
						sx={{ display: "block", margin: "0 auto", textTransform: "none" }}
					>
						<Button variant="outlined" sx={{ textTransform: "none" }}>
							1 Hr
						</Button>
						<Button variant="outlined" sx={{ textTransform: "none" }}>
							2 Hrs
						</Button>
						<Button variant="outlined" sx={{ textTransform: "none" }}>
							4 Hrs
						</Button>
						<Button variant="outlined" sx={{ textTransform: "none" }}>
							Tomorrow
						</Button>
					</ButtonGroup>
					<TextField
						size="small"
						sx={{ margin: "1rem 0" }}
						variant="outlined"
						fullWidth
						type={"date"}
					/>
					<Select
						defaultValue={""}
						size="small"
						sx={{ margin: "0 0 1rem 0" }}
						displayEmpty
						variant="outlined"
						fullWidth
					>
						{timeData?.map((el, index) => {
							return (
								<MenuItem value={el} key={index}>
									{el === "" ? "Select" : el}
								</MenuItem>
							);
						})}
					</Select>
					<Button
						variant="contained"
						disableElevation
						color="secondary"
						sx={{ textTransform: "none", display: "block", margin: "0 auto" }}
					>
						Schedule Reminder
					</Button>
				</DialogContent>
			</Dialog>

			{/* Loading Dialog */}
			<Dialog open={open2}>
				<DialogContent
					sx={{ padding: "3rem", width: "20rem", textAlign: "center" }}
				>
					<CircularProgress value={30} color="primary" />
					<Typography mt={2}>Loading...</Typography>
				</DialogContent>
			</Dialog>

			{/* Popover */}
			<Popover
				open={openCalendar}
				anchorEl={anchorEl}
				onClose={() => setAnchorEl(null)}
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
				transformOrigin={{ vertical: "top", horizontal: "right" }}
			>
				<Card>
					<CardContent sx={{ display: "flex", gap: "1rem", p: 1 }}>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<StaticDatePicker
								sx={{
									".MuiDialogActions-root": { display: "none" },
									".MuiPickersToolbar-root .MuiDatePickerToolbar-root": {
										display: "none",
									},
								}}
								value={dayjs(dateRange?.start)}
								// disableFuture
								onChange={(dt) => {
									setDateRange((prev) => ({
										...prev,
										start: getISODate(dt?.$d),
									}));
								}}
							/>
						</LocalizationProvider>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<StaticDatePicker
								sx={{ ".MuiDialogActions-root": { display: "none" } }}
								// disableFuture
								value={dayjs(dateRange?.end)}
								onChange={(dt) =>
									setDateRange((prev) => ({ ...prev, end: getISODate(dt?.$d) }))
								}
							/>
						</LocalizationProvider>
					</CardContent>
				</Card>
			</Popover>
		</div>
	);
};

export default LiveLeads;

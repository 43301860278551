import React, { useState } from "react";
import {
  Box,
  FormControl,
  OutlinedInput,
  Typography,
  InputAdornment,
  Modal,
  Button,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import StyledLabel from "../../atomicComponents/StyledLabel";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CompAverage from "../../atomicComponents/CompAverage";
import CompsMainCard from "./comps/CompsMainCard";
import useProformaData from "../../hooks/dealReporthooks/useProformaData";
import HtmlToolTip from "../../atomicComponents/HtmlToolTip";
import CapCalculator from "../../atomicComponents/CapCalculator";
import { greyout } from "../../utils/greyout";
import commaSeparate from "../../utils/commaSeparate";
import EnhancedRadioButtonGroup from "../../atomicComponents/EnhancedRadioButtonGroup";
import { updateEstimatedResaleValue } from "../../../../actions/dealAnalyzer";
import { useDispatch } from "react-redux";
import useFetchComps from "../../hooks/useFetchComps";
import {
  appreciatedResaleAmountCalc,
  presentPurchasePriceFuc,
} from "../../localCalcutions/estResaleValue/estResaleValue";
import { useMemo } from "react";
import * as actionCreators from "../../../../actions/dealAnalyzer/index";
import { useEffect } from "react";
import useZillowComps from "./zillowComps/useZillowComps";
import { preventMinus } from "../../utils/preventNegativeValues";
import {
  isNaNChecker,
  isNaNCheckerRounded,
  yearsToMonthsTransformer,
} from "../../localCalcutions/utils/genericFunctions";
import { multiplyArrays } from "../../utils/GenerateInputField";
import useSaveNow from "../../hooks/useSaveNow";

const useStyles = (theme) => {
  return {
    root: {
      margin: "1.25rem",
    },
    row1: {
      display: "flex",
      justifyContent: "space-between",
    },
    inputContainer: {
      width: "47%",
    },
    comps: {
      margin: "1.6rem 0",
    },
    compTypo: {
      cursor: "pointer",
      fontWeight: "700",
    },
    compsAverages: {
      margin: "1.875rem 0",
      display: "flex",
      alignItems: "center",
    },
    appreciation: {
      display: "flex",
      flexDirection: "column",
    },
    modal: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "80%",
      minWidth: "80rem",
      bgcolor: "background.paper",
      boxShadow: 24,
      p: 4,
      "&:focus": {
        outline: 0,
      },
    },
    typography: {
      "&:hover": {
        textDecoration: "underline",
      },
      cursor: "pointer",
    },
    capRate: {
      display: "flex",
      alignItems: "center",
      color: theme.palette.primary.main,
      margin: "0rem 0",
      padding: "5px 0",
    },
  };
};

function EstimatedResaleValue({ handleEvents }) {
  const theme = useTheme();
  const { saveNow } = useSaveNow();
  const styles = useStyles(theme);
  const [showCompsAverages, setShowCompsAverages] = useState(false);
  const [open, setOpen] = React.useState(false);

  const [initialLoad, setInitialLoad] = React.useState(true);
  const analyzerData = useSelector((state) => state.straightAestheticRemodel);
  const propertyData = analyzerData?.propertyInformation;
  const resaleValueChoice =
    +analyzerData?.estimatedResaleValue?.resale_value_option - 1 || 0;
  const appreciationValueChoice =
    +analyzerData?.estimatedResaleValue?.appreciation_value_option - 1 || 0;
  const appreciationValueChoiceDesired =
    +analyzerData?.estimatedResaleValue?.appreciation_value_option_desired -
      1 || 0;

  const estimatedResaleFields = analyzerData?.estimatedResaleValue;

  const { sendResalevalueOption } = useFetchComps();
  const dispatch = useDispatch();
  const compSearchData = {
    beds: propertyData?.bedrooms,
    bath: propertyData?.bathrooms,
    sqft: propertyData?.pre_existing_livable_sqft,
    address: propertyData?.property_address,
  };
  const ownerData = {
    ...compSearchData,
    year_built: new Date(propertyData?.year_of_construction).getFullYear(),
    requestId: analyzerData.dealAnalyzerReportId?.id,
    lot_size: propertyData?.lot_size,
    sale_date: "N/A",
  };
  const { resultData, fetchResult } = useProformaData({ noAutoLoad: true });
  const estimates = resultData?.est_resale_value;
  const zillowEstimate =
    analyzerData?.estimatedResaleValue?.calculatedAverageZillowComps;
  const { replaceAllCustomComps } = useZillowComps();

  const savedZillowCompsFromServer =
    analyzerData?.dealAnalyzerResults?.zillowComps;
  // const savedZillowCompsIds = savedZillowCompsFromServer && savedZillowCompsFromServer.map(item => item.id);

  const [userAssertAppreciation, setUserAssertAppreciation] = useState(
    analyzerData?.estimatedResaleValue?.annual_property_appreciation
  );

  const handleClickOpen = async () => {
    await replaceAllCustomComps(savedZillowCompsFromServer);

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    fetchResult();
  };

  const handleShowComps = () => {
    setShowCompsAverages((prev) => !prev);
  };
  const [showCapRate, setShowCapRate] = useState(false);

  const toggleCapRateVisibility = () => {
    setShowCapRate((prev) => !prev);
  };
  const handleDesiredResaleValueChange = (e) => {
    let value = e.target.value;

    if (value) {
      value = Number.parseInt(value.split(",").join(""));
      if (isNaN(+value)) {
        return;
      }
      dispatch(updateEstimatedResaleValue({ desired_resale_value: value }));
    } else {
      dispatch(updateEstimatedResaleValue({ desired_resale_value: value }));
    }
  };

  useEffect(() => {
    const save = async () => {
      saveNow({ est_resale_value: estimatedResaleFields });
      fetchResult();
    };
    save();
    // NOTE: Run effect once on component mount, please
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estimatedResaleFields]);

  React.useEffect(() => {
    if (!initialLoad) {
      sendResalevalueOption(
        analyzerData.estimatedResaleValue?.resale_value_option,
        analyzerData.dealAnalyzerReportId?.id,
        analyzerData.dealAnalyzerReportId?.analyzerTypeId,
        analyzerData?.estimatedResaleValue?.desired_resale_value
      );
    }
    if (initialLoad) {
      setInitialLoad(false);
    }

    // NOTE: Run effect once on component mount, please
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    resaleValueChoice,
    analyzerData?.estimatedResaleValue?.desired_resale_value,
  ]);
  const handleResaleValueOption = (value) => {
    dispatch(updateEstimatedResaleValue({ resale_value_option: +value + 1 }));
  };
  const handleAppreciationOptions = (value) => {
    dispatch(
      updateEstimatedResaleValue({ appreciation_value_option: +value + 1 })
    );
  };
  const handleAppreciationOptionsDesired = (value) => {
    dispatch(
      updateEstimatedResaleValue({
        appreciation_value_option_desired: +value + 1,
      })
    );
  };

  //=============================== Estimated Resale Cost =====================================//

  const remodelCostComp = analyzerData?.remodelCosts || 0;
  const propertyInfo = analyzerData?.propertyInformation || 0;
  const remodelCost =
    analyzerData.remodelCosts.grandTotalRemodelCost ||
    analyzerData.remodelCosts.remodelCost ||
    0;
  const generalPurchasePrice =
    analyzerData.financeOptions.primary_purchase_price || 0;

  const dealtype = +analyzerData.financeOptions?.deal_finance_option;

  const cashForHomePurchasePrice =
    analyzerData.financeOptions?.cash_for_home_purchase_price;

  const purchasePrice =
    dealtype === 3 ? cashForHomePurchasePrice : generalPurchasePrice;

  const currentPrice = useMemo(() => {
    return presentPurchasePriceFuc(purchasePrice, remodelCost);

    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remodelCost, purchasePrice]);

  const assertAppreciationPercentVal = +userAssertAppreciation;
  //custom comps checker
  const numberOfZillowComps =
    analyzerData?.estimatedResaleValue?.zillowComps?.length;
  const numberOfComps =
    analyzerData?.dealAnalyzerResults?.est_resale_value
      ?.number_of_comparables || numberOfZillowComps;

  const compsToApply = +analyzerData?.estimatedResaleValue?.compsToApply;
  useEffect(() => {
    if (!assertAppreciationPercentVal) return;
    dispatch(
      actionCreators.updateEstimatedResaleValue({
        annual_property_appreciation: assertAppreciationPercentVal,
      })
    );
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assertAppreciationPercentVal]);

  const {
    carrying_cost_owning_months,
    carrying_cost_owning_months_month_or_year,
  } = analyzerData?.carryingMonths;

  const months = +yearsToMonthsTransformer(
    carrying_cost_owning_months_month_or_year,
    carrying_cost_owning_months
  );

  const appreciationCalculator = useMemo(() => {
    const val = appreciatedResaleAmountCalc(
      currentPrice,
      assertAppreciationPercentVal,
      months
    );

    return val;
  }, [currentPrice, assertAppreciationPercentVal, months]);

  const estimatedResaleValue = analyzerData?.estimatedResaleValue;
  const resaleValueOption = estimatedResaleValue?.resale_value_option;
  // const compsResale = estimatedResaleValue?.estimatedResaleAmount;

  //dynamic  resale value to speed up profit calcs
  const averagePurchasePrice = zillowEstimate?.averageTotalPrice;

  //applies either zillow or custom comps
  const customOrZillowComps =
    compsToApply === 1
      ? averagePurchasePrice
      : estimates?.average_purchase_price; //checks if number of if number of comps is zero, before using the estimated comps

  // =============== appreciation alone =====================//

  const appreciationAmount = isNaNChecker(
    appreciationCalculator?.appreciatedAmount
  );

  const showAddAppreciation =
    resaleValueOption === 2
      ? true
      : numberOfComps && numberOfComps > 0
      ? true
      : false;

  const optionalAddtion =
    appreciationValueChoice === 1 ? appreciationAmount : 0;
  const optionalAddtionDesired =
    appreciationValueChoiceDesired === 1 ? appreciationAmount : 0;

  const estimatedResaleAmount =
    resaleValueOption === 2
      ? estimatedResaleFields?.desired_resale_value
      : numberOfComps && numberOfComps > 0
      ? +customOrZillowComps + optionalAddtion
      : isNaNChecker(appreciationCalculator?.estimatedHomePrice);

  //dispatches the investors profit
  useEffect(() => {
    dispatch(
      actionCreators.updateEstimatedResaleValue({
        estimatedResaleAmount: estimatedResaleAmount,
      })
    );
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estimatedResaleAmount]);

  //==========xxxxxx===================== Estimated Resale Cost ===========================xxxxxxxx==========//

  const unitCostResult = useMemo(() => {
    return multiplyArrays(
      propertyInfo?.additional_units,
      remodelCostComp?.additional_units_price
    );
  }, [propertyInfo.additional_units, remodelCostComp?.additional_units_price]);
  // basement

  return (
    <Box sx={styles.root}>
      <Box sx={styles.row1}>
        <Box sx={styles.inputContainer}>
          <Box sx={{ marginBottom: "10px" }}>
            <StyledLabel label={estimatedValueStaticData.input1.label} />
          </Box>
          <FormControl sx={{ width: "100%" }}>
            <OutlinedInput
              fullWidth
              sx={{ ...greyout }}
              name="total_sqft_after_remodel"
              // onChange={handleChange}
              placeholder="0"
              margin="normal"
              inputProps={{ style: { fontSize: "1rem" } }}
              value={
                (analyzerData?.remodelCosts?.grandTotalRemodelCost &&
                  commaSeparate(
                    analyzerData?.remodelCosts?.grandTotalRemodelCost
                  )) ||
                resultData?.est_resale_value?.total_sqft_after_remodel +
                  (+unitCostResult?.totalSum || 0) +
                  (+propertyInfo?.adu || 0)
              }
              endAdornment={
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="adornment" color="secondary">
                    {" "}
                    SqF
                  </Typography>
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>
        <Box sx={styles.inputContainer}>
          <Box sx={{ marginBottom: "10px" }}>
            <StyledLabel label={estimatedValueStaticData.input2.label} />
          </Box>
          <FormControl sx={{ width: "100%", mb: 1 }}>
            <OutlinedInput
              fullWidth
              name="annual_property_appreciation"
              value={assertAppreciationPercentVal}
              // sx={{ ...greyout }}
              onChange={(e) => setUserAssertAppreciation(e.target.value)}
              placeholder="0"
              margin="normal"
              type="number"
              onKeyPress={preventMinus}
              inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
              endAdornment={
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="adornment" color="secondary">
                    {" "}
                    %
                  </Typography>
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>
      </Box>
      <Box sx={{ width: "100%", mt: 2 }}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 600,
          }}
        >
          Resale Value Option
        </Typography>
        <EnhancedRadioButtonGroup
          options={radioOptions}
          radioOverride={{ display: "flex" }}
          styles={{ display: "flex", width: "100%" }}
          handler={handleResaleValueOption}
          choice={resaleValueChoice}
        />
      </Box>

      {!!+resaleValueChoice && (
        <Box sx={{ my: 3 }}>
          <Box
            sx={{
              marginBottom: "10px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <StyledLabel label={"Desired Resale Value"} />
          </Box>
          <FormControl sx={{ width: "100%" }}>
            <OutlinedInput
              fullWidth
              onChange={handleDesiredResaleValueChange}
              value={commaSeparate(
                analyzerData?.estimatedResaleValue?.desired_resale_value
              )}
              placeholder="0"
              type="string"
              margin="normal"
              inputProps={{ style: { fontSize: "1rem" } }}
              startAdornment={
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="adornment" color="secondary">
                    {" "}
                    $
                  </Typography>
                </InputAdornment>
              }
            />
          </FormControl>
          <Box>
            {
              <>
                <Box
                  sx={{
                    marginBottom: "10px",
                    display: "flex",
                    flexDirection: "column",
                    mt: "2rem",
                  }}
                >
                  <StyledLabel
                    label={"Add Property Appreciation to Desired Resale Value"}
                  />
                </Box>
                <EnhancedRadioButtonGroup
                  options={radioOptionsForAppreciation}
                  radioOverride={{ display: "flex" }}
                  styles={{ display: "flex", width: "100%" }}
                  handler={handleAppreciationOptionsDesired}
                  choice={appreciationValueChoiceDesired}
                />
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  gap={"10%"}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <StyledLabel label={"Property Appreciation Amount"} />
                    <OutlinedInput
                      fullWidth
                      sx={{ ...greyout }}
                      name="asset_appreciated_amount"
                      // onChange={handleChange}
                      placeholder="0"
                      margin="normal"
                      inputProps={{ style: { fontSize: "1rem" } }}
                      value={commaSeparate(appreciationAmount?.toFixed(2))}
                      endAdornment={
                        <InputAdornment position="start">
                          {" "}
                          <Typography variant="adornment" color="secondary">
                            {" "}
                            $
                          </Typography>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  {appreciationValueChoiceDesired === 1 && (
                    <FormControl sx={{ width: "100%" }}>
                      <StyledLabel
                        label={"Appreciated Property Resale Value"}
                      />
                      <OutlinedInput
                        fullWidth
                        sx={{ ...greyout }}
                        name="asset_appreciated_amount"
                        // onChange={handleChange}
                        placeholder="0"
                        margin="normal"
                        inputProps={{ style: { fontSize: "1rem" } }}
                        value={commaSeparate(
                          (
                            isNaNChecker(
                              estimatedResaleFields?.desired_resale_value
                            ) + optionalAddtionDesired
                          )?.toFixed(2)
                        )}
                        endAdornment={
                          <InputAdornment position="start">
                            {" "}
                            <Typography variant="adornment" color="secondary">
                              {" "}
                              $
                            </Typography>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  )}
                </Box>
              </>
            }
          </Box>
        </Box>
      )}
      {!+resaleValueChoice && (
        <>
          <Box sx={styles.comps}>
            <Button
              sx={{ textTransform: "none", padding: "2% 10%" }}
              variant="outlined"
              onClick={handleClickOpen}
            >
              <Typography variant="h4" color="primary" sx={styles.compTypo}>
                Get Comps
              </Typography>
            </Button>
          </Box>
          <Box>
            {showAddAppreciation && (
              <Box
                sx={{
                  marginBottom: "10px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <FormControl sx={{ width: "100%" }}>
                  <StyledLabel label={"Add Property Appreciation to Comps"} />
                  <EnhancedRadioButtonGroup
                    options={radioOptionsForAppreciation}
                    radioOverride={{ display: "flex" }}
                    styles={{ display: "flex", width: "100%" }}
                    handler={handleAppreciationOptions}
                    choice={appreciationValueChoice}
                  />
                </FormControl>

                <FormControl sx={{ width: "100%" }}>
                  <StyledLabel label={"Property Appreciation Amount"} />
                  <OutlinedInput
                    fullWidth
                    sx={{ ...greyout }}
                    name="asset_appreciated_amount"
                    // onChange={handleChange}
                    placeholder="0"
                    margin="normal"
                    inputProps={{ style: { fontSize: "1rem" } }}
                    value={commaSeparate(appreciationAmount?.toFixed(2))}
                    endAdornment={
                      <InputAdornment position="start">
                        {" "}
                        <Typography variant="adornment" color="secondary">
                          {" "}
                          $
                        </Typography>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Box>
            )}
          </Box>
          <Box>
            <Box
              sx={{
                marginBottom: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <StyledLabel label={estimatedValueStaticData.input3.label} />
              <Typography variant="h5" sx={{ marginTop: "-5px" }}>
                {estimatedValueStaticData.input3.subLabel}
              </Typography>
            </Box>
            <FormControl sx={{ width: "100%" }}>
              <OutlinedInput
                fullWidth
                name="est_resale_value"
                // onChange={handleChange}
                sx={{ ...greyout }}
                value={commaSeparate(
                  isNaNCheckerRounded(estimatedResaleAmount)
                )}
                placeholder="0"
                margin="normal"
                inputProps={{ style: { fontSize: "1rem" } }}
                startAdornment={
                  <InputAdornment position="start">
                    {" "}
                    <Typography variant="adornment" color="secondary">
                      {" "}
                      $
                    </Typography>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Box>

          {!showCompsAverages && (
            <Box sx={styles.compsAverages}>
              <Typography
                variant="h4"
                color="primary"
                sx={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  marginRight: "1rem",
                }}
                onClick={handleShowComps}
              >
                Check Comps Averages
              </Typography>

              <KeyboardArrowRightIcon
                color="primary"
                sx={{ fontSize: "1.25rem" }}
              />
            </Box>
          )}
          {showCompsAverages && (
            <Box sx={styles.compsAverages}>
              <Typography
                variant="buttonFont"
                color="primary"
                sx={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  marginRight: "1rem",
                }}
                onClick={handleShowComps}
              >
                Hide Comp's Averages
              </Typography>
              <KeyboardArrowDownIcon
                color="primary"
                sx={{ fontSize: "1.25rem" }}
              />
            </Box>
          )}
          {showCompsAverages && (
            <CompAverage
              estimates={estimates}
              zillowEstimate={zillowEstimate}
            />
          )}
        </>
      )}
      <Box sx={styles.capRate}>
        <Typography
          variant="modalTitle"
          sx={styles.typography}
          onClick={toggleCapRateVisibility}
        >
          Cap Rate
        </Typography>
        <HtmlToolTip
          content={{ text: propertyManagementStaticData.capRateToolTipText }}
        />
      </Box>
      {showCapRate && <CapCalculator resultData={resultData} />}
      <Modal
        open={open}
        onClose={handleClose}
        width="55rem"
        sx={{
          padding: "0",
          "&:focus": {
            outline: 0,
          },
        }}
        fullwidth={true}
      >
        <Box sx={styles.modal}>
          <CompsMainCard
            handleMainClose={handleClose}
            propertData={propertyData}
            compSearchData={compSearchData}
            ownerData={ownerData}
            handler={handleEvents}
          />
        </Box>
      </Modal>
    </Box>
  );
}

export default EstimatedResaleValue;

const estimatedValueStaticData = {
  input1: {
    label: "Total SqFt After Remodel",
  },
  input2: {
    label: "Annual Property Appreciation",
    lowerLabel: "See Appreciation Amount in your area",
  },
  input3: {
    label: "Estimated Resale Value",
    subLabel:
      "(Based on Comps selected, below is BPO Homes's suggested estimated value for this home)",
  },
};
const propertyManagementStaticData = {
  withRates: [
    {
      label: "Property Management Fees",
      toolTipText:
        "Property Management Fees are for a property management company to manage your rental",
      lowerLabel:
        "Typically, Property management fees are 7%-10% of the total rental income per year.",
      name: "property_management_fees",
      rate: "property_management_fees_dollar_or_percent",
    },
    {
      label: "Annual Maintenance",
      toolTipText:
        "The ongoing cost of keeping the property in good condition and to prevent major issues in the future",
      name: "annual_maintenance",
      rate: "annual_maintenance_dollar_or_percent",
    },
  ],
  withoutRates: {
    label: "Appreciation",
  },
  capRateToolTipText:
    "Add items and their estimated dollar value individually ",
};
const radioOptions = ["Estimated Resale Value", "Desired Resale Value"];
const radioOptionsForAppreciation = ["No", "Yes"];

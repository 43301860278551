import { ArrowBack, Close, Description, Download } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Dialog, DialogContent, DialogTitle, Grid, InputBase, Paper, Stack, ThemeProvider, Typography } from '@mui/material';
import { sortBy } from 'lodash';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { theme } from '../theme';
import { GradientHolder, libraries } from './TrainingCenter';
import { Document, Page } from 'react-pdf';
import React, { useState } from 'react';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const newTheme = {
	...theme,
	typography: {
		allVariant: {
			fontFamily: 'Lato',
		},
		fontFamily: 'Lato, serif',
		fontSize: 16,
		button: {
			textTransform: 'capitalize',
			padding: '0 10px',
			fontFamily: 'Lato, serif',
		},
	},
};

//valid options- video, zoom, docs
const documents = libraries[2].items;

const TrainingCenterDocs = ({ name = 'Document' }) => {
	const type = useSelector((state) => state.auth.role);
	const docsForYou = documents.filter((item) => (type === 'realty_agent' ? true : item.all));
	const [sortedDocs, setSortedDocs] = useState(docsForYou);
	const [search, setSearch] = useState('');
	const handleSearch = (e) => {
		setSearch(e.target.value);
		setSortedDocs((v) => docsForYou.filter((item) => item.title.toLowerCase().includes(e.target.value.toLowerCase())));
	};
	// const [isDocLoading, setIsDocLoading] = useState(true);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedDoc, setSelectedDoc] = useState(null);
	const handleViewDoc = (doc) => {
		setIsModalOpen(true);
		setSelectedDoc(doc);
	};

	const docsToShow = sortBy(sortedDocs, ['title']);
	const [numPages, setNumPages] = useState();
	const handleDownload = () => {
		const link = document.createElement('a');
		link.href = selectedDoc?.itemLink;
		link.target = '_blank';
		link.download = selectedDoc?.title;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	return (
		<ThemeProvider theme={newTheme}>
			<Dialog fullWidth open={isModalOpen} onClose={() => setIsModalOpen(false)} maxWidth='lg'>
				<DialogTitle>
					<Stack direction='row' justify='space-between'>
						<Typography>{selectedDoc?.title}</Typography>
						<Stack direction='row' sx={{ marginLeft: 'auto' }} gap='1rem'>
							{selectedDoc?.canDownload && <Download onClick={handleDownload} style={{ cursor: 'pointer' }} />}
							<Close fontSize='small' onClick={() => setIsModalOpen(false)} style={{ cursor: 'pointer', marginLeft: 'auto' }} />
						</Stack>
					</Stack>
				</DialogTitle>
				<DialogContent dividers>
					<Stack direction='row' justifyContent={'center'}>
						<Document
							file={selectedDoc?.itemLink}
							onLoadSuccess={({ numPages }) => {
								setNumPages(numPages);
							}}
						>
							{Array.from(new Array(numPages), (el, index) => (
								<React.Fragment key={`page_${index + 1}`}>
									<Page pageNumber={index + 1} />
									{index !== 0 && <hr />}
								</React.Fragment>
							))}
						</Document>
					</Stack>
				</DialogContent>
			</Dialog>
			<Stack gap='1rem' sx={{ backgroundColor: '#ffffff', height: '100%' }}>
				<Stack gap='1rem' sx={{ backgroundColor: '#F9F9FB', padding: '1rem' }}>
					<Stack direction='row' justifyContent={'space-between'}>
						<Stack direction={'row'} alignItems={'center'} gap='1rem'>
							<Button
								variant='text'
								startIcon={<ArrowBack />}
								color='secondary'
								sx={{ fontSize: '18px', fontWeight: 600 }}
								disableElevation
								LinkComponent={Link}
								to='..'
								disableRipple
							>
								Back
							</Button>
							<Typography sx={{ fontSize: 20, fontWeight: 700, fontFamily: 'Lato' }}>{name} Library</Typography>
						</Stack>
						{/* <TextField placeholder={`Search ${name}`} sx={{ width: '600px' }} /> */}
					</Stack>

					{/* <Grid container spacing={2}>
						{docsForYou.slice(0, 3).map((video) => (
							<Grid item xs={4} key={video.link}>
								<MainItem {...video} />
							</Grid>
						))}
					</Grid> */}
				</Stack>
			</Stack>
			<Stack p='1rem' gap='1rem'>
				<Stack justifyContent='flex-end' direction='row'>
					{/* <Typography color='#005EA9' fontWeight={700} fontSize={20} textAlign='left' sx={{ fontStyle: 'italic', fontFamily: 'Lato' }}>
						{name} Library
					</Typography> */}
					<TextField placeholder={'Search by title'} style={{ width: 400 }} onChange={handleSearch} value={search} />
				</Stack>
				{docsToShow?.length > 0 ? (
					<Grid container spacing={2}>
						{docsToShow.map((doc) => (
							<Grid item xs={4} key={doc.link}>
								<LibraryItem {...doc} onClick={() => handleViewDoc(doc)} />
							</Grid>
						))}
					</Grid>
				) : (
					<Stack justifyContent={'center'} alignItems={'center'} minHeight={'500px'}>
						<Typography>No documents found! </Typography>
						<Button
							color='secondary'
							onClick={() => {
								setSearch('');
								setSortedDocs(docsForYou);
							}}
						>
							Clear search
						</Button>
					</Stack>
				)}
			</Stack>
		</ThemeProvider>
	);
};

export default TrainingCenterDocs;

// const MainItem = ({ title, description, author = 'Joban Brown', itemLink }) => {
// 	return (
// 		<Stack gap='11.2px' sx={{ height: '100%' }}>
// 			<GradientHolder>
// 				<Description htmlColor='#FFFFFF4D' fontSize='large' />
// 			</GradientHolder>
// 			<Typography sx={{ fontWeight: 600, fontSize: 16, fontFamily: 'Lato' }}>{title || 'Buyer 1 Presentation'}</Typography>
// 			<Typography sx={{ fontStyle: 'italic', fontSize: '12px', fontWeight: 600, fontFamily: 'Lato' }}>{author}</Typography>
// 			<Typography
// 				sx={{
// 					overflow: 'hidden',
// 					textOverflow: 'ellipsis',
// 					display: '-webkit-box',
// 					WebkitLineClamp: '2',
// 					WebkitBoxOrient: 'vertical',
// 					fontWeight: 400,
// 					fontSize: 14,
// 					color: '#667085',
// 					fontFamily: 'Lato',
// 					textAlign: 'justify',
// 				}}
// 			>
// 				{description}
// 			</Typography>
// 		</Stack>
// 	);
// };

const TextField = ({ placeholder, style, ...rest }) => {
	return (
		<Paper
			component='form'
			sx={{ p: '2px 4px', display: 'flex', borderRadius: '5px', alignItems: 'center', width: 600, boxShadow: '0px 4px 10px 0px #0000001A', ...style }}
		>
			<InputBase sx={{ ml: 1, flex: 1, fontSize: '14px', padding: '5px 10px', fontFamily: 'Lato' }} placeholder={placeholder} {...rest} />
			<SearchIcon />
		</Paper>
	);
};

export const LibraryItem = ({ title, description, icon, onClick }) => {
	return (
		<Stack direction='row' sx={{ height: '100%' }} gap='1rem'>
			{
				<GradientHolder>
					<Description htmlColor='#FFFFFF4D' fontSize='large' />
				</GradientHolder>
			}
			<Stack direction='column' sx={{ flex: 2 }} alignItems='flex-start' gap='1rem'>
				<Typography sx={{ fontWeight: 600, fontSize: 16, fontFamily: 'Lato' }}>{title}</Typography>
				<Typography
					sx={{
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						display: '-webkit-box',
						WebkitLineClamp: '2',
						WebkitBoxOrient: 'vertical',
						fontWeight: 400,
						fontSize: 14,
						color: '#667085',
						fontFamily: 'Lato',
					}}
				>
					{description}
				</Typography>
				<Button
					variant='text'
					color='secondary'
					// startIcon={<PlayCircleOutline fontSize='small' color='secondary' />}
					disableElevation
					disableFocusRipple
					disableRipple
					sx={{ fontWeight: 600, fontSize: 13, fontFamily: 'Inter, Lato, serif' }}
					onClick={onClick}
				>
					View Document
				</Button>
			</Stack>
		</Stack>
	);
};

/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from "react";
import useTheme from "@mui/material/styles/useTheme";
import { useDispatch, useSelector } from "react-redux";
import useProformaData from "../../hooks/dealReporthooks/useProformaData";
import useSaveNow from "../../hooks/useSaveNow";
import StyledLabel from "../../atomicComponents/StyledLabel";
import commaSeparate from "../../utils/commaSeparate";
import * as actionCreators from "../../../../actions/dealAnalyzer/index";
import {
  FormControl,
  OutlinedInput,
  Typography,
  Box,
  InputAdornment,
  Grid,
  Divider,
} from "@mui/material";
import EnhancedRadioButtonGroup from "../../atomicComponents/EnhancedRadioButtonGroup";
// import HtmlToolTip from "../../atomicComponents/HtmlToolTip";
import { greyout } from "../../utils/greyout";
import { remodelCostOptions } from "./constants";
import { getCostPsqftFromAdvancedRemodelCost } from "./remodelCostUtils/getCostPerSqft";
import EditIcon from "@mui/icons-material/Edit";
import RegularToolTip from "../../atomicComponents/RegularToolTip";
import { preventMinus } from "../../utils/preventNegativeValues";
import { generateField, multiplyArrays } from "../../utils/GenerateInputField";
import { isNaNChecker } from "../../localCalcutions/utils/genericFunctions";

const useStyles = (_theme) => {
  return {
    root: {},
    inputContainer: {
      display: "flex",
      flexDirection: "column",
      margin: "10px 0 10px 0",
    },
    underInput: {
      "&:hover": {
        borderBottom: "solid",
      },
      cursor: "pointer",
    },
    bottomLabel: {
      marginTop: "5px",
      display: "flex",
      alignItems: "center",
    },
  };
};

function RemodelMainCardAlt0({
  handleOpenDialog,
  handleSubMenu,
  handler,
  data,
}) {
  const theme = useTheme();
  const styles = useStyles(theme);
  const { saveNow } = useSaveNow();
  const analyzerData = useSelector((state) => state?.straightAestheticRemodel);
  const resultData = analyzerData?.dealAnalyzerResults;
  const { fetchResult } = useProformaData();
  const isAdvanced = +analyzerData?.remodelCosts?.remodel_cost_option;
  const preExistingSquareFootage =
    analyzerData?.propertyInformation?.pre_existing_livable_sqft;
  const additionalSquareFootage =
    analyzerData?.propertyInformation?.additional_sqrft;
  const aestheticItems = analyzerData?.remodelCosts?.aesthetic_items || [];
  const nonAestheticItems =
    analyzerData?.remodelCosts?.non_aesthetic_items || [];
  const additionalItems =
    analyzerData?.remodelCosts?.additional_remodel_items || [];
  const {
    estimatedRemodelCostPsqft,
    estimatedNewConstructionCostPsqft,
    remodelCost,
    newConstructionCost,
    estimatedTotalRemodelWithAdditional,
  } =
    getCostPsqftFromAdvancedRemodelCost({
      preExistingSquareFootage,
      aestheticItems,
      nonAestheticItems,
      additionalItems,
      additionalSquareFootage,
    }) || {};
  let advancedRemodelTimer;
  const handleChange = (e) => {
    handler({ [e.target.name]: e.target.value });
  };

  const specialHandler = (e) => {
    handler({ [e.target.name]: e.target.value });
    if (+e.target.value === 1) {
      advancedRemodelTimer = setTimeout(() => {
        handleSubMenu(1);
      }, 1000);
    }
  };

  const save = async () => {
    try {
      saveNow({ remodel_cost: analyzerData?.remodelCosts });
    } catch (err) {}
  };

  // console.log("mainCardArt0");
  useEffect(() => {
    const saveFetch = async () => {
      await save();
      await fetchResult();
    };
    saveFetch();

    // NOTE: Run effect once on component mount, please
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data?.pre_existing_livable_sqft,
    data?.est_remodel_cost_psqft,
    data?.est_new_construction_cost_psqft,
  ]);

  useEffect(() => {
    !data?.remodel_cost_option && handler({ remodel_cost_option: "0" });
    return () => {
      advancedRemodelTimer && clearTimeout(advancedRemodelTimer);
    };
    // NOTE: Run effect once on component mount, please
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //====================== Local Remodel calculations  ==========================//

  //gets data from the property information
  const preEstLivingSquare =
    analyzerData.propertyInformation.pre_existing_livable_sqft;
  const localAdditionalSquareFootage =
    analyzerData.propertyInformation.additional_sqrft;

  // gets cost amount from redux
  const preEstLivingSquarePrice =
    resultData?.remodel_cost?.est_remodel_cost_psqft;
  // const remodelCostOptionSelected = analyzerData.remodelCosts.remodel_cost_option
  const localAdditionalSquareFootagePrice =
    resultData?.remodel_cost?.est_new_construction_cost_psqft;

  const returnedRemodelCostAmount = isNaN(+data?.["est_remodel_cost_psqft"])
    ? preEstLivingSquarePrice
    : data?.["est_remodel_cost_psqft"];

  const returnlocalAdditionalSquareFootagePrice = isNaN(
    +data?.["est_new_construction_cost_psqft"]
  )
    ? localAdditionalSquareFootagePrice
    : +data?.["est_new_construction_cost_psqft"];

  const localRemodelCostFunc = useMemo(() => {
    const cost = preEstLivingSquare * returnedRemodelCostAmount;
    return cost;

    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preEstLivingSquare, returnedRemodelCostAmount]);

  //calculates additional cost
  const additionalCostFunc = useMemo(() => {
    const additionalCost =
      localAdditionalSquareFootage * returnlocalAdditionalSquareFootagePrice;
    return additionalCost;

    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localAdditionalSquareFootage, returnlocalAdditionalSquareFootagePrice]);

  const dispatch = useDispatch();

  //calculates total cost for remodel and addition
  const totalAmountPaid = useMemo(() => {
    const totalRemodelCost = localRemodelCostFunc + additionalCostFunc;
    dispatch(
      actionCreators.updateFinanceOptions({
        totalRemodelCost: totalRemodelCost,
      })
    );

    return totalRemodelCost;
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localRemodelCostFunc, additionalCostFunc]);

  const additionalCostNaNCheck = isNaN(additionalCostFunc)
    ? 0
    : additionalCostFunc;
  const localRemodelCostNaNCheck = isNaN(localRemodelCostFunc)
    ? 0
    : localRemodelCostFunc;
  const totalAmountPaidNaNCheck = isNaN(totalAmountPaid) ? 0 : totalAmountPaid;

  const returnAdditonalCost =
    +additionalCostNaNCheck.toFixed(2) ||
    resultData?.remodel_cost?.estimated_new_construction_cost;
  const returnedRemodelCost =
    +localRemodelCostNaNCheck.toFixed(2) ||
    resultData?.remodel_cost?.estimated_remodel_cost;

  const remodelCosts = resultData?.remodel_cost;

  //========================xxxxxxxx===============================================xxxxx===========//

  //====================== dynamic forms =============================//
  //========================== dynamic form generation ============================//
  // generate fields for additional sqf added
  const generateNewForm = useMemo(() => {
    return generateField(
      analyzerData?.propertyInformation?.additional_units?.length
    );
  }, [analyzerData?.propertyInformation?.additional_units]);

  const formsWithValues =
    data?.additional_units_price?.length === generateNewForm?.length
      ? data?.additional_units_price
      : undefined;

  // empty prevPrices if unit length changes
  useEffect(() => {
    if (!formsWithValues) {
      handler({ additional_units_price: [] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // generate fields for additional sqf added
  const [additionalUnitArray, setAdditionalUnitArray] = useState(
    formsWithValues || generateNewForm
  );

  const componentDynamicDataFilter = (index, event) => {
    let data = [...additionalUnitArray];

    data[index][event.target.name] = event.target.value;
    setAdditionalUnitArray(data);
    handler({ additional_units_price: data });
    // handleEvents({ [e.target.name]: e.target.value });
  };

  const {
    additional_units,
    pre_existing_livable_sqft,
    est_remodel_cost_psqft,
    adu,
    basement_sqrft,
  } = analyzerData?.propertyInformation || {};

  const unitCostResult = useMemo(() => {
    return multiplyArrays(additional_units, data?.additional_units_price);
  }, [additional_units, data?.additional_units_price]);

  const simpleRemodelTotal = useMemo(() => {
    const res =
      +totalAmountPaidNaNCheck +
      (unitCostResult?.totalSum || 0) +
      (data?.adu_price * adu || 0) +
      (data?.basement_price * basement_sqrft || 0);
    const localTotalRemodelCost = { remodelCost: res };

    dispatch(actionCreators.updateRemodelCosts(localTotalRemodelCost));
    return isNaNChecker(res);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    totalAmountPaidNaNCheck,
    pre_existing_livable_sqft,
    est_remodel_cost_psqft,
    unitCostResult?.totalSum,
    data?.adu_price,
    data?.basement_price,
    adu,
    basement_sqrft,
  ]);

  const totalAmountReturned =
    +simpleRemodelTotal.toFixed(2) ||
    resultData?.remodel_cost?.estimated_total_remodel_cost;

  //Straight Remodel & Addition
  useEffect(() => {
    const grandTotalRemodelCost = isAdvanced
      ? estimatedTotalRemodelWithAdditional
      : totalAmountReturned;

    handler({ grandTotalRemodelCost });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalAmountReturned, estimatedTotalRemodelWithAdditional]);

  return (
    <>
      <StyledLabel label="Choose a Remodel Cost Calculator " />
      <EnhancedRadioButtonGroup
        options={remodelCostOptions}
        name="remodel_cost_option"
        choice={data?.remodel_cost_option}
        specialHandler={specialHandler}
        styles={{ display: "flex" }}
      />
      <Divider
        light={true}
        // variant="middle"
        sx={{
          height: "1px",
          mt: -2,
          mb: 2,
          backgroundColor: "#C4c4c4",
        }}
      />
      <Box>
        <Grid container columns={24}>
          <Grid item sm={24} md={11}>
            <Box sx={styles.inputContainer}>
              <Typography fontSize={18.3}>
                Est. Remodel Cost P/SqFt (Main Unit)
              </Typography>
              <FormControl sx={{ width: "100%" }}>
                <OutlinedInput
                  sx={isAdvanced ? greyout : {}}
                  margin="normal"
                  name={"est_remodel_cost_psqft"}
                  onKeyPress={preventMinus}
                  onChange={isAdvanced ? "" : handleChange}
                  value={
                    isAdvanced
                      ? estimatedRemodelCostPsqft
                      : returnedRemodelCostAmount
                  }
                  placeholder="0"
                  renderValue={(value) => value}
                  inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                  type="number"
                  startAdornment={
                    <InputAdornment position="start">
                      {" "}
                      <Typography variant="adornment" color="secondary">
                        {" "}
                        $
                      </Typography>
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      {" "}
                      <Typography variant="adornment" color="secondary">
                        {" "}
                        /SqFt
                      </Typography>
                    </InputAdornment>
                  }
                />
              </FormControl>
              {!isAdvanced && (
                <Box sx={styles.bottomLabel}>
                  <Typography
                    variant="h6"
                    color="primary"
                    sx={styles.underInput}
                    onClick={() => handleOpenDialog("remodel_cost")}
                  >
                    {remodelMainCardStaticData.input1.lowerLabel}
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item sm={0} md={2} />
          <Grid item sm={24} md={11}>
            <Box sx={styles.inputContainer}>
              <StyledLabel label={"Addition Cost P/Sqft"} />
              <FormControl sx={{ width: "100%" }}>
                <OutlinedInput
                  sx={isAdvanced ? greyout : {}}
                  margin="normal"
                  onKeyPress={preventMinus}
                  name={"est_new_construction_cost_psqft"}
                  onChange={isAdvanced ? "" : handleChange}
                  value={
                    isAdvanced
                      ? estimatedNewConstructionCostPsqft
                      : returnlocalAdditionalSquareFootagePrice
                  }
                  placeholder="0"
                  renderValue={(value) => value}
                  inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                  type="number"
                  startAdornment={
                    <InputAdornment position="start">
                      {" "}
                      <Typography variant="adornment" color="secondary">
                        {" "}
                        $
                      </Typography>
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="start">
                      {" "}
                      <Typography variant="adornment" color="secondary">
                        {" "}
                        /SqFt
                      </Typography>
                    </InputAdornment>
                  }
                />
              </FormControl>
              {!isAdvanced && (
                <Box sx={styles.bottomLabel}>
                  <Typography
                    variant="h6"
                    color="primary"
                    sx={styles.underInput}
                    onClick={() => handleOpenDialog("new_construction")}
                  >
                    {remodelMainCardStaticData.input1.lowerLabel}
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "0.5rem",
          }}
        >
          {!isAdvanced &&
            additionalUnitArray?.map((item, index) => (
              <FormControl sx={{ width: "45%", mt: "1rem" }} key={index}>
                <StyledLabel
                  label={`Unit ${index + 2}`}
                  showToolTip
                  // toolTipContent={toolTipTexts.bedrooms}
                />

                <OutlinedInput
                  name={"value"}
                  onKeyPress={preventMinus}
                  type="number"
                  onChange={(event) => componentDynamicDataFilter(index, event)}
                  value={item?.value}
                  inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                  sx={{ fontSize: "16px" }}
                  endAdornment={
                    <InputAdornment position="start">
                      {" "}
                      <Typography variant="adornment"> SqFt</Typography>
                    </InputAdornment>
                  }
                ></OutlinedInput>
              </FormControl>
            ))}
        </Box>

        {!!adu && (
          <Box sx={styles.inputContainer}>
            <StyledLabel label={"Accessory Dwelling Unit SqFt Cost"} />
            <FormControl sx={{ minWidth: "18.75rem" }}>
              <OutlinedInput
                fullWidth
                margin="normal"
                name={"adu_price"}
                onKeyPress={preventMinus}
                onChange={isAdvanced ? "" : handleChange}
                value={data?.adu_price}
                placeholder="0"
                renderValue={(value) => value}
                inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                type="number"
                sx={isAdvanced ? greyout : {}}
                startAdornment={
                  <InputAdornment position="start">
                    {" "}
                    <Typography variant="adornment" color="secondary">
                      {" "}
                      $
                    </Typography>
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="start">
                    {" "}
                    <Typography variant="adornment" color="secondary">
                      {" "}
                      /SqFt
                    </Typography>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Box>
        )}
        {!!basement_sqrft && (
          <Box sx={styles.inputContainer}>
            <StyledLabel label={"Basement Unit SqFt Cost"} />
            <FormControl sx={{ minWidth: "18.75rem" }}>
              <OutlinedInput
                fullWidth
                margin="normal"
                name={"basement_price"}
                onKeyPress={preventMinus}
                onChange={isAdvanced ? "" : handleChange}
                value={data?.basement_price}
                placeholder="0"
                renderValue={(value) => value}
                inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                type="number"
                sx={isAdvanced ? greyout : {}}
                startAdornment={
                  <InputAdornment position="start">
                    {" "}
                    <Typography variant="adornment" color="secondary">
                      {" "}
                      $
                    </Typography>
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="start">
                    {" "}
                    <Typography variant="adornment" color="secondary">
                      {" "}
                      /SqFt
                    </Typography>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Box>
        )}
        <Grid container columns={24}>
          <Grid item sm={24} md={11}>
            <Box sx={styles.inputContainer}>
              <StyledLabel label={"Est. Remodel Cost"} />
              <FormControl sx={{ width: "100%" }}>
                <OutlinedInput
                  fullWidth
                  margin="normal"
                  sx={greyout}
                  value={
                    isAdvanced
                      ? commaSeparate(remodelCost)
                      : commaSeparate(returnedRemodelCost)
                  }
                  placeholder="0"
                  renderValue={(value) => value}
                  inputProps={{ style: { fontSize: "1rem" } }}
                  startAdornment={
                    <InputAdornment position="start">
                      {" "}
                      <Typography variant="adornment" color="secondary">
                        {" "}
                        $
                      </Typography>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Box>
          </Grid>
          <Grid item sm={0} md={2} />
          <Grid item sm={24} md={11}>
            <Box sx={styles.inputContainer}>
              <StyledLabel label={"Addition Cost"} />
              <FormControl sx={{ width: "100%" }}>
                <OutlinedInput
                  fullWidth
                  margin="normal"
                  name={"est_new_construction_cost"}
                  sx={greyout}
                  onKeyPress={preventMinus}
                  value={
                    isAdvanced
                      ? commaSeparate(newConstructionCost)
                      : commaSeparate(returnAdditonalCost)
                  }
                  placeholder="0"
                  renderValue={(value) => value}
                  inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                  startAdornment={
                    <InputAdornment position="start">
                      {" "}
                      <Typography variant="adornment" color="secondary">
                        {" "}
                        $
                      </Typography>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "0.5rem",
          }}
        >
          {!!adu && (
            <Box display={"flex"} gap={"4rem"}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  my: "2rem",
                }}
              >
                <StyledLabel label={"Accessory Dwelling Unit SqFt"} />
                <FormControl sx={{ minWidth: "18.75rem" }}>
                  <OutlinedInput
                    fullWidth
                    margin="normal"
                    name={"adu"}
                    onChange={handleChange}
                    value={adu || 0}
                    sx={greyout}
                    placeholder="0"
                    onKeyPress={preventMinus}
                    renderValue={(value) => value}
                    inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                    type="number"
                    endAdornment={
                      <InputAdornment position="start">
                        {" "}
                        <Typography variant="adornment" color="secondary">
                          {" "}
                          SqFt
                        </Typography>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  my: "2rem",
                }}
              >
                <StyledLabel label={"Accs Dwelling Unit SqFt  Total Cost"} />
                <FormControl sx={{ minWidth: "18.75rem" }}>
                  <OutlinedInput
                    fullWidth
                    margin="normal"
                    name={"adu"}
                    onChange={handleChange}
                    value={data?.adu_price * adu || 0}
                    sx={greyout}
                    placeholder="0"
                    onKeyPress={preventMinus}
                    renderValue={(value) => value}
                    inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                    type="number"
                    endAdornment={
                      <InputAdornment position="start">
                        {" "}
                        <Typography variant="adornment" color="secondary">
                          {" "}
                          SqFt
                        </Typography>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Box>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "0.5rem",
          }}
        >
          {!!basement_sqrft && (
            <Box display={"flex"} gap={"4rem"}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  my: "2rem",
                }}
              >
                <StyledLabel label={"Basement Unit SqFt"} />
                <FormControl sx={{ minWidth: "18.75rem" }}>
                  <OutlinedInput
                    fullWidth
                    margin="normal"
                    name={"adu"}
                    onChange={handleChange}
                    value={basement_sqrft || 0}
                    sx={greyout}
                    placeholder="0"
                    onKeyPress={preventMinus}
                    renderValue={(value) => value}
                    inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                    type="number"
                    endAdornment={
                      <InputAdornment position="start">
                        {" "}
                        <Typography variant="adornment" color="secondary">
                          {" "}
                          SqFt
                        </Typography>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  my: "2rem",
                }}
              >
                <StyledLabel label={"Basement Unit SqFt  Total Cost"} />
                <FormControl sx={{ minWidth: "18.75rem" }}>
                  <OutlinedInput
                    fullWidth
                    margin="normal"
                    name={"adu"}
                    onChange={handleChange}
                    value={data?.basement_price * basement_sqrft || 0}
                    sx={greyout}
                    placeholder="0"
                    onKeyPress={preventMinus}
                    renderValue={(value) => value}
                    inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                    type="number"
                    endAdornment={
                      <InputAdornment position="start">
                        {" "}
                        <Typography variant="adornment" color="secondary">
                          {" "}
                          SqFt
                        </Typography>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Box>
            </Box>
          )}
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          {unitCostResult?.indexMultiplications?.map((item, index) => (
            <Box sx={styles.inputContainer}>
              <StyledLabel
                label={`Unit ${index + 2} Remodel Cost per Square Foot`}
              />
              <FormControl sx={{ minWidth: "8.75rem" }}>
                <OutlinedInput
                  fullWidth
                  margin="normal"
                  name={remodelMainCardStaticData?.input2.name}
                  onChange={handleChange}
                  value={item?.value || 0}
                  sx={greyout}
                  placeholder="0"
                  onKeyPress={preventMinus}
                  renderValue={(value) => value}
                  inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                  type="number"
                  startAdornment={
                    <InputAdornment position="start">
                      {" "}
                      <Typography variant="adornment" color="secondary">
                        {" "}
                        $
                      </Typography>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Box>
          ))}
        </Box>
        <Box sx={styles.inputContainer}>
          <Box>
            <StyledLabel label={"Est. Total Remodel + Addition Sqft Cost"} />
            <RegularToolTip content="Edit">
              <EditIcon
                sx={{
                  fontSize: "1.2rem",
                  mx: 3,
                  color: "#1378A5",
                  cursor: "pointer",
                }}
                onClick={() => handleSubMenu(1)}
              />
            </RegularToolTip>
          </Box>

          <FormControl sx={{ width: "100%" }}>
            <OutlinedInput
              fullWidth
              margin="normal"
              name={"est_total_remodel_cost_plus_additional_sqft_cost"}
              sx={greyout}
              value={
                isAdvanced
                  ? commaSeparate(estimatedTotalRemodelWithAdditional)
                  : commaSeparate(totalAmountReturned)
              }
              placeholder="0"
              onKeyPress={preventMinus}
              renderValue={(value) => value}
              inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
              startAdornment={
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="adornment" color="secondary">
                    {" "}
                    $
                  </Typography>
                </InputAdornment>
              }
            />
          </FormControl>
          {/* <Box sx={styles.bottomLabel}>
            <Typography
              variant="notice"
              color="primary"
              sx={styles.underInput}
              onClick={() => handleSubMenu(1)}
            >
              {remodelMainCardStaticData.input3.lowerLabel}
            </Typography>
            <HtmlToolTip
              content={{
                text: "Add Items and their estimated dollar value individually",
              }}
            />
          </Box> */}
        </Box>
      </Box>
    </>
  );
}

export default RemodelMainCardAlt0;

const remodelMainCardStaticData = {
  input1: {
    label: "Contractor Pre Existing Remodel Cost Per Square foot (Main Unit)",
    lowerLabel:
      "Get an estimated dollar per square foot from a Local Licensed Contractor",
    name: "est_remodel_cost_psqft",
  },
  input2: {
    label: "Pre Existing Livable Square Footage",
    name: "pre_existing_livable_square_footage",
  },
  input3: {
    label: "Est. Total Remodel + Addition Sqft Cost",
    lowerLabel: "Advanced Remodel Cost",
    name: "est_total_remodel_cost_plus_additional_sqft_cost",
  },
};

import { Close, Description, Download, DownloadOutlined, Launch, MenuBookOutlined, PlayCircleOutline } from '@mui/icons-material';
import { Button, Dialog, DialogContent, DialogTitle, Divider, Grid, Stack, ThemeProvider, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import Logo from '../images/bpoVerticalLogo.webp';
import Cash from '../images/cashflow.png';
import BG from '../images/gradientImage.jpg';
import Person from '../images/join.png';
import { ReactComponent as Zoom } from '../images/zoom.svg';
import ZoomThumb from '../images/zoomThumb.jpg';
import { theme } from '../theme';

const newTheme = {
	...theme,
	typography: {
		fontFamily: 'Lato, san-serif',
		fontSize: 16,
		button: {
			textTransform: 'capitalize',
			padding: '0 10px',
			fontFamily: 'Inter, san-serif',
		},
	},
};

const zoomLinks = [
	{
		title: 'BPO Homes Training Session',
		time: 'Tuesdays / Thursdays 12:00 PM Pacific Time (US and Canada)',
		description: 'Zoom Meeting for BPO Homes Training session held every wednesday and Friday at 12:00 PM Pacific Time (US and Canada)',
		addToCalendarLink:
			'https://us06web.zoom.us/meeting/tZAlcOqpqTgtGtei1105riYYZc8d0dgkSsuD/ics?icsToken=98tyKuGspjwpG9OWtxmERpwIGo_Cb-vwtnZajbdYlU60U3VBazrPDOEaNuJ7F9Pj',
		joinMeetingLink: 'https://us06web.zoom.us/j/84873173703?pwd=ZW1FRnE4eHkyMVYvcy85RFFVcjhEZz09',
		all: false,
	},
	{
		title: 'BPO TECH Training Session',
		time: 'Wednesdays / Fridays 12:00 PM Pacific Time (US and Canada)',
		description: 'Zoom Meeting for BPO Tech Training session held every wednesday and Friday at 12:00 PM Pacific Time (US and Canada)',
		addToCalendarLink:
			'https://us06web.zoom.us/meeting/tZcsdeqhpj8vHtGGQs98YOJDOGkSBhXo9huv/ics?icsToken=98tyKuGrrzkpE9yRtR2CRpwqBYjob_PxiClYgrd6uy72WnhqTSnSGcVJAZB3KNeJ',
		joinMeetingLink: 'https://us06web.zoom.us/j/83123984545?pwd=QWtIck5IaG9BTmFycFhQSTM3dkRHZz09',
		all: true,
	},
];

const TrainingCenter = ({ tech }) => {
	const baseUrl = `${tech ? 'tech' : 'homes'}-training-center`;
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedDoc, setSelectedDoc] = useState(null);
	const handleViewDoc = (doc) => {
		setIsModalOpen(true);
		setSelectedDoc(doc);
	};
	const handleDownload = (doco) => {
		const link = document.createElement('a');
		const doc = doco || selectedDoc;
		link.href = doco?.itemLink;
		link.target = '_blank';
		link.download = doc?.title;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};
	const [numPages, setNumPages] = useState();

	return (
		<ThemeProvider theme={newTheme}>
			<Dialog fullWidth open={isModalOpen} onClose={() => setIsModalOpen(false)} maxWidth='lg'>
				<DialogTitle>
					<Stack direction='row' justify='space-between'>
						<Typography>{selectedDoc?.title}</Typography>
						<Stack direction='row' sx={{ marginLeft: 'auto' }} gap='1rem'>
							{selectedDoc?.canDownload && <Download onClick={handleDownload} style={{ cursor: 'pointer' }} />}
							<Close fontSize='small' onClick={() => setIsModalOpen(false)} style={{ cursor: 'pointer', marginLeft: 'auto' }} />
						</Stack>
					</Stack>
				</DialogTitle>
				<DialogContent dividers>
					<Stack direction='row' justifyContent={'center'}>
						<Document
							file={selectedDoc?.itemLink}
							onLoadSuccess={({ numPages }) => {
								setNumPages(numPages);
							}}
						>
							{Array.from(new Array(numPages), (el, index) => (
								<React.Fragment key={`page_${index + 1}`}>
									<Page pageNumber={index + 1} />
									{index !== 0 && <hr />}
								</React.Fragment>
							))}
						</Document>
					</Stack>
				</DialogContent>
			</Dialog>
			<Stack padding={'1rem'} gap='1.5rem' sx={{ position: 'relative' }}>
				<Typography
					fontFamily={'Inter'}
					sx={{
						borderBottom: '1px solid #D0D5DD',
						color: '#475467',
						position: 'sticky',
						top: 'calc(var(--navheight))',
						background: 'white',
						py: '1rem',
						pb: '1.5rem',
						zIndex: 100,
						fontSize: '24px',
					}}
					fontWeight={700}
				>
					BPO Homes Training Center
				</Typography>
				<Grid container spacing={2}>
					{zoomLinks
						.filter((i) => (!tech ? i : i.all))
						.map((item) => (
							<Grid item xs={6} key={item.title}>
								<img src={ZoomThumb} alt='zoom' style={{ width: '100%', aspectRatio: '2.5', objectFit: 'cover' }} />
								<Stack gap='1rem' mt='2rem'>
									<Typography
										fontFamily={'Lato'}
										fontSize={'14px'}
										fontWeight={600}
										color='#004073'
										sx={{ borderBottom: '1px solid currentColor', paddingBottom: '3px', width: 'fit-content' }}
									>
										{item.time}
									</Typography>
									<Typography
										fontFamily={'Lato'}
										fontSize='24px'
										sx={{ borderBottom: '1px solid currentColor', paddingBottom: '3px', width: 'fit-content' }}
									>
										{item.title}
									</Typography>
									<Typography fontFamily={'Lato'} fontSize='16px' color='#475467'>
										{item.description}
									</Typography>
									<Stack direction='row' gap='1rem' justifyContent='flex-end'>
										<Button
											variant='outlined'
											color='secondary'
											sx={{ fontWeight: 600, fontSize: '14px', color: '#344054', borderColor: '#344054', borderRadius: '8px', fontFamily: 'Lato' }}
											disableElevation
											disableRipple
											href={item.addToCalendarLink}
											target='_blank'
											rel='noreferrer'
										>
											Add to Calendar
										</Button>
										<Button
											variant='contained'
											color='secondary'
											sx={{ fontSize: '14px', fontWeight: 600, borderRadius: '8px', fontFamily: 'Lato' }}
											disableElevation
											disableRipple
											href={item.joinMeetingLink}
											target='_blank'
											rel='noreferrer'
										>
											Join Meeting
										</Button>
									</Stack>
								</Stack>
							</Grid>
						))}
				</Grid>

				{tech && (
					<Stack style={{ backgroundColor: '#C4C4C433' }} direction='row' alignItems='center'>
						<Stack width={'68%'} justifyContent={'center'} alignItems={'center'} gap='1.5rem'>
							<Typography fontFamily={'Lato'} fontSize='25px' fontWeight={700} textAlign='center'>
								Join <span style={{ color: '#005EA9' }}>BPO Homes Realty</span> and <span style={{ color: '#005EA9' }}>Save your Money $$$.</span>{' '}
								<br />
								Commission Rates starting at only <span style={{ color: '#005EA9' }}>$199.00</span> per Transaction
							</Typography>
							<Button href={'https://bpohomes.com/join'} target='_blank' variant='contained' color='secondary' sx={{ padding: '10px 1rem' }}>
								Sign up now
							</Button>
						</Stack>
						<Stack width='28%' direction='row' alignItems='flex-end' justifyContent={'center'}>
							<img src={Person} width='400px' alt='join' />
						</Stack>
					</Stack>
				)}
				<Stack style={{ backgroundColor: '#C4C4C433' }} direction='row' alignItems='center' gap='1rem' mt={'2rem'} p='3rem 1rem'>
					<img src={Cash} width='200px' alt='join' />
					<Stack flex={1} justifyContent={'center'} alignItems={'center'} gap='1.5rem'>
						<Typography fontFamily={'Lato'} fontSize='25px' fontWeight={700} textAlign='center'>
							Bring your <span style={{ color: '#005EA9' }}>OFF- Market Deals</span> to <span style={{ color: '#005EA9' }}>CashFlow 4U</span> <br />
							and Earn up to <span style={{ color: '#005EA9' }}>6 commissions </span> on <span style={{ color: '#005EA9' }}>1 Transaction </span> $$$
						</Typography>
						{/* <Button href={'https://bpohomes.com/join'} target='_blank' variant='contained' color='secondary' sx={{ padding: '10px 1rem' }}>
							Sign up now
						</Button> */}
					</Stack>
					<img src={Cash} width='200px' alt='join' />
				</Stack>

				<Stack p='1rem' gap='2rem'>
					<Typography fontSize={'25px'} fontWeight={600} fontFamily={'Lato'}>
						Video Library
					</Typography>
					<Stack direction='row' justifyContent={'space-between'} alignItems='center'>
						<Stack>
							<Typography variant='body1' fontFamily='Lato' color='#475467' fontWeight={400} fontSize='14px'>
								Learning often happens in the classroom but it doesn't have to
							</Typography>
							<Typography variant='body1' fontFamily='Lato' color='#475467' fontWeight={400} fontSize='14px'>
								Use BPO Training center to facilitate learning experiences no matter the context
							</Typography>
						</Stack>
						<Button variant='contained' color='secondary' href={`${baseUrl}/video`}>
							Show more
						</Button>
					</Stack>
					<Divider />
					<Grid container spacing={2} my='2rem'>
						{libraries[0].items.slice(0, 3).map((item) => (
							<Grid item xs={4} key={item.title}>
								<Stack>
									{item.link.includes('youtube') ? (
										<iframe
											id={item.link}
											style={{
												borderRadius: '5px',
												border: 'none',
												width: '100%',
												aspectRatio: '16/9',
											}}
											src={item.link}
											title='YouTube video player'
											allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
											allowFullScreen
										></iframe>
									) : (
										<video
											src={item.link}
											controls
											autoPlay={false}
											style={{ borderRadius: '5px', border: 'none', width: '100%', aspectRatio: '16/9' }}
											poster={BG}
										/>
									)}
									<Typography fontSize={'20px'} fontWeight={600} fontFamily={'Lato'}>
										{item.title}
									</Typography>
									<Typography variant='body1' fontFamily='Lato' color='#475467' fontWeight={400} fontSize='14px'>
										{item.description}
									</Typography>
								</Stack>
							</Grid>
						))}
					</Grid>
					<Divider />
				</Stack>
				<Stack p='1rem' gap='2rem'>
					<Typography fontSize={'25px'} fontWeight={600} fontFamily={'Lato'}>
						Document Library
					</Typography>
					<Stack direction='row' justifyContent={'space-between'} alignItems='center'>
						<Stack>
							<Typography variant='body1' fontFamily='Lato' color='#475467' fontWeight={400} fontSize='14px'>
								Learning often happens in the classroom but it doesn't have to
							</Typography>
							<Typography variant='body1' fontFamily='Lato' color='#475467' fontWeight={400} fontSize='14px'>
								Use BPO Training center to facilitate learning experiences no matter the context
							</Typography>
						</Stack>
						<Button variant='contained' color='secondary' href={`${baseUrl}/document`}>
							Show more
						</Button>
					</Stack>
					<Divider />
					<Grid container spacing={2} my='2rem'>
						{libraries[2].items.slice(0, 3).map((item) => (
							<Grid item xs={4} key={item.title}>
								<Stack gap='1rem'>
									<Stack
										justifyContent='center'
										alignItems='center'
										sx={{ width: '100%', aspectRatio: '16/9', background: `url(${BG})`, backgroundSize: 'cover', cursor: 'pointer' }}
										onClick={() => handleViewDoc(item)}
									>
										<img src={Logo} alt='Logo' style={{ height: '45px' }} />
									</Stack>
									<Typography fontSize={'20px'} fontWeight={600} fontFamily={'Lato'}>
										{item.title}
									</Typography>
									<Typography variant='body1' fontFamily='Lato' color='#475467' fontWeight={400} fontSize='14px'>
										{item.description}
									</Typography>
									<Stack direction='row' justifyContent={'space-between'}>
										<Button startIcon={<MenuBookOutlined />} color='secondary' onClick={() => handleViewDoc(item)}>
											View Document
										</Button>
										{item.canDownload && (
											<Button startIcon={<DownloadOutlined />} color='secondary' onClick={() => handleDownload(item)}>
												Download
											</Button>
										)}
									</Stack>
								</Stack>
							</Grid>
						))}
					</Grid>
				</Stack>
				<Stack p='1rem' gap='2rem'>
					<Typography fontSize={'25px'} fontWeight={600} fontFamily={'Lato'}>
						Recorded Zoom Training
					</Typography>
					<Stack direction='row' justifyContent={'space-between'} alignItems='center'>
						<Stack>
							<Typography variant='body1' fontFamily='Lato' color='#475467' fontWeight={400} fontSize='14px'>
								Learning often happens in the classroom but it doesn't have to
							</Typography>
							<Typography variant='body1' fontFamily='Lato' color='#475467' fontWeight={400} fontSize='14px'>
								Use BPO Training center to facilitate learning experiences no matter the context
							</Typography>
						</Stack>
						<Button variant='contained' color='secondary' href={`${baseUrl}/zoom`}>
							Show more
						</Button>
					</Stack>
					<Divider />
					<Grid container spacing={2} my='2rem'>
						{libraries[1].items.slice(0, 3).map((item) => (
							<Grid item xs={4} key={item.title}>
								<Stack gap='1rem'>
									<Stack
										justifyContent='center'
										alignItems='center'
										sx={{ width: '100%', aspectRatio: '16/9', background: `url(${BG})`, backgroundSize: 'cover', cursor: 'pointer' }}
										component='a'
										href={item.itemLink}
										target='_blank'
										// onClick={() => openZoomLink(item)}
									>
										<img src={Logo} alt='Logo' style={{ height: '45px' }} />
									</Stack>
									<Typography fontSize={'20px'} fontWeight={600} fontFamily={'Lato'}>
										{item.title}
									</Typography>
									<Typography variant='body1' fontFamily='Lato' color='#475467' fontWeight={400} fontSize='14px'>
										{item.description}
									</Typography>
									<Stack direction='row' justifyContent={'space-between'}>
										<Button startIcon={<Launch />} color='secondary' href={item.itemLink} target='_blank'>
											Watch Recording
										</Button>
									</Stack>
								</Stack>
							</Grid>
						))}
					</Grid>
				</Stack>

				<Stack justifyContent='center' alignItems='center' gap='1rem'>
					<Typography fontSize='24px' fontFamily='Lato'>
						<span style={{ textDecoration: 'underline', color: '#005EA9', textUnderlineOffset: '5px', textDecorationColor: '#FFAA01' }}>
							Training
						</span>{' '}
						<span style={{ color: '#FFAA01', textDecoration: 'underline', textDecorationColor: '#005ea9', textUnderlineOffset: '5px' }}>Center</span>
					</Typography>
					<Typography mb='1rem' fontStyle={'italic'} fontFamily={'Lato'}>
						"At BPO, we believe in the power of continuous learning and professional growth
					</Typography>
					<Typography fontStyle={'italic'} fontFamily={'Lato'}>
						Our training programs are your launchpad to success in real estate.
					</Typography>
					<Typography fontStyle={'italic'} fontFamily={'Lato'}>
						Dive in, learn fast, and let's achieve greatness together!"
					</Typography>
					<img
						alt='ceo'
						style={{ width: '50px', aspectRatio: '1', borderRadius: '50%' }}
						src={
							'https://bpooperationsmain.s3.amazonaws.com/profile_images/JobanBrownRealEstate_bpMVoVR_bIOBhix_2vTV600_8e6QVAx_HAox7Lw_Momjbyt_TgQh_cD42QsX.jpg'
						}
					></img>
					<Typography color='grey' fontSize='14px' fontFamily={'Lato'}>
						Joban Brown, CEO, BPO Tech Inc.
					</Typography>
				</Stack>
			</Stack>
		</ThemeProvider>
	);
};

export default TrainingCenter;

export const GradientHolder = ({ children, ...rest }) => {
	return (
		<Stack
			width={'175px'}
			sx={{ backgroundImage: `url(${BG})`, borderRadius: '5px', backgroundSize: 'cover', cursor: 'pointer' }}
			alignItems='center'
			height='150px'
			justifyContent='center'
			{...rest}
		>
			{children || <Zoom />}
		</Stack>
	);
};

export const LibraryItem = ({ title, description, icon, link, itemLink }) => {
	const linkItem = link || itemLink;
	return (
		<Stack direction='column' sx={{ height: '100%' }} gap='1rem'>
			{!linkItem ? (
				<GradientHolder>{icon}</GradientHolder>
			) : linkItem.includes('youtube') ? (
				<iframe
					id={linkItem}
					style={{
						borderRadius: '5px',
						border: 'none',
						width: '100%',
						aspectRatio: '16/9',
					}}
					src={linkItem}
					title='YouTube video player'
					allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
					allowFullScreen
				></iframe>
			) : (
				<video
					src={linkItem}
					controls
					autoPlay={false}
					style={{ borderRadius: '5px', border: 'none', width: '100%', aspectRatio: '16/9' }}
					poster={BG}
				/>
			)}
			<Stack direction='column' sx={{ flex: 2 }} alignItems='flex-start' gap='1rem'>
				<Typography sx={{ fontWeight: 600, fontSize: 16, fontFamily: 'Lato' }}>{title}</Typography>
				<Typography
					sx={{
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						display: '-webkit-box',
						WebkitLineClamp: '2',
						WebkitBoxOrient: 'vertical',
						fontWeight: 400,
						fontSize: 14,
						color: '#667085',
						fontFamily: 'Lato',
					}}
				>
					{description}
				</Typography>
			</Stack>
		</Stack>
	);
};

export const libraries = [
	{
		title: 'Video Library',
		viewMoreTitle: 'All Video Tutorials',
		viewMoreLink: 'video',
		icon: <PlayCircleOutline htmlColor='#FFFFFF4D' fontSize='large' />,
		items: [
			{
				title: 'How to Make 6 Commissions on One Transaction',
				description: `Learn to Leverage your Investors & Wholesalers and Earn 6 commissions on 1 Deal!!`,
				link: 'https://www.youtube.com/embed/qp9Tzc37nqk',
				thumbnail: 'https://i3.ytimg.com/vi/qp9Tzc37nqk/maxresdefault.jpg',
				all: true,
			},
			{
				title: 'Why Buyers & Sellers Need a BPO Report',
				link: 'https://www.youtube.com/embed/HIX9gI2OL8k',
				description: 'Why you need a BPO Report',
				all: true,
			},
			{
				title: 'Why BPO Reports Are Needed Example No.2',
				link: 'https://www.youtube.com/embed/v3_b46Lzw_I',
				description: 'Why you need a BPO Report',
				all: true,
			},
			{
				title: 'Buyer Agency Relationship Disclosure',
				description: 'Contractual Explanation of Page 1 of the California (C.A.R Form AD) Agency Relationship Disclosure',
				all: false,
				link: 'https://www.youtube.com/embed/3Mp-AyU5meY',
			},
			{
				title: 'Possible Representation of more than one buyer or seller',
				link: 'https://bpooperationsmain.s3.eu-west-2.amazonaws.com/agent_documents+/possibleRepresentationofMorethanonebuyerorsellertrainingcenter.mp4',
				all: false,
				description: 'Possible Representation of more than one Buyer or Seller',
			},
			{
				title: 'Fair Housing Discrimination Advisory',
				link: 'https://bpooperationsmain.s3.eu-west-2.amazonaws.com/agent_documents+/fair+Housing+Discsrimination+Advisory.mp4',
				all: false,
				description: 'Fair Housing Discrimination Advisory',
			},
		],
	},
	{
		title: 'Recorded Zoom Trainings',
		viewMoreTitle: 'All Recorded Zoom Training',
		viewMoreLink: 'zoom',
		icon: <Zoom />,
		items: [
			{
				title: 'BPO Tech Training- August 14 2024',
				description: 'BPO Tech Training for August 14, 2024',
				itemLink: `https://us06web.zoom.us/rec/share/H9BKFzJAARxx3W2Z2Gou2Y853ktLO0l46xkpTWIrTVJMwD5y3Vd8-EEsMeUu84py.WTIC7yv0bwF-pxh8`,
				all: true,
			},
			{
				title: 'BPO Tech Training- August 9 2024',
				description: 'BPO Tech Training for August 9, 2024',
				itemLink: `https://us06web.zoom.us/rec/share/Rrc1PK9ajiAFX0eq7T12PoCAotFCNRLLlCJ8ThdkhvaGmncLA8crvR7o4sAIfrVf.DvgFHcryuuhLslEh`,
				all: true,
			},
			{
				title: 'BPO Tech Training- August 7 2024',
				description: 'BPO Tech Training for August 7, 2024',
				itemLink: `https://us06web.zoom.us/rec/share/qGCDkS-FQyEc91fU7UH31Q5_OuEqebP2lxfLND-dr74yru-bkKxMTNJqxtW20Bhp.XzfNjxdCSAthXxwx`,
				all: true,
			},
			{
				title: 'BPO Tech Training- August 2 2024- Part 2',
				description: 'BPO Tech Training for August 2, 2024- Part 2',
				itemLink: `https://us06web.zoom.us/rec/share/_U9Zl5hEO4NCxZGm8P3jvp-bitfT7PbPAEKDYHmGZnAosRd0niriSb5aK4h4X-Wb.x3Hpgp6zk5PbAOfv`,
				all: true,
			},
			{
				title: 'BPO Tech Training- August 2 2024- Part 1',
				description: 'BPO Tech Training for August 2, 2024- Part 1',
				itemLink: `https://us06web.zoom.us/rec/share/xmOQS9aHsFs9roqUJ4hH4iGgpRsdlCTRPTr9DBgD7T9TMrdth4nnMNJ88Gc9V-Fh.AOW8nbk7DpUntHWo`,
				all: true,
			},
			{
				title: 'BPO Tech Training- July 31 2024',
				description: 'BPO Tech Training for July 31, 2024',
				itemLink: `https://us06web.zoom.us/rec/share/bBBD7BKwo2qqGOg4MawkqpdXCGliIsg6_C4aqUkuGVl7QGAfPqNYkcbEUSy9fvtK.YkmN6Mvf_CZ7hOEN`,
				all: true,
			},
			{
				title: 'BPO Tech Training- July 26 2024',
				description: 'BPO Tech Training for July 26, 2024',
				itemLink: `https://us06web.zoom.us/rec/share/M4XdYTDbaEnmk5zLqyL-nAcgOTHEH7RN9W-j2tB075Jhx-q86NoqjLxd1hmrauJq.iC7YLU17ESDcPl5t`,
				all: true,
			},
			{
				title: 'BPO Tech Training- July 24 2024',
				description: 'BPO Tech Training for July 24, 2024',
				itemLink: `https://us06web.zoom.us/rec/share/7GkBZtU3lDF8RKtqY4KhCPzaJwdPgMAwjuGB_PcUvGkvTBUoRMRUvU5EeFtWHnxW.5-aRy7pc_pakovWZ`,
				all: true,
			},
			{
				title: 'BPO Tech Training- July 19 2024',
				description: 'BPO Tech Training for July 19, 2024',
				itemLink: `https://us06web.zoom.us/rec/share/ssy6tHm18uNEsIqUOVSXbqcl8M2vcAFyRm1KOw7CJATyG3SuPwrc6xDqj-fuX7rN.1PaiWRbzbkEgfxGf`,
				all: true,
			},
			{
				title: 'BPO Tech Training- July 17 2024',
				description: 'BPO Tech Training for July 17, 2024',
				itemLink: `https://us06web.zoom.us/rec/share/q9w3sNL-F4Vm6Irol8xuiAHJQFua0AhxlN3gIttZvwUUb-2r2HMuZUWXnExBVBJz.siY28tfYorFEERV7`,
				all: true,
			},
			{
				title: 'BPO Tech Training- July 12 2024',
				description: 'BPO Tech Training for July 12, 2024',
				itemLink: `https://us06web.zoom.us/rec/share/oRo0S-Wj_Ym4rbr6Kvq7SVMhPEHYrLNQP6y6AQWNb257ecyTIKe3tPwWjGRlRygm.26fWeP034_vcLZK-`,
				all: true,
			},
			{
				title: 'BPO Tech Training- July 10 2024',
				description: 'BPO Tech Training for July 10, 2024',
				itemLink: `https://us06web.zoom.us/rec/share/ekYWXs65yaPVrcFaQclVeVqd4mdWWEqCw4XVLvCg2LueZyUtKxQT8hO-Gh25Wf8E.rZoQUb0X1UG8mvQ-`,
				all: true,
			},
			{
				title: 'BPO Tech Training- July 5 2024',
				description: 'BPO Tech Training for July 5, 2024',
				itemLink: `https://us06web.zoom.us/rec/share/eCbW_MYyNR_zpT1sxK17WuY5mV1TZ2iMfhrLrvb_rSRpygJDUPT_2dsoEuel1Tlr.4R3APEbXw-ZMzjAK`,
				all: true,
			},
			{
				title: 'BPO Tech Training- July 3 2024',
				description: 'BPO Tech Training for July 3, 2024',
				itemLink: `https://us06web.zoom.us/rec/share/zBzZAVwB_pc247EZL1pQHwUZtLK3ew0NsYcypAmWPLsssHujOpa-I33WTzzokVVP.lV_eT_dj5WqEwp86`,
				all: true,
			},
			{
				title: 'BPO Tech Training- June 28 2024',
				description: 'BPO Tech Training for June 28, 2024',
				itemLink: `https://us06web.zoom.us/rec/share/6Pd-J7p31YB8m5Frh00Y4S1VA_8wBqe_RHgJVLDdznVfvK8JlPuW_BjYy6Aramxw.OVUbuzZt-pWWtsG_`,
				all: true,
			},
			{
				title: 'BPO Tech Training- June 26 2024',
				description: 'BPO Tech Training for June 26, 2024',
				itemLink: `https://us06web.zoom.us/rec/share/Xv0wAk6GZqClEMslWlobBPs5Wp_ckvPcOJ_fXCf_uzhFReMGNM6A-cm7G1eybGVo.xvyWtZQeQJL1v8WT`,
				all: true,
			},
			{
				title: 'BPO Tech Training- June 21 2024',
				description: 'BPO Tech Training for June 21, 2024',
				itemLink: `https://us06web.zoom.us/rec/share/PLT9a8vifYWTz6G3_Vm9P95rGVw0MunURKkseNyXz3U5WDPUZ0VcYSYk6a1Z-l82.xA5eFaJ8UKYrYhZR`,
				all: true,
			},
			{
				title: 'BPO Tech Training- June 19 2024',
				description: 'BPO Tech Training for June 19, 2024',
				itemLink: `https://us06web.zoom.us/rec/share/O2AGxViUCpuzAGvha8kjTsxaTLkFCcumJWrSvJtS46PcUmkPNG7HRlrBPdU7Lt6U.Bv7V3VVI8bwclaO2`,
				all: true,
			},
			{
				title: 'BPO Tech Training- June 14 2024',
				description: 'BPO Tech Training for June 14, 2024',
				itemLink: `https://us06web.zoom.us/rec/share/u6dzsCokWzCpDRPqMo-E2XvYdsUY48hdeFSZL9Zrhvb0PBv9NsMkJBosNSGKQ3_Y.L381gWXnsG_MFtx2`,
				all: true,
			},
			{
				title: 'BPO Tech Training- June 12 2024',
				description: 'BPO Tech Training for June 12, 2024',
				itemLink: `https://us06web.zoom.us/rec/share/8DXDLoEw5e9jdCh-BAnD2x5RINNkcJ0mR3J9-0aj9MWbfBv9Zpnl84BbWglJxEhQ.wLmObaM1sZRWMw7t`,
				all: true,
			},
			{
				title: 'BPO Tech Training- June 7 2024',
				description: 'BPO Tech Training for June 7, 2024',
				itemLink: `https://us06web.zoom.us/rec/share/d8tcL62DVuxRm6sxoPK2C6J_yD3-XqXJPlef6i6AK89_qteTtZ0qZdg62_Xkm1rR.K6A2hrQcQ6yMRdA1`,
				all: true,
			},
			{
				title: 'BPO Tech Training- June 5 2024',
				description: 'BPO Tech Training for June 5, 2024',
				itemLink: `https://us06web.zoom.us/rec/share/_NhazybjsBqBU5iCO-AqSw8UwjnSOgr1-tOvBMZtuPxRF5DtA7LI1bGcjvP3F4F6.OwXDg4_Lz6UTjL7b`,
				all: true,
			},
			{
				title: 'BPO Tech Training- May 31 2024',
				description: 'BPO Tech Training for May 31, 2024',
				itemLink: `https://us06web.zoom.us/rec/share/4rIHvrNilRAHfCUrbBSPD1v7wgwbxUsbkKMG9maR6kldZlNiPP6EVs7vw88EVKU.Qv4MLFrAiDJIEXD5`,
				all: true,
			},
			{
				title: 'BPO Tech Training- May 29 2024',
				description: 'BPO Tech Training for May 29, 2024',
				itemLink: `https://us06web.zoom.us/rec/share/mX_lMLCVmy9Qbf2Zklmh7-ftYPxMsnc1oXx8XCzskZAFIG8NhY-FQmT8BVVLT5F-.4BHg2Xn648WSTm-A`,
				all: true,
			},

			{
				title: 'BPO Tech Training- May 24 2024',
				description: 'BPO Tech Training for May 24, 2024',
				itemLink: `https://us06web.zoom.us/rec/share/EfdfNJ7-mocqHVB4pvryuD3WX4yEEOEkqzX5TLEWKRiI3ol6GKEPWTul-1nZiXM3.ClQZDtsjX7EyXfdx`,
				all: true,
			},
			{
				title: 'BPO Tech Training- May 22 2024',
				description: 'BPO Tech Training for May 22, 2024',
				itemLink: `https://us06web.zoom.us/rec/share/lctGRNQf0D06iEOGwM8V-I_-Xauzkfv6J-aLDYikmdh0lGR6TQoqP5-IJNzfRnak.mIYJ0bSNvTTT1sF3`,
				all: true,
			},
			{
				title: 'BPO Tech Training- May 17 2024',
				description: 'BPO Tech Training for May 17, 2024',
				itemLink: `https://us06web.zoom.us/rec/share/g2AGWxsUFoIjFuI29A0arD9aH9ylg5kKfLkVrPrzX0HFbv4GpGftBWVpFQNP5dj1.vVe4zLDM5NUiagcV`,
				all: true,
			},
			{
				title: 'BPO Tech Training- May 15 2024',
				description: 'BPO Tech Training for May 15, 2024',
				itemLink: `https://us06web.zoom.us/rec/share/cvUfE3Q-dbsuYlvm47JFWcMEVbdOk34vjIZRS0Ku4Zu4B3vXJeq9FmvG_G0cyXTU.Hl5LNgkt-_uYmjRk`,
				all: true,
			},
			{
				title: 'BPO Tech Training- May 10 2024',
				description: 'BPO Tech Training for May 10, 2024',
				itemLink: `https://us06web.zoom.us/rec/share/RcK10-bDqGAycsFhIuUSYwygqJNRHWynWGermeQz0dkwZ8zwlciGzta1db96twlr.pAdZf4FhtAiaYM9D`,
				all: true,
			},
			{
				title: 'BPO Tech Training- May 8 2024',
				description: 'BPO Tech Training for May 8, 2024',
				itemLink: `https://us06web.zoom.us/rec/share/IxDlR2Q6d4Iv7uZ7Xa2cwRFTmf9oCnYQQCPd2qbCdfcdLjBatxtoT6aKnn8NaKMU.C3oGh62McZZtZASa`,
				all: true,
			},
			{
				title: 'BPO Tech Training- May 3 2024',
				description: 'BPO Tech Training for May 3, 2024',
				itemLink: `https://us06web.zoom.us/rec/share/1zcVRtQVKEcATaHYtkw-5dgFSrHjptA-UJerZEaIxikZauwoleV8iuTKGcSXgyU5.GHQtUcnRGMAsHKJR`,
				all: true,
			},
			{
				title: 'BPO Tech Training- May 1 2024',
				description: 'BPO Tech Training for May 1, 2024',
				itemLink: `https://us06web.zoom.us/rec/share/VQPYv3biduvFX5iHm4h7eLUu_t5JnKeVnDNbjxWMTv_VzoeU-98C15DEFJ2GfjUn.KQiknxR_ltN0sUf_`,
				all: true,
			},

			//for bpo homes
			{
				title: 'BPO Homes Training- May 23 2024',
				description: 'BPO Homes Training for May 23, 2024',
				itemLink: 'https://bpooperationsmain.s3.eu-west-2.amazonaws.com/agent_documents+/zoom_videos/bpohomes/BPOHOMES202405_23.mp4',
				all: false,
			},
			{
				title: 'BPO Homes Training- May 16 2024',
				description: 'BPO Homes Training for May 16, 2024',
				itemLink: 'https://bpooperationsmain.s3.eu-west-2.amazonaws.com/agent_documents+/zoom_videos/bpohomes/BPOHOMES202405_16.mp4',
				all: false,
			},
			{
				title: 'BPO Homes Training- May 9 2024',
				description: 'BPO Homes Training for May 9, 2024',
				itemLink: 'https://bpooperationsmain.s3.eu-west-2.amazonaws.com/agent_documents+/zoom_videos/bpohomes/BPOHOMES202405_09.mp4',
				all: false,
			},
			{
				title: 'BPO Homes Training- May 2 2024',
				description: 'BPO Homes Training for May 2, 2024',
				itemLink: 'https://bpooperationsmain.s3.eu-west-2.amazonaws.com/agent_documents+/zoom_videos/bpohomes/BPOHOMES202405_02.mp4',
				all: false,
			},
		],
	},
	{
		title: 'Documents Library',
		viewMoreTitle: 'All Documents',
		viewMoreLink: 'document',
		icon: <Description htmlColor='#FFFFFF4D' fontSize='large' />,
		items: [
			{
				title: 'Seller NOD Script & Options',
				description: 'Script for Seller NOD & Options',
				itemLink: 'https://bpooperationsmain.s3.eu-west-2.amazonaws.com/agent_documents+/Seller+NOD+Script+%26+Options.pdf',
				all: true,
			},
			{
				title: 'Divorce Script',
				description: 'Script for divorce',
				itemLink: 'https://bpooperationsmain.s3.eu-west-2.amazonaws.com/agent_documents+/Divorce+Script.pdf',
				all: true,
				canDownload: true,
			},
			{
				title: 'Expired Listing Script',
				description: 'Script for expired listing',
				itemLink: 'https://bpooperationsmain.s3.eu-west-2.amazonaws.com/agent_documents+/Expired+Listing+Script.pdf',
				all: true,
				canDownload: true,
			},
			{
				title: 'Name (Nosy Ass Neighbor) Script',
				description: 'Script for Nosy Ass Neighbor (Name)',
				itemLink: 'https://bpooperationsmain.s3.eu-west-2.amazonaws.com/agent_documents+/Name+(Nosy+Ass+Neighbor+Script).pdf',
				all: true,
				canDownload: true,
			},
			{
				title: 'Probate Script',
				description: 'Script for probate',
				itemLink: 'https://bpooperationsmain.s3.eu-west-2.amazonaws.com/agent_documents+/Probate+Scipt+.pdf',
				all: true,
				canDownload: true,
			},
		],
	},
];

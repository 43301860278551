import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
	typography: {
		fontFamily: 'Roboto',
		fontSize: 15,
	},
	palette: {
		primary: {
			main: '#FFAA01',
		},
		secondary: {
			main: '#005EA9',
		},
		text: {
			main: '#000',
		},
		paper: {
			main: '#fff',
		},
	},
	primaryColor: '#FFAA01',
	secondaryColor: '#005EA9',
	components: {
		MuiTypography: {
			styleOverrides: {
				root: {
					fontSize: 16,
				},
			},
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					'& .MuiOutlinedInput-root': {
						fontSize: 16,
					},
				},
			},
		},
	},
});

// let id = 1;
export const generateField = (len) => {
  let arr = [];
  // generate random forms based on names
  // use redux to dispatch their names and values
  // get values and save and use for whatever you want
  let i = 0;

  for (i; i < len; i++) {
    let fieldCreator = { name: `field${i}`, value: "" };
    arr.push(fieldCreator);
  }

  return arr;
};

export const multiplyArrays = (arr1 = [], arr2 = []) => {
  if (!arr1.length || !arr2.length) return;
  const indexMultiplications = arr1?.map((item, index) => {
    return { value: item?.value * arr2[index]?.value };
  });
  const totalSum = indexMultiplications?.reduce((a, b) => a + b?.value, 0);

  return { indexMultiplications, totalSum };
};
export const multiplyArraysRentalINcome = (
  arr1 = [],
  months,
  vacancy_months
) => {
  if (!arr1.length) return;
  const indexMultiplications = arr1?.map((item, index) => {
    return { value: item?.value * vacancy_months };
  });
  const totalSum = indexMultiplications?.reduce((a, b) => a + b?.value, 0);
  const indexMultiplicationsProfit = arr1?.map((item, index) => {
    return { value: item?.value * months };
  });
  const totalSumProfit = indexMultiplicationsProfit?.reduce(
    (a, b) => a + b?.value,
    0
  );

  return {
    indexMultiplications,
    totalSum,
    totalSumProfit,
    indexMultiplicationsProfit,
  };
};

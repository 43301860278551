import { Box, Button, FormControl, FormControlLabel, Grid, Stack, Switch, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import './templatescreen.scss';
// import { useNavigate } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import base from '../../apis';
import InfoIcon from '@mui/icons-material/Info';
import Spinner from '../../components/Spinner/Spinner';
import Thumb1 from '../../images/temp1thumb.webp';
import Thumb2 from '../../images/temp2thumb.webp';
import Thumb3 from '../../images/temp3thumb.webp';
import Thumb4 from '../../images/temp4thumb.webp';
import Thumb5 from '../../images/temp5thumb.webp';
import Thumb6 from '../../images/temp6thumb.webp';
import Thumb7 from '../../images/temp7thumb.webp';
import Thumb8 from '../../images/temp8thumb.webp';
import Thumb9 from '../../images/temp9thumb.webp';
import Thumb10 from '../../images/temp10thumb.webp';
import Thumb11 from '../../images/temp11thumb.webp';
import Thumb12 from '../../images/temp12thumb.jpg';
import Thumb13 from '../../images/temp13thumb.jpg';
import Thumb14 from '../../images/temp14thumb.jpg';

const templates = [
	{
		id: 1,
		image: Thumb1,
	},
	{
		id: 2,
		image: Thumb2,
	},
	{
		id: 3,
		image: Thumb3,
	},
	{
		id: 4,
		image: Thumb4,
	},
	{
		id: 5,
		image: Thumb5,
	},
	{
		id: 6,
		image: Thumb6,
	},
	{
		id: 7,
		image: Thumb7,
	},
	{
		id: 8,
		image: Thumb8,
	},
	{
		id: 9,
		image: Thumb9,
	},
	{
		id: 10,
		image: Thumb10,
	},
	{
		id: 11,
		image: Thumb11,
	},
	{
		id: 12,
		image: Thumb12,
	},
	{
		id: 13,
		image: Thumb13,
	},
	{
		id: 14,
		image: Thumb14,
	},
];

function TemplateScreen() {
	// const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const id = useSelector((state) => state.auth.typeId);
	const [selectedTemplate, setSelectedTemplate] = useState(null);
	const [landingText, setLandingText] = useState('');
	const [questionnaires_on, setQuestionnaires_on] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		base
			.get(`register/agents/${id}/`)
			.then(({ data }) => {
				setSelectedTemplate(data.selected_template);
				setLandingText(data.landing_page_title);
				setQuestionnaires_on(data.questionnaires_on);
			})
			.catch(() => {
				toast.error('Could not get agent profile');
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [id]);

	const patcher = async (name, value) => {
		return base.patch(`register/agents/${id}/`, {
			[name]: value,
		});
	};

	const setNewTemplate = (templateId) => {
		setIsLoading(true);
		patcher('selected_template', templateId)
			.then(() => {
				setSelectedTemplate(templateId);
				toast.success('Template updated');
			})
			.catch(() => {
				toast.error('Could not update template');
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const onSaveText = async () => {
		setIsLoading(true);
		try {
			await patcher('landing_page_title', landingText);
			toast.success('Landing page text updated successfully');
		} catch (error) {
			toast.error('Could not update landing page text');
		} finally {
			setIsLoading(false);
		}
	};

	const handleQuesChange = async (e, checked) => {
		setQuestionnaires_on(checked);
		try {
			await patcher('questionnaires_on', checked);
		} catch (error) {
			toast.error('Could not update investor option');
			setQuestionnaires_on(!checked);
		}
	};

	return (
		<>
			{isLoading && <Spinner />}
			<div className='templatescreen'>
				<Box display={'flex'} justifyContent='space-between' alignItems={'center'}>
					<Button sx={{ textTransform: 'capitalize' }} href='/premier-agent-website/website' color='secondary' variant='outlined'>
						Back
					</Button>
					<Button
						sx={{ textTransform: 'capitalize' }}
						onClick={() => {
							window.open(`/agent/${id}`);
						}}
						color='secondary'
						variant='contained'
					>
						Preview
					</Button>
				</Box>
				<Stack direction='row' justifyContent='space-between' my='1rem'>
					<Stack direction='row' gap='1rem' width={'400px'}>
						<TextField
							label='Landing page text'
							color='secondary'
							size='small'
							value={landingText}
							onChange={(e) => setLandingText(e.target.value)}
							sx={{ flex: 1 }}
							multiline
						/>
						<Button sx={{ textTransform: 'capitalize', height: 'fit-content' }} color='secondary' variant='contained' onClick={onSaveText}>
							Save
						</Button>
					</Stack>
					<FormControl>
						<FormControlLabel
							control={<Switch size='small' sx={{ fontSize: '14px' }} onChange={handleQuesChange} checked={questionnaires_on} />}
							labelPlacement='start'
							label={
								<span style={{ fontSize: '14px', marginRight: '1rem' }}>
									"Ready to thrive with Investors? Activate this feature to transform your website into a lead magnet for Investment opportunites"
								</span>
							}
						>
							text
						</FormControlLabel>
					</FormControl>
				</Stack>
				<Box sx={{ fontSize: '14px' }}>
					<InfoIcon color='secondary' fontSize='14px' sx={{ cursor: 'pointer' }} /> This text applies to templates <b>1,2,6,7,10,11</b>
				</Box>
				<h3>Choose Template</h3>
				<div className='templatescreen-preview'>
					<Grid container spacing={2}>
						{templates.map((template) => (
							<Grid item xs={3} key={template.id}>
								<Selector
									number={template.id}
									isSelected={template.id === selectedTemplate}
									onSetNewTemplate={() => setNewTemplate(template.id)}
									image={template.image}
								/>
							</Grid>
						))}
					</Grid>
				</div>
			</div>
		</>
	);
}

export default TemplateScreen;

const Selector = ({ number, isSelected, onSetNewTemplate, image }) => (
	<div className='templatescreen-preview-item' style={{ borderColor: isSelected ? 'var(--primary-color)' : '#e6e6e6' }} onClick={onSetNewTemplate}>
		<div>
			<span style={{ width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center', backgroundColor: '#0000005c' }}>Select</span>
			<img src={image} alt='template' style={{ width: '100%', objectFit: 'cover', height: '100%', borderBottom: '1px solid grey' }} />
		</div>
		<p>
			{number} {isSelected && <CheckCircleOutlineIcon color='secondary' fontSize='small' />}
		</p>
	</div>
);

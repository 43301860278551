import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  InputAdornment,
  FormControl,
  OutlinedInput,
  Typography,
  Grid,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import InvestorTable from "../atomicComponents/InvestorTable";
import StyledLabel from "../atomicComponents/StyledLabel";
import { useTheme } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import { greyout } from "../utils/greyout";
import commaSeparate from "../utils/commaSeparate";
import { useSelector, useDispatch } from "react-redux";
import { updateInvestorProfitItems } from "../../../actions/dealAnalyzer";
import { investorProfitMiddleware } from "../../../reducer/dealAnalyzerReducers/straightAestheticRemodelReducer";
import useSaveNow from "../hooks/useSaveNow";
import { monthToYearsTransformer } from "../localCalcutions/utils/dealAnalyzerUtilsFunctions";

//    import useSaveNow from "../hooks/useSaveNow";
import * as actionCreators from "../../../actions/dealAnalyzer/index";
import base from "../../../apis";
import { changeCodeToDealType } from "./EstimatedResaleValue/utils/EstimatedResaleUtils";
import { investorProfitCalcs } from "../localCalcutions/formulas/InvestorProfit";

const useStyles = (theme) => {
  return {
    root: {
      margin: "1.25rem",
    },
    inputContainer: {
      margin: "1.375rem 0",
      display: "flex",
      flexDirection: "column",
    },
    symbolsContainer: {
      display: "flex",
      borderRadius: "5px",
      marginLeft: "3.75rem",
      width: "9rem",
    },
    flatrate: {
      width: "4.5rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "white",
      cursor: "pointer",
      padding: "0.937rem",
      boxSizing: "border-box",
      backgroundColor: theme.palette.primary.main,
    },
    percentage: {
      width: "4.5rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      boxSizing: "border-box",
      border: `solid 1px ${theme.palette.primary.main}`,
    },
    flatRateActive: {
      display: "flex",
    },
    percentageActive: {
      display: "flex",
      flexDirection: "row-reverse",
    },
    currency: {
      display: "flex",
      alignItems: "center",
      margin: "1.875rem 0",
    },
  };
};
function InvestorProfit({ handleEvents, data }) {
  const theme = useTheme();
  const styles = useStyles(theme);
  const { saveNow } = useSaveNow();
  const [initialRenderFlag, setInitialRenderFlag] = useState();
  const rate = data?.investment_values_dollar_or_percent
    ? +data.investment_values_dollar_or_percent
    : 0;
  const analyzerData = useSelector((state) => state?.straightAestheticRemodel);
  const resultData = analyzerData?.dealAnalyzerResults;

  const investors = analyzerData?.investorProfitItems;
  const dispatch = useDispatch();
  const netProfit = resultData?.net_profit?.est_net_profit;
  const totalExpense =
    resultData?.total_liquid_capital_required?.total_liquid_capital;
  const onlineExpenseAmount =
    resultData?.returnedInvestorsProfit?.totalExpenseAmount;
  const onlineTotalLiquidRequired =
    resultData?.returnedInvestorsProfit?.totalLiquidRequired;
  const onlineEstNetProfit = resultData?.returnedInvestorsProfit?.estNetProfit;
  const financialOptions = analyzerData?.financeOptions;

  const timeInYear =
    analyzerData?.carryingMonths?.carrying_cost_owning_months || 0;
  const timeInYearChecker =
    analyzerData?.carryingMonths.carrying_cost_owning_months_month_or_year || 0;

  const dealtype = +financialOptions.deal_finance_option;

  const { analyzerTypeId, id } = analyzerData.dealAnalyzerReportId;
  const { investorProfitItems } = analyzerData;

  const prevEquityOnly = investorProfitItems?.map(
    (investor) => +investor?.equity
  );
  const prevProfitOnly = investorProfitItems?.map(
    (investor) => +investor?.profit
  );

  const [numberOfFields, setNumberOfFields] = useState();
  const [inputValues, setInputValues] = useState(
    prevEquityOnly || Array(numberOfFields).fill(0)
  );
  const [inputNetProfit, setInputNetProfit] = useState(
    prevProfitOnly || Array(numberOfFields).fill(0)
  );

  const investorsCalculations = useMemo(
    () =>
      investorProfitCalcs({
        remodelCosts: analyzerData.remodelCosts,
        financeOptions: analyzerData.financeOptions,
        resultData,
        dealId: analyzerData.dealAnalyzerReportId.analyzerTypeId,
        propertyPermitAndFees: analyzerData?.propertyPermitAndFees,
        taxes: analyzerData.taxes,
        rentalIncome: analyzerData.rentalIncome,
        propertyManagement: analyzerData.propertyManagement,
        otherCosts: analyzerData?.otherCosts,
        carryingMonths: analyzerData?.carryingMonths,
        estimatedResaleValue: analyzerData?.estimatedResaleValue,
        dealAnalyzerResults: analyzerData?.dealAnalyzerResults,
        closingCosts: analyzerData?.closingCosts,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      analyzerData.remodelCosts,
      analyzerData.financeOptions,
      analyzerData?.propertyPermitAndFees,
      analyzerData.taxes,
      analyzerData.rentalIncome,
      analyzerData.propertyManagement,
      analyzerData?.otherCosts,
      analyzerData?.carryingMonths,
      analyzerData?.estimatedResaleValue,
      analyzerData?.closingCosts,
    ]
  );

  const renderNetProfit =
    +investorsCalculations?.localInvestorsProfit.toFixed(2) ||
    onlineEstNetProfit;

  //====================xxx======x=x=x=========== investors netProfit ==================x=x=x=xx=====//

  const handleRates = (rate) => {
    handleEvents({ investment_values_dollar_or_percent: rate });
  };
  const handleAddInvestor = (reset = false) => {
    const newValue = { investor: "", equity: 0, profit: 0 };
    dispatch(updateInvestorProfitItems([...investors, newValue]));
  };
  const splitIntoRates = (dataIn) => {
    //console.log(dataIn)
    if (dataIn) {
      const percent = dataIn?.map((element) => ({
        investor: element?.investor,
        roi: element?.roi, //roi_in_percent
        roi_a: element?.roi_a, //annualized_roi_percent
        equity: element?.equity, //equity_in_percent
        profit: element?.profit, //profit_in_percent
      }));
      return percent;
    }
  };

  const filterValues = (e) => {
    let value = e.target.value;
    if (value) {
      value = Number.parseInt(value.split(",").join(""));
      e.target.value = value;
    }
    return e;
  };

  const convertToPercent = (amount, ref) => {
    return amount && ref ? ((amount / ref) * 100).toFixed(2) : 0;
  };
  const convertToAmount = (percent, ref) => {
    return percent && ref ? +((+percent / 100) * ref).toFixed(2) : 0;
  };

  const getRoiWithPercent = (investorArrIn) => {
    const investorArray = getAnnualizedRoi([...investorArrIn]);

    investorArray.forEach((investor) => {
      const investment = +investor?.equity;
      const profit = +investor?.profit;
      if (!profit || !+totalExpense || !renderNetProfit) {
        investor.roi = 0;
      } else {
        const amtInvested =
          (+investment / 100) *
          (investorsCalculations?.totalLiquidRequired || totalExpense);
        const amtReturned = (+profit / 100) * renderNetProfit;

        // const roi = ((amtReturned-amtInvested)/amtInvested)*100
        // ROI - 236.23% ( 60% LCC / Estimated Net Profit * 100  )
        const roi = (amtReturned / amtInvested) * 100;
        investor.roi = roi.toFixed(2);
      }
    });
    dispatch(updateInvestorProfitItems(investorArray));
  };

  const getAnnualizedRoi = (investorArrIn) => {
    const years = +monthToYearsTransformer(
      timeInYear,
      timeInYearChecker
    ).toFixed(2);

    const investorArray = [...investorArrIn];

    investorArray.forEach((investor) => {
      const investment = +investor?.equity;
      const profit = +investor?.profit;

      if (!investment || !profit) {
        investor.roi_a = 0;
      } else {
        const amtInvested =
          (+investment / 100) *
          (investorsCalculations?.totalLiquidRequired || totalExpense);
        const amtReturned = (+profit / 100) * renderNetProfit;

        //  const profit_per_month = amtReturned / years
        const x = +(amtReturned / amtInvested).toFixed(2);
        const y = +(1 / years).toFixed(2);
        const z = Math.abs(x);

        let CAGR;
        if (x < 0) {
          CAGR = Math.pow(z, y) * -1;
        } else {
          CAGR = Math.pow(z, y);
        }

        const roi_a = CAGR - 1;
        investor.roi_a = roi_a.toFixed(2);
      }
    });
    return investorArray;
  };

  // check for  interaction with the input field
  // if interacted then change the vals percentage

  const handleUpdateInvestor = (e, index, filter = false) => {
    const name = e.target.name;
    let value = e.target.value;

    if ((name === "profit" || name === "equity") && !+rate) {
      filterValues(e);

      if (isNaN(Number.parseInt(value))) {
        e.value = 0;
      } else {
        const newValue =
          name === "profit"
            ? convertToPercent(+value, +netProfit)
            : convertToPercent(+inputValues[index], +totalExpense);
        e.value = newValue;
      }
    } else if ((name === "profit" || name === "equity") && +rate) {
      filterValues(e);
      if (isNaN(Number.parseInt(value))) {
        e.target.value = 0;
      } else {
        e.value = Number.parseInt(value);
      }
    }
    let newInvestors;
    newInvestors = investors.map((el, i) =>
      i === index ? { ...el, [e.target.name]: e.target.value } : el
    );

    getRoiWithPercent(newInvestors);
  };

  const handleDeleteRow = (deleteIndex) => {
    let newInvestors;
    newInvestors = investors.filter((element, index) => index !== deleteIndex);
    getRoiWithPercent(newInvestors);
  };

  const handleRemoveInvestor = () => {
    const temp = investors.slice(0, investors?.length - 1);
    dispatch(updateInvestorProfitItems(temp));
  };

  //updates redux with previous value from server
  useEffect(() => {
    getProfit();
    const beforeLoad = async () => {
      const investorList =
        resultData?.returnedInvestorsProfit?.investors_profit;
      //  console.log(splitIntoRates(investorList))
      dispatch(updateInvestorProfitItems(splitIntoRates(investorList) || []));
    };
    beforeLoad();
    handleRates(1);
    // NOTE: Run effect once on component mount, please
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!initialRenderFlag) {
      saveNow({
        investor_profit: investorProfitMiddleware({
          ...analyzerData.investorProfit,
          investment_values: analyzerData.investorProfitItems,
        }),
      });
      submitProfit();
    }

    initialRenderFlag && setInitialRenderFlag(false);
    // NOTE: Run effect once on component mount, please
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyzerData.investorProfit, analyzerData.investorProfitItems]);

  //==============================================network put and get ==================================================================//

  //posting user profit to server
  const submitProfit = async () => {
    const investorsData = {
      investors_profit: investorProfitItems,
      totalLiquidRequired: investorsCalculations?.totalLiquidRequired,
      totalExpenseAmount: investorsCalculations?.totalExpenseAmount,
      estNetProfit: investorsCalculations?.localInvestorsProfit,
      dollar_percent_checker: 0,
    };
    const dealType = changeCodeToDealType(analyzerTypeId);
    try {
      await base.put(
        `deal_analyzer/expenses?model_type=${dealType}&model_id=${id}`,
        investorsData
      );
    } catch (error) {
      //  toast.error("Drat! Our saving magic fizzled out. Try saving once more, pretty please!")
    }
  };

  //  submitProfit()
  const getProfit = async () => {
    const dealType = changeCodeToDealType(analyzerTypeId);
    try {
      const response = await base.get(
        `deal_analyzer/expenses?model_type=${dealType}&model_id=${id}`
      );
      const { data } = response;
      dispatch(
        actionCreators.updateDealAnalyzerResults({
          returnedInvestorsProfit: data,
        })
      );
    } catch (error) {}
  };

  useEffect(() => {
    if (!dealtype || !id) return;
    getProfit();
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealtype, id]);

  //================xxxxxxxxxxxxxx===============network put and get ===================================xxxxxxxxxxxxxxx===============================//

  return (
    <Box sx={styles.root}>
      <Grid container columns={24}>
        <Grid item sm={24} md={11}>
          <StyledLabel label="Total Liquid Capital Required" />

          <FormControl sx={{ width: "100%" }}>
            <OutlinedInput
              fullWidth
              margin="normal"
              placeholder="0"
              renderValue={(value) => value}
              inputProps={{ style: { fontSize: "1rem" } }}
              sx={{ ...greyout }}
              value={commaSeparate(
                investorsCalculations?.totalLiquidRequired ||
                  onlineTotalLiquidRequired
              )}
              startAdornment={
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="adornment" color="secondary">
                    {" "}
                    $
                  </Typography>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        <Grid item sm={0} md={2} />

        <Grid item sm={24} md={11}>
          <StyledLabel label="Total Expenses" />
          <FormControl sx={{ width: "100%" }}>
            <OutlinedInput
              fullWidth
              margin="normal"
              placeholder="0"
              renderValue={(value) => value}
              inputProps={{ style: { fontSize: "1rem" } }}
              sx={{ ...greyout }}
              value={commaSeparate(
                investorsCalculations?.totalExpenseAmount?.toFixed(2) ||
                  onlineExpenseAmount
              )}
              startAdornment={
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="adornment" color="secondary">
                    {" "}
                    $
                  </Typography>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
      </Grid>

      <Box sx={styles.inputContainer}>
        <StyledLabel label="Estimated Net Profit" />
        <FormControl sx={{ width: "100%" }}>
          <OutlinedInput
            fullWidth
            margin="normal"
            placeholder="0"
            renderValue={(value) => value}
            inputProps={{ style: { fontSize: "1rem" } }}
            sx={{ ...greyout }}
            value={commaSeparate(renderNetProfit)}
            startAdornment={
              <InputAdornment position="start">
                {" "}
                <Typography variant="adornment" color="secondary">
                  {" "}
                  $
                </Typography>
              </InputAdornment>
            }
          />
        </FormControl>
      </Box>

      <Box sx={styles.currency}>
        <StyledLabel label="Investment Values" />
        <Box sx={styles.symbolsContainer}>
          <Box
            sx={
              rate === 0
                ? { ...styles.flatrate, borderRadius: "5px 0 0 5px " }
                : { ...styles.percentage, borderRadius: "5px 0 0 5px " }
            }
            onClick={() => handleRates(0)}
          >
            <Typography variant="inputLabelFont">$</Typography>
          </Box>
          <Box
            sx={
              rate === 0
                ? { ...styles.percentage, borderRadius: "0 5px 5px 0" }
                : { ...styles.flatrate, borderRadius: "0 5px 5px 0" }
            }
            onClick={() => handleRates(1)}
          >
            <Typography variant="inputLabelFont">%</Typography>
          </Box>
        </Box>
      </Box>

      <InvestorTable
        investors={investors}
        rate={rate}
        updateTable={handleUpdateInvestor}
        netProfit={netProfit}
        totalExpense={totalExpense}
        convertToAmount={convertToAmount}
        convertToPercent={convertToPercent}
        handleDeleteRow={handleDeleteRow}
        handleEvents={handleEvents}
        setNumberOfFields={setNumberOfFields}
        setInputValues={setInputValues}
        numberOfFields={numberOfFields}
        inputValues={inputValues}
        setInputNetProfit={setInputNetProfit}
        inputNetProfit={inputNetProfit}
      />

      <Box sx={{ height: "1.875rem" }} />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <AddIcon
            sx={{ color: "#1378A5", fontSize: "1.875rem", cursor: "pointer" }}
            onClick={handleAddInvestor}
          />
          <Typography
            variant="inputLabelFont"
            sx={{ color: "#1378A5", marginLeft: "10px", cursor: "pointer" }}
            onClick={handleAddInvestor}
          >
            Add An Investor
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <DeleteIcon
            sx={{ color: "#1378A5", fontSize: "1.875rem", cursor: "pointer" }}
            onClick={handleRemoveInvestor}
          />
          <Typography
            variant="inputLabelFont"
            sx={{ color: "#1378A5", marginLeft: "10px", cursor: "pointer" }}
            onClick={handleRemoveInvestor}
          >
            Delete Recent Investor
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{ borderBottom: "solid 5px #1378A5", marginTop: "1.875rem" }}
      ></Box>
    </Box>
  );
}

export default InvestorProfit;

import React, { useEffect, useMemo, useState } from "react";
import useTheme from "@mui/material/styles/useTheme";
import StyledLabel from "../../atomicComponents/StyledLabel";
import {
  FormControl,
  OutlinedInput,
  Typography,
  Box,
  InputAdornment,
  Grid,
  Divider,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import useProformaData from "../../hooks/dealReporthooks/useProformaData";
import { greyout } from "../../utils/greyout";
import commaSeparate from "../../utils/commaSeparate";
import EnhancedRadioButtonGroup from "../../atomicComponents/EnhancedRadioButtonGroup";
import { remodelCostOptions } from "./constants";
import { getCostPsqftFromAdvancedRemodelCost } from "./remodelCostUtils/getCostPerSqft";
import EditIcon from "@mui/icons-material/Edit";
import RegularToolTip from "../../atomicComponents/RegularToolTip";
import {
  estRemodelCostDownToStudPerSqrfCalculator,
  isNaNChecker,
} from "../../localCalcutions/utils/genericFunctions";
import * as actionCreators from "../../../..//actions/dealAnalyzer/index";
import { preventMinus } from "../../utils/preventNegativeValues";
import { generateField, multiplyArrays } from "../../utils/GenerateInputField";

const useStyles = (_theme) => {
  return {
    root: {},
    inputContainer: {
      display: "flex",
      flexDirection: "column",
      marginTop: "5px",
    },
    inputContainerSingle: {
      display: "flex",
      flexDirection: "column",
      marginTop: "1rem",
    },
    underInput: {
      "&:hover": {
        borderBottom: "solid",
      },
      cursor: "pointer",
    },
    bottomLabel: {
      marginTop: "5px",
      display: "flex",
      alignItems: "center",
      padding: "5px 0",
    },
    firstRow: {
      display: "flex",
      justifyContent: "space-between",
    },
    dollarInputs: {
      display: "flex",
      flexDirection: "column",
      marginTop: "1.125rem",
    },
  };
};

// console.log("mainCardArt2");

function RemodelMainCardAlt2({
  handleOpenDialog,
  handleSubMenu,
  handler,
  data,
}) {
  const theme = useTheme();
  const styles = useStyles(theme);
  const { resultData } = useProformaData();
  const analyzerData = useSelector((state) => state?.straightAestheticRemodel);
  const isAdvanced = +analyzerData?.remodelCosts?.remodel_cost_option;
  const preExistingSquareFootage =
    analyzerData?.propertyInformation?.pre_existing_livable_sqft;
  const additionalSquareFootage =
    analyzerData?.propertyInformation?.additional_sqrft;
  const aestheticItems = analyzerData?.remodelCosts?.aesthetic_items || [];
  const nonAestheticItems =
    analyzerData?.remodelCosts?.non_aesthetic_items || [];
  const additionalItems =
    analyzerData?.remodelCosts?.additional_remodel_items || [];

  const dispatch = useDispatch();

  const {
    estimatedRemodelCostPsqft,
    estimatedNewConstructionCostPsqft,
    remodelCost,
    newConstructionCost,
    estimatedTotalRemodelWithAdditional,
  } =
    getCostPsqftFromAdvancedRemodelCost({
      preExistingSquareFootage,
      aestheticItems,
      nonAestheticItems,
      additionalItems,
      additionalSquareFootage,
    }) || {};
  let advancedRemodelTimer;
  const handleChange = (e) => {
    handler({ [e.target.name]: e.target.value });
  };
  const specialHandler = (e) => {
    handler({ [e.target.name]: e.target.value });
    if (+e.target.value === 1) {
      advancedRemodelTimer = setTimeout(() => {
        handleSubMenu(1);
      }, 1000);
    }
  };
  useEffect(() => {
    !data?.remodel_cost_option && handler({ remodel_cost_option: "0" });
    return () => {
      advancedRemodelTimer && clearTimeout(advancedRemodelTimer);
    };
    // NOTE: Run effect once on component mount, please
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const propertyInfo = useSelector(
    (state) => state?.straightAestheticRemodel?.propertyInformation
  );

  const generateNewForm = useMemo(() => {
    return generateField(propertyInfo?.additional_units?.length);
  }, [propertyInfo?.additional_units]);

  const formsWithValues =
    data?.additional_units_price?.length === generateNewForm?.length
      ? data?.additional_units_price
      : undefined;

  // empty prevPrices if unit length changes
  useEffect(() => {
    if (!formsWithValues) {
      handler({ additional_units_price: [] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // generate fields for additional sqf added
  const [additionalUnitArray, setAdditionalUnitArray] = useState(
    formsWithValues || generateNewForm
  );

  const componentDynamicDataFilter = (index, event) => {
    let data = [...additionalUnitArray];

    data[index][event.target.name] = event.target.value;
    setAdditionalUnitArray(data);
    handler({ additional_units_price: data });
    // handleEvents({ [e.target.name]: e.target.value });
  };

  const unitCostResult = useMemo(() => {
    return multiplyArrays(
      propertyInfo?.additional_units,
      data?.additional_units_price
    );
  }, [propertyInfo.additional_units, data?.additional_units_price]);

  //==================================== local calculations ===================================//
  const preExistingLivableSquareFootage =
    +analyzerData?.propertyInformation?.pre_existing_livable_sqft;
  const localAdditionalSquareFootage =
    +analyzerData?.propertyInformation?.additional_sqrft;
  const localRemodeDownToStudsCostSqft =
    +analyzerData?.remodelCosts?.est_remodel_cost_psqft;
  const localAdditionalCostSqft =
    +analyzerData?.remodelCosts?.est_new_construction_cost_psqft;
  const savedResultData = analyzerData?.dealAnalyzerResults;
  // gets cost amount from redux
  const preEstLivingSquarePrice =
    savedResultData?.remodel_cost?.est_remodel_cost_psqft;
  // const remodelCostOptionSelected = analyzerData.remodelCosts.remodel_cost_option
  const localAdditionalSquareFootagePrice =
    savedResultData?.remodel_cost?.est_new_construction_cost_psqft;

  const returnedRemodelCostAmount = isNaN(
    +data?.[remodelMainCardStaticData.input1.name]
  )
    ? preEstLivingSquarePrice
    : data?.[remodelMainCardStaticData.input1.name];
  const returnlocalAdditionalSquareFootagePrice = isNaN(
    +data?.[remodelMainCardStaticData.input1B.name]
  )
    ? localAdditionalSquareFootagePrice
    : data?.[remodelMainCardStaticData.input1B.name];

  const estRemodelCostDownToStuds = useMemo(() => {
    const value = estRemodelCostDownToStudPerSqrfCalculator(
      localRemodeDownToStudsCostSqft,
      localAdditionalCostSqft
    );
    return isNaNChecker(value);

    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localRemodeDownToStudsCostSqft, localAdditionalCostSqft]);

  const estTotalRemodelCostDownToStuds = useMemo(() => {
    const result = preExistingLivableSquareFootage * estRemodelCostDownToStuds;
    return isNaNChecker(result);

    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preExistingLivableSquareFootage, estRemodelCostDownToStuds]);

  const estTotalAdditionalSqFtCost = useMemo(() => {
    const result = localAdditionalSquareFootage * localAdditionalCostSqft;
    return isNaNChecker(result);
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localAdditionalSquareFootage, localAdditionalCostSqft]);

  const totalAmountPaid =
    estTotalAdditionalSqFtCost + estTotalRemodelCostDownToStuds ||
    resultData?.remodel_cost?.[
      "est_remodel_cost_down__to_studs_plus_additional_sqft"
    ];

  const simpleRemodelTotal = useMemo(() => {
    const res =
      +totalAmountPaid +
      (unitCostResult?.totalSum || 0) +
      (data?.adu_price * propertyInfo?.adu || 0) +
      (data?.basement_price * propertyInfo?.basement_sqrft || 0);
    const localTotalRemodelCost = { remodelCost: res };

    dispatch(actionCreators.updateRemodelCosts(localTotalRemodelCost));
    return isNaNChecker(res);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    unitCostResult?.totalSum,
    data?.adu_price,
    propertyInfo?.adu,
    propertyInfo?.basement_sqrft,
    totalAmountPaid,
    data?.basement_price,
  ]);

  useEffect(() => {
    dispatch(
      actionCreators.updateFinanceOptions({
        advancedRemodelCost: remodelCost,
      })
    );
    // NOTE: Run effect once on component mount, please
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remodelCost]);
  //shares the Remodel DownToStuds and Addition Data

  useEffect(() => {
    dispatch(
      actionCreators.updateFinanceOptions({
        totalAmountPaidRemodelDownToStudsAddition: simpleRemodelTotal,
      })
    );
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [simpleRemodelTotal]);

  const remodelDownToStuds =
    estRemodelCostDownToStuds ||
    resultData?.remodel_cost?.est_remodel_cost_down_to_studs_psqft;
  const estTotalRemodelCost =
    estTotalRemodelCostDownToStuds ||
    resultData?.remodel_cost?.est_total_remodel_cost_down_to_studs;
  const totalAdditionalCost =
    estTotalAdditionalSqFtCost ||
    resultData?.remodel_cost?.est_total_additional_sqft_cost;

  //==========xxx=xxx=xxx======================== local calculations ========xxxx=xx=xxxx=xx========================//
  // Remodel Down to Studs & Add Addition
  useEffect(() => {
    const grandTotalRemodelCost = isAdvanced
      ? estimatedTotalRemodelWithAdditional
      : simpleRemodelTotal;

    handler({ grandTotalRemodelCost });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estimatedTotalRemodelWithAdditional, simpleRemodelTotal]);

  return (
    <>
      <StyledLabel label="Choose a Remodel Cost Calculator 1" />
      <EnhancedRadioButtonGroup
        options={remodelCostOptions}
        name="remodel_cost_option"
        choice={data?.remodel_cost_option}
        specialHandler={specialHandler}
        styles={{ display: "flex" }}
      />
      <Divider
        light={true}
        // variant="middle"
        sx={{
          height: "1px",
          mt: -2,
          mb: 2,
          backgroundColor: "#C4c4c4",
        }}
      />
      <Box>
        <Grid container columns={24}>
          <Grid item sm={24} md={12}>
            <StyledLabel label={remodelMainCardStaticData.input1.label} />
            <FormControl sx={{ width: "100%" }}>
              <OutlinedInput
                fullWidth
                margin="normal"
                name={remodelMainCardStaticData.input1.name}
                onChange={isAdvanced ? "" : handleChange}
                value={
                  isAdvanced
                    ? estimatedRemodelCostPsqft
                    : returnedRemodelCostAmount
                }
                sx={isAdvanced ? greyout : {}}
                placeholder="0"
                onKeyPress={preventMinus}
                renderValue={(value) => value}
                inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                type="number"
                startAdornment={
                  <InputAdornment position="start">
                    {" "}
                    <Typography variant="adornment" color="secondary">
                      {" "}
                      $
                    </Typography>
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="start">
                    {" "}
                    <Typography variant="adornment" color="secondary">
                      {" "}
                      /SqFt
                    </Typography>
                  </InputAdornment>
                }
              />
            </FormControl>
            {!isAdvanced && (
              <Box sx={styles.bottomLabel}>
                <Typography
                  variant="h6"
                  color="primary"
                  sx={styles.underInput}
                  onClick={() => handleOpenDialog("remodel_cost")}
                >
                  {remodelMainCardStaticData.input1.lowerLabel}
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid item sm={0} md={1} />
          <Grid item sm={24} md={11}>
            <StyledLabel label={remodelMainCardStaticData.input1B.label} />
            <FormControl sx={{ width: "100%" }}>
              <OutlinedInput
                fullWidth
                margin="normal"
                name={remodelMainCardStaticData.input1B.name}
                sx={isAdvanced ? greyout : {}}
                onChange={isAdvanced ? "" : handleChange}
                value={
                  isAdvanced
                    ? estimatedNewConstructionCostPsqft
                    : returnlocalAdditionalSquareFootagePrice
                }
                placeholder="0"
                onKeyPress={preventMinus}
                renderValue={(value) => value}
                inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                type="number"
                startAdornment={
                  <InputAdornment position="start">
                    {" "}
                    <Typography variant="adornment" color="secondary">
                      {" "}
                      $
                    </Typography>
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="start">
                    {" "}
                    <Typography variant="adornment" color="secondary">
                      {" "}
                      /SqFt
                    </Typography>
                  </InputAdornment>
                }
              />
            </FormControl>
            {!isAdvanced && (
              <Box sx={styles.bottomLabel}>
                <Typography
                  variant="h6"
                  color="primary"
                  sx={styles.underInput}
                  onClick={() => handleOpenDialog("new_construction")}
                >
                  {remodelMainCardStaticData.input1.lowerLabel}
                </Typography>
              </Box>
            )}
            <Box sx={{ mt: 1 }} />
          </Grid>
        </Grid>
        {!!propertyInfo?.adu && (
          <Box sx={styles.inputContainer}>
            <StyledLabel label={"Accessory Dwelling Unit SqFt Cost"} />
            <FormControl sx={{ minWidth: "18.75rem" }}>
              <OutlinedInput
                fullWidth
                margin="normal"
                name={"adu_price"}
                onKeyPress={preventMinus}
                onChange={isAdvanced ? "" : handleChange}
                value={data?.adu_price}
                placeholder="0"
                renderValue={(value) => value}
                inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                type="number"
                sx={isAdvanced ? greyout : {}}
                startAdornment={
                  <InputAdornment position="start">
                    {" "}
                    <Typography variant="adornment" color="secondary">
                      {" "}
                      $
                    </Typography>
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="start">
                    {" "}
                    <Typography variant="adornment" color="secondary">
                      {" "}
                      /SqFt
                    </Typography>
                  </InputAdornment>
                }
              />
            </FormControl>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "0.5rem",
              }}
            >
              {!isAdvanced &&
                additionalUnitArray?.map((item, index) => (
                  <FormControl sx={{ width: "45%", mt: "1rem" }} key={index}>
                    <StyledLabel
                      label={`Unit ${index + 2}`}
                      showToolTip
                      // toolTipContent={toolTipTexts.bedrooms}
                    />

                    <OutlinedInput
                      name={"value"}
                      onKeyPress={preventMinus}
                      type="number"
                      onChange={(event) =>
                        componentDynamicDataFilter(index, event)
                      }
                      value={item?.value}
                      inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                      sx={{ fontSize: "16px" }}
                      endAdornment={
                        <InputAdornment position="start">
                          {" "}
                          <Typography variant="adornment"> SqFt</Typography>
                        </InputAdornment>
                      }
                    ></OutlinedInput>
                  </FormControl>
                ))}
            </Box>
          </Box>
        )}
        {!!propertyInfo?.basement_sqrft && (
          <Box sx={styles.inputContainer}>
            <StyledLabel label={"Basement Unit SqFt Cost"} />
            <FormControl sx={{ minWidth: "18.75rem" }}>
              <OutlinedInput
                fullWidth
                margin="normal"
                name={"basement_price"}
                onKeyPress={preventMinus}
                onChange={isAdvanced ? "" : handleChange}
                value={data?.basement_price}
                placeholder="0"
                renderValue={(value) => value}
                inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                type="number"
                sx={isAdvanced ? greyout : {}}
                startAdornment={
                  <InputAdornment position="start">
                    {" "}
                    <Typography variant="adornment" color="secondary">
                      {" "}
                      $
                    </Typography>
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="start">
                    {" "}
                    <Typography variant="adornment" color="secondary">
                      {" "}
                      /SqFt
                    </Typography>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "0.5rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "0.5rem",
            }}
          >
            {!!propertyInfo?.adu && (
              <Box display={"flex"} gap={"4rem"}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    my: "2rem",
                  }}
                >
                  <StyledLabel label={"Accessory Dwelling Unit SqFt"} />
                  <FormControl sx={{ minWidth: "18.75rem" }}>
                    <OutlinedInput
                      fullWidth
                      margin="normal"
                      name={"adu"}
                      onChange={handleChange}
                      value={propertyInfo?.adu || 0}
                      sx={greyout}
                      placeholder="0"
                      onKeyPress={preventMinus}
                      renderValue={(value) => value}
                      inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                      type="number"
                      endAdornment={
                        <InputAdornment position="start">
                          {" "}
                          <Typography variant="adornment" color="secondary">
                            {" "}
                            SqFt
                          </Typography>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    my: "2rem",
                  }}
                >
                  <StyledLabel label={"Accs Dwelling Unit SqFt  Total Cost"} />
                  <FormControl sx={{ minWidth: "18.75rem" }}>
                    <OutlinedInput
                      fullWidth
                      margin="normal"
                      name={"adu"}
                      onChange={handleChange}
                      value={data?.adu_price * propertyInfo?.adu || 0}
                      sx={greyout}
                      placeholder="0"
                      onKeyPress={preventMinus}
                      renderValue={(value) => value}
                      inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                      type="number"
                      endAdornment={
                        <InputAdornment position="start">
                          {" "}
                          <Typography variant="adornment" color="secondary">
                            {" "}
                            SqFt
                          </Typography>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Box>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "0.5rem",
            }}
          >
            {!!propertyInfo?.basement_sqrft && (
              <Box display={"flex"} gap={"4rem"}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    my: "2rem",
                  }}
                >
                  <StyledLabel label={"Basement Unit SqFt"} />
                  <FormControl sx={{ minWidth: "18.75rem" }}>
                    <OutlinedInput
                      fullWidth
                      margin="normal"
                      name={"adu"}
                      onChange={handleChange}
                      value={propertyInfo?.basement_sqrft || 0}
                      sx={greyout}
                      placeholder="0"
                      onKeyPress={preventMinus}
                      renderValue={(value) => value}
                      inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                      type="number"
                      endAdornment={
                        <InputAdornment position="start">
                          {" "}
                          <Typography variant="adornment" color="secondary">
                            {" "}
                            SqFt
                          </Typography>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    my: "2rem",
                  }}
                >
                  <StyledLabel label={"Basement Unit SqFt  Total Cost"} />
                  <FormControl sx={{ minWidth: "18.75rem" }}>
                    <OutlinedInput
                      fullWidth
                      margin="normal"
                      name={"adu"}
                      onChange={handleChange}
                      value={
                        data?.basement_price * propertyInfo?.basement_sqrft || 0
                      }
                      sx={greyout}
                      placeholder="0"
                      onKeyPress={preventMinus}
                      renderValue={(value) => value}
                      inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                      type="number"
                      endAdornment={
                        <InputAdornment position="start">
                          {" "}
                          <Typography variant="adornment" color="secondary">
                            {" "}
                            SqFt
                          </Typography>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Box>
              </Box>
            )}
          </Box>
          {!isAdvanced &&
            additionalUnitArray.map((item, index) => (
              <FormControl sx={{ width: "45%", mt: "1rem" }} key={index}>
                <StyledLabel
                  label={`Unit ${index + 2}`}
                  showToolTip
                  // toolTipContent={toolTipTexts.bedrooms}
                />

                <OutlinedInput
                  name={"value"}
                  onKeyPress={preventMinus}
                  type="number"
                  onChange={(event) => componentDynamicDataFilter(index, event)}
                  value={item?.value}
                  inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                  sx={{ fontSize: "16px" }}
                  endAdornment={
                    <InputAdornment position="start">
                      {" "}
                      <Typography variant="adornment"> SqFt</Typography>
                    </InputAdornment>
                  }
                ></OutlinedInput>
              </FormControl>
            ))}
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          {unitCostResult?.indexMultiplications?.map((item, index) => (
            <Box sx={styles.inputContainer}>
              <StyledLabel label={`Unit ${index + 2} Remodel Cost`} />
              <FormControl sx={{ minWidth: "8.75rem" }}>
                <OutlinedInput
                  fullWidth
                  margin="normal"
                  name={remodelMainCardStaticData?.input2.name}
                  onChange={handleChange}
                  value={item?.value || 0}
                  sx={greyout}
                  placeholder="0"
                  onKeyPress={preventMinus}
                  renderValue={(value) => value}
                  inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                  type="number"
                  startAdornment={
                    <InputAdornment position="start">
                      {" "}
                      <Typography variant="adornment" color="secondary">
                        {" "}
                        $
                      </Typography>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Box>
          ))}
        </Box>
        <Box sx={styles.inputContainer}>
          <StyledLabel label={remodelMainCardStaticData?.input2.label} />
          <FormControl sx={{ width: "100%" }}>
            <OutlinedInput
              fullWidth
              margin="normal"
              name={remodelMainCardStaticData.input1.name}
              value={
                isAdvanced
                  ? commaSeparate((+remodelCost + +newConstructionCost) / 2)
                  : commaSeparate(remodelDownToStuds)
              }
              placeholder="0"
              onKeyPress={preventMinus}
              sx={{ ...greyout }}
              renderValue={(value) => value}
              inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
              startAdornment={
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="adornment" color="secondary">
                    {" "}
                    $
                  </Typography>
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="start">
                  {" "}
                  {/* <Typography variant="adornment" color="secondary">
                    {" "}
                    /SqFt
                  </Typography> */}
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>
        <Box sx={{ mt: 2 }} />
        <Grid container columns={24}>
          <Grid item sm={24} md={13}>
            <StyledLabel label={remodelMainCardStaticData?.input3A.label} />
            <FormControl sx={{ width: "100%" }}>
              <OutlinedInput
                fullWidth
                margin="normal"
                value={
                  isAdvanced
                    ? commaSeparate(remodelCost)
                    : commaSeparate(estTotalRemodelCost)
                }
                placeholder="0"
                onKeyPress={preventMinus}
                renderValue={(value) => value}
                inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                sx={{ ...greyout }}
                startAdornment={
                  <InputAdornment position="start">
                    {" "}
                    <Typography variant="adornment" color="secondary">
                      {" "}
                      $
                    </Typography>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item sm={0} md={1} />
          <Grid item sm={24} md={10}>
            <StyledLabel label={remodelMainCardStaticData?.input3B.label} />
            <FormControl sx={{ width: "100%" }}>
              <OutlinedInput
                fullWidth
                margin="normal"
                name={remodelMainCardStaticData?.input3B.name}
                value={
                  isAdvanced
                    ? commaSeparate(newConstructionCost)
                    : commaSeparate(totalAdditionalCost)
                }
                placeholder="0"
                onKeyPress={preventMinus}
                sx={{ ...greyout }}
                renderValue={(value) => value}
                inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                startAdornment={
                  <InputAdornment position="start">
                    {" "}
                    <Typography variant="adornment" color="secondary">
                      {" "}
                      $
                    </Typography>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
        </Grid>
        <Box sx={styles.inputContainerSingle}>
          <Box>
            <StyledLabel label={remodelMainCardStaticData?.input4.label} />
            <RegularToolTip content="Edit">
              <EditIcon
                sx={{
                  fontSize: "1.2rem",
                  mx: 3,
                  color: "#1378A5",
                  cursor: "pointer",
                }}
                onClick={() => handleSubMenu(1)}
              />
            </RegularToolTip>
          </Box>
          <FormControl sx={{ width: "100%" }}>
            <OutlinedInput
              fullWidth
              margin="normal"
              name={remodelMainCardStaticData?.input4.name}
              value={
                isAdvanced
                  ? commaSeparate(estimatedTotalRemodelWithAdditional)
                  : commaSeparate(simpleRemodelTotal)
              }
              sx={{ ...greyout }}
              placeholder="0"
              renderValue={(value) => value}
              inputProps={{ style: { fontSize: "1rem" } }}
              startAdornment={
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="adornment" color="secondary">
                    {" "}
                    $
                  </Typography>
                </InputAdornment>
              }
            />
          </FormControl>
          {/* <Box sx={styles.bottomLabel}>
            <Typography
              variant="notice"
              color="primary"
              sx={styles.underInput}
              onClick={() => handleSubMenu(1)}
            >
              {remodelMainCardStaticData.input4.lowerLabel}
            </Typography>
            <HtmlToolTip
              content={{
                text: "Add Items and their estimated dollar value individually",
              }}
            />
          </Box> */}
        </Box>
      </Box>
    </>
  );
}

export default RemodelMainCardAlt2;

const remodelMainCardStaticData = {
  input1: {
    label: "Remodel Down to Studs Cost P/Sqft",
    lowerLabel:
      "Get an estimated dollar per square foot from a Local Licensed Contractor",
    name: "est_remodel_cost_psqft",
  },
  input1B: {
    label: "Addition Cost P/Sqft",
    lowerLabel:
      "Get an estimated dollar per square foot from a Local Licensed Contractor",
    name: "est_new_construction_cost_psqft",
  },
  input2: {
    label: "Remodel Down to Studs Cost  ",
    name: "est_remodel_down_to_studs_psqft",
  },
  input3A: {
    label: "Est Total Remodel Cost Down To Studs ",
    name: "est_total_remodel_cost_down_to_studs",
  },
  input3B: {
    label: "Est Total Addition SqFt Cost",
    name: "est_total_additional_sqft_cost",
  },
  input4: {
    label: "Est Remodel Cost down to studs + Addition SqFt",
    lowerLabel: "Advanced Remodel Cost",
    name: "est_remodel_cost_down_to_studs_plus_additional_sqft",
  },
};

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './leftnavbar.scss';
import { toggleSidebar } from '../../actions';

import AgentLeftNav from './AgentLeftNav';
import ClientLeftNav from './ClientLeftNav';

const LeftNavBar = () => {
	const type = useSelector((state) => state.auth.role);
	const isOpen = useSelector((state) => !state.sidebar.isOpen);
	const dispatch = useDispatch();

	const closeOnMobile = () => {
		if (window.innerWidth <= 1200 && isOpen) {
			dispatch(toggleSidebar());
		}
	};

	return (
		<>
			{type === 'agent' || type === 'realty_agent' ? <AgentLeftNav onClose={closeOnMobile} /> : null}
			{type === 'client' && <ClientLeftNav onClose={closeOnMobile} />}
		</>
	);
};

export default LeftNavBar;

import { ArrowBack, Launch } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Grid, InputBase, Paper, Stack, ThemeProvider, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Logo from '../images/bpoVerticalLogo.webp';
import { theme } from '../theme';
import { GradientHolder, libraries } from './TrainingCenter';

import BG from '../images/gradientImage.jpg';

const newTheme = {
	...theme,
	typography: {
		allVariant: {
			fontFamily: 'Lato',
		},
		fontFamily: 'Lato, serif',
		fontSize: 16,
		button: {
			textTransform: 'capitalize',
			padding: '0 10px',
			fontFamily: 'Lato, serif',
		},
	},
};

//valid options- video, zoom, docs
const recordings = libraries[1].items;

const TrainingCenterZoom = ({ name }) => {
	const type = useSelector((state) => state.auth.role);

	const recordingsForYou = recordings.filter((item) => (type === 'realty_agent' ? true : item.all));
	const [sortedRecordings, setSortedRecordings] = useState(recordingsForYou);
	const [search, setSearch] = useState('');
	const handleSearch = (e) => {
		setSearch(e.target.value);
		setSortedRecordings((v) => recordingsForYou.filter((item) => item.title.toLowerCase().includes(e.target.value.toLowerCase())));
	};
	return (
		<ThemeProvider theme={newTheme}>
			<Stack gap='1rem' sx={{ backgroundColor: '#ffffff', height: '100%' }}>
				<Stack gap='1rem' sx={{ backgroundColor: '#F9F9FB', padding: '1rem' }}>
					<Stack direction='row' justifyContent={'space-between'}>
						<Stack direction={'row'} alignItems={'center'} gap='1rem'>
							<Button
								variant='text'
								startIcon={<ArrowBack />}
								color='secondary'
								sx={{ fontSize: '18px', fontWeight: 600 }}
								disableElevation
								LinkComponent={Link}
								to='..'
								disableRipple
							>
								Back
							</Button>
							<Typography sx={{ fontSize: 20, fontWeight: 700, fontFamily: 'Lato' }}>{name} Library</Typography>
						</Stack>
					</Stack>
					<Grid container spacing={2}>
						{recordingsForYou.slice(0, 3).map((rec) => (
							<Grid item xs={4} key={rec.itemLink}>
								<MainItem {...rec} />
							</Grid>
						))}
					</Grid>
				</Stack>
			</Stack>
			<Stack p='1rem' gap='1rem' mt='3rem'>
				<Stack justifyContent='space-between' direction='row'>
					<Typography color='#005EA9' fontWeight={700} fontSize={20} textAlign='left' sx={{ fontStyle: 'italic', fontFamily: 'Lato' }}>
						More from {name} Library
					</Typography>
					<TextField placeholder={'Search by title'} style={{ width: 400 }} onChange={handleSearch} value={search} />
				</Stack>
				<Grid container spacing={2}>
					{sortedRecordings.map((video) => (
						<Grid item xs={4} key={video.link}>
							<LibraryItem {...video} />
						</Grid>
					))}
				</Grid>
			</Stack>
		</ThemeProvider>
	);
};

export default TrainingCenterZoom;

const LibraryItem = ({ title, description, icon, link, itemLink }) => {
	const linkItem = link || itemLink;
	const isZoomLink = itemLink.includes('us06web.zoom.us');
	return (
		<Stack direction='column' sx={{ height: '100%' }} gap='1rem'>
			{isZoomLink ? (
				<GradientHolder component='a' href={itemLink} target='_blank' title='Click to watch'>
					{icon}
				</GradientHolder>
			) : linkItem.includes('youtube') ? (
				<iframe
					id={linkItem}
					style={{
						borderRadius: '5px',
						border: 'none',
						width: '100%',
						aspectRatio: '16/9',
					}}
					src={linkItem}
					title='YouTube video player'
					allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
					allowFullScreen
				></iframe>
			) : (
				<video
					src={linkItem}
					controls
					autoPlay={false}
					style={{ borderRadius: '5px', border: 'none', width: '100%', aspectRatio: '16/9' }}
					poster={BG}
				/>
			)}
			<Stack direction='column' sx={{ flex: 2 }} alignItems='flex-start' gap='1rem'>
				<Typography sx={{ fontWeight: 600, fontSize: 16, fontFamily: 'Lato' }}>{title}</Typography>
				<Typography
					sx={{
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						display: '-webkit-box',
						WebkitLineClamp: '2',
						WebkitBoxOrient: 'vertical',
						fontWeight: 400,
						fontSize: 14,
						color: '#667085',
						fontFamily: 'Lato',
					}}
				>
					{description}
				</Typography>
			</Stack>
		</Stack>
	);
};

const MainItem = ({ itemLink, title, description, author = 'Joban Brown' }) => {
	const isZoomLink = itemLink.includes('us06web.zoom.us');

	if (isZoomLink) {
		return (
			<Stack gap='11.2px' title='Click to watch'>
				<Stack
					justifyContent='center'
					alignItems='center'
					sx={{ width: '100%', aspectRatio: '16/9', background: `url(${BG})`, backgroundSize: 'cover', cursor: 'pointer' }}
					component='a'
					href={itemLink}
					target='_blank'
					// onClick={() => openZoomLink(item)}
				>
					<img src={Logo} alt='Logo' style={{ height: '45px' }} />
				</Stack>
				<Typography fontSize={'20px'} fontWeight={600} fontFamily={'Lato'}>
					{title}
				</Typography>
				<Typography variant='body1' fontFamily='Lato' color='#475467' fontWeight={400} fontSize='14px'>
					{description}
				</Typography>
				<Stack direction='row' justifyContent={'space-between'}>
					<Button startIcon={<Launch />} color='secondary' href={itemLink} target='_blank'>
						Watch Recording
					</Button>
				</Stack>
			</Stack>
		);
	}

	return (
		<Stack gap='11.2px'>
			{itemLink.includes('youtube') ? (
				<iframe
					style={{
						aspectRatio: '2/1',
						borderRadius: '5px',
						border: '5px',
						width: '100%',
						marginBottom: '26px',
						// maxHeight: '400px',
					}}
					src={itemLink}
					title={title}
					allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
					allowFullScreen
				></iframe>
			) : (
				<video
					controls
					autoPlay={false}
					style={{ aspectRatio: '2/1', borderRadius: '5px', border: '5px', width: '100%', marginBottom: '26px' }}
					src={itemLink}
					title={title}
				/>
			)}
			<Typography sx={{ fontWeight: 600, fontSize: 16, fontFamily: 'Lato' }}>{title}</Typography>
			<Typography sx={{ fontStyle: 'italic', fontSize: '12px', fontWeight: 600, fontFamily: 'Lato' }}>{author}</Typography>
			<Typography
				sx={{
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					display: '-webkit-box',
					WebkitLineClamp: '2',
					WebkitBoxOrient: 'vertical',
					fontWeight: 400,
					fontSize: 14,
					color: '#667085',
					fontFamily: 'Lato',
					textAlign: 'justify',
				}}
			>
				{description}
			</Typography>
		</Stack>
	);
};

const TextField = ({ placeholder, style, ...rest }) => {
	return (
		<Paper
			component='form'
			sx={{ p: '2px 4px', display: 'flex', borderRadius: '5px', alignItems: 'center', width: 600, boxShadow: '0px 4px 10px 0px #0000001A', ...style }}
		>
			<InputBase sx={{ ml: 1, flex: 1, fontSize: '14px', padding: '5px 10px', fontFamily: 'Lato' }} placeholder={placeholder} {...rest} />
			<SearchIcon />
		</Paper>
	);
};

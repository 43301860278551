import { ArrowBack } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Grid, InputBase, Paper, Stack, ThemeProvider, Typography } from '@mui/material';
import React, { useState } from 'react';
import { theme } from '../theme';
import { libraries, LibraryItem } from './TrainingCenter';
import { Link } from 'react-router-dom';
import { sortBy } from 'lodash';
import { useSelector } from 'react-redux';

const newTheme = {
	...theme,
	typography: {
		allVariant: {
			fontFamily: 'Lato',
		},
		fontFamily: 'Lato, serif',
		fontSize: 16,
		button: {
			textTransform: 'capitalize',
			padding: '0 10px',
			fontFamily: 'Lato, serif',
		},
	},
};

//valid options- video, zoom, docs
const videos = libraries[0].items;

const TrainingCenterItemLibrary = ({ name }) => {
	const type = useSelector((state) => state.auth.role);

	const videosForYou = videos.filter((item) => (type === 'realty_agent' ? true : item.all));
	const [sortedVideos, setSortedVideos] = useState(videosForYou);
	const [search, setSearch] = useState('');
	const handleSearch = (e) => {
		setSearch(e.target.value);
		setSortedVideos((v) => videosForYou.filter((item) => item.title.toLowerCase().includes(e.target.value.toLowerCase())));
	};
	return (
		<ThemeProvider theme={newTheme}>
			<Stack gap='1rem' sx={{ backgroundColor: '#ffffff', height: '100%' }}>
				<Stack gap='1rem' sx={{ backgroundColor: '#F9F9FB', padding: '1rem' }}>
					<Stack direction='row' justifyContent={'space-between'}>
						<Stack direction={'row'} alignItems={'center'} gap='1rem'>
							<Button
								variant='text'
								startIcon={<ArrowBack />}
								color='secondary'
								sx={{ fontSize: '18px', fontWeight: 600 }}
								disableElevation
								LinkComponent={Link}
								to='..'
								disableRipple
							>
								Back
							</Button>
							<Typography sx={{ fontSize: 20, fontWeight: 700, fontFamily: 'Lato' }}>{name} Library</Typography>
						</Stack>
					</Stack>
					<Grid container spacing={2}>
						{videosForYou.slice(0, 3).map((video) => (
							<Grid item xs={4} key={video.link}>
								<MainItem {...video} />
							</Grid>
						))}
					</Grid>
				</Stack>
			</Stack>
			<Stack p='1rem' gap='1rem' mt='3rem'>
				<Stack justifyContent='space-between' direction='row'>
					<Typography color='#005EA9' fontWeight={700} fontSize={20} textAlign='left' sx={{ fontStyle: 'italic', fontFamily: 'Lato' }}>
						More from {name} Library
					</Typography>
					<TextField placeholder={'Search by title'} style={{ width: 400 }} onChange={handleSearch} value={search} />
				</Stack>
				<Grid container spacing={2}>
					{sortBy(sortedVideos, ['title']).map((video) => (
						<Grid item xs={4} key={video.link}>
							<LibraryItem {...video} />
						</Grid>
					))}
				</Grid>
			</Stack>
		</ThemeProvider>
	);
};

export default TrainingCenterItemLibrary;

const MainItem = ({ link, title, description, author = 'Joban Brown' }) => {
	return (
		<Stack gap='11.2px'>
			{link.includes('youtube') ? (
				<iframe
					style={{
						aspectRatio: '2/1',
						borderRadius: '5px',
						border: '5px',
						width: '100%',
						marginBottom: '26px',
						// maxHeight: '400px',
					}}
					src={link}
					title={title}
					allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
					allowFullScreen
				></iframe>
			) : (
				<video
					controls
					autoPlay={false}
					style={{ aspectRatio: '2/1', borderRadius: '5px', border: '5px', width: '100%', marginBottom: '26px' }}
					src={link}
					title={title}
				/>
			)}
			<Typography sx={{ fontWeight: 600, fontSize: 16, fontFamily: 'Lato' }}>{title || 'Buyer 1 Presentation'}</Typography>
			<Typography sx={{ fontStyle: 'italic', fontSize: '12px', fontWeight: 600, fontFamily: 'Lato' }}>{author}</Typography>
			<Typography
				sx={{
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					display: '-webkit-box',
					WebkitLineClamp: '2',
					WebkitBoxOrient: 'vertical',
					fontWeight: 400,
					fontSize: 14,
					color: '#667085',
					fontFamily: 'Lato',
					textAlign: 'justify',
				}}
			>
				{description}
			</Typography>
		</Stack>
	);
};

const TextField = ({ placeholder, style, ...rest }) => {
	return (
		<Paper
			component='form'
			sx={{ p: '2px 4px', display: 'flex', borderRadius: '5px', alignItems: 'center', width: 600, boxShadow: '0px 4px 10px 0px #0000001A', ...style }}
		>
			<InputBase sx={{ ml: 1, flex: 1, fontSize: '14px', padding: '5px 10px', fontFamily: 'Lato' }} placeholder={placeholder} {...rest} />
			<SearchIcon />
		</Paper>
	);
};
